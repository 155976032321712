
import { 
  CleanupAction, 
  GalleryItem, 
  CollectedWaste,
  InventoryItem,
  Invoice,
  User
} from '@/types/database';

// Generate a simple UUID for new items
const generateId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

// Common localStorage utility functions
const getStoredData = <T>(key: string, defaultValue: T[]): T[] => {
  try {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error);
    return defaultValue;
  }
};

const saveStoredData = <T>(key: string, data: T[]): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(`Error saving ${key} to localStorage:`, error);
  }
};

// Generic API creator to avoid duplication
export const createApi = <T extends { id: string }>(storageKey: string) => ({
  getAll: async (): Promise<T[]> => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Simulate network delay
    return getStoredData<T>(storageKey, []);
  },
  
  getById: async (id: string): Promise<T | undefined> => {
    await new Promise(resolve => setTimeout(resolve, 300)); // Simulate network delay
    const items = getStoredData<T>(storageKey, []);
    return items.find(item => item.id === id);
  },
  
  create: async (data: Omit<T, 'id' | 'createdAt' | 'updatedAt'> & { id?: string }): Promise<T> => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Simulate network delay
    const items = getStoredData<T>(storageKey, []);
    
    const now = new Date().toISOString();
    const newItem = {
      ...data,
      id: data.id || generateId(),
      createdAt: now,
      updatedAt: now,
    } as unknown as T;
    
    items.push(newItem);
    saveStoredData(storageKey, items);
    
    return newItem;
  },
  
  update: async (id: string, data: Partial<T>): Promise<T> => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Simulate network delay
    const items = getStoredData<T>(storageKey, []);
    
    const index = items.findIndex(item => item.id === id);
    if (index === -1) {
      throw new Error(`Item with id ${id} not found`);
    }
    
    const updatedItem = {
      ...items[index],
      ...data,
      id, // ensure ID doesn't change
      updatedAt: new Date().toISOString(),
    } as T;
    
    items[index] = updatedItem;
    saveStoredData(storageKey, items);
    
    return updatedItem;
  },
  
  delete: async (id: string): Promise<void> => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Simulate network delay
    const items = getStoredData<T>(storageKey, []);
    
    const filteredItems = items.filter(item => item.id !== id);
    saveStoredData(storageKey, filteredItems);
  }
});
