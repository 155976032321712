
import React from "react";
import { 
  PieChart, 
  Pie, 
  Cell, 
  Tooltip, 
  ResponsiveContainer 
} from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { InvoiceStats } from "@/types/invoice";

interface InvoiceStatusChartProps {
  stats: InvoiceStats;
}

const InvoiceStatusChart = ({ stats }: InvoiceStatusChartProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Statut des factures</CardTitle>
        <CardDescription>
          Répartition des factures par statut
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col items-center justify-center p-6 bg-green-50 rounded-lg">
            <span className="text-4xl font-bold text-green-600">{stats.paid}</span>
            <span className="text-green-600 mt-2">Factures payées</span>
          </div>
          <div className="flex flex-col items-center justify-center p-6 bg-yellow-50 rounded-lg">
            <span className="text-4xl font-bold text-yellow-600">{stats.pending}</span>
            <span className="text-yellow-600 mt-2">Factures en attente</span>
          </div>
          <div className="flex flex-col items-center justify-center p-6 bg-red-50 rounded-lg">
            <span className="text-4xl font-bold text-red-600">{stats.overdue}</span>
            <span className="text-red-600 mt-2">Factures en retard</span>
          </div>
        </div>
        
        <div className="mt-8 h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={[
                  { name: 'Payées', value: stats.paid },
                  { name: 'En attente', value: stats.pending },
                  { name: 'En retard', value: stats.overdue }
                ]}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                <Cell fill="#4ade80" />
                <Cell fill="#facc15" />
                <Cell fill="#f87171" />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default InvoiceStatusChart;
