
import React from "react";
import { Card } from "@/components/ui/card";
import { Activity } from "lucide-react";
import { CleanupAction } from "@/types/database";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

interface RecentActivitiesProps {
  actions: CleanupAction[];
}

const RecentActivities: React.FC<RecentActivitiesProps> = ({ actions = [] }) => {
  // Get recent activities
  const getRecentActivities = () => {
    if (!actions || actions.length === 0) {
      return [];
    }

    // Sort actions by date, most recent first
    const sortedActions = [...actions].sort((a, b) => 
      new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    // Get recently completed actions
    return sortedActions
      .filter(action => action.status === "completed")
      .slice(0, 5)
      .map(action => ({
        id: action.id,
        title: action.title,
        date: format(new Date(action.date), "d MMMM yyyy", { locale: fr }),
        status: "Terminée",
        amount: action.wasteData?.totalWeight 
          ? `${action.wasteData.totalWeight} kg collectés` 
          : `${action.currentParticipants} participants`
      }));
  };

  const recentActivities = getRecentActivities();

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Activités récentes</h2>
        <Activity className="h-5 w-5 text-gray-400" />
      </div>
      <div className="space-y-4">
        {recentActivities.length > 0 ? (
          recentActivities.map((activity) => (
            <div key={activity.id} className="flex items-start border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <div className="w-2 h-2 rounded-full bg-green-500 mt-2 mr-3"></div>
              <div className="flex-1">
                <h3 className="text-sm font-medium">{activity.title}</h3>
                <p className="text-xs text-gray-500 mt-1">{activity.date}</p>
              </div>
              <div className="text-right">
                <span className="text-xs bg-green-100 text-green-800 py-1 px-2 rounded font-medium">{activity.status}</span>
                {activity.amount && (
                  <p className="text-xs text-gray-500 mt-1">{activity.amount}</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4 text-gray-500">
            Aucune activité récente
          </div>
        )}
      </div>
    </Card>
  );
};

export default RecentActivities;
