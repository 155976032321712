
import React from "react";
import { Card } from "@/components/ui/card";

interface StatCardProps { 
  title: string;
  value: string;
  description: string;
  icon: React.ReactNode;
  color?: string;
}

const StatCard: React.FC<StatCardProps> = ({ 
  title, 
  value, 
  description, 
  icon,
  color = "bg-blue-50" 
}) => (
  <Card className="p-6">
    <div className="flex items-start">
      <div className={`p-3 rounded-lg ${color}`}>
        {icon}
      </div>
      <div className="ml-4">
        <p className="text-sm font-medium text-gray-500">{title}</p>
        <h3 className="text-xl font-bold mt-1">{value}</h3>
        <p className="text-xs text-gray-500 mt-1">{description}</p>
      </div>
    </div>
  </Card>
);

export default StatCard;
