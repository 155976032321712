
import React from "react";
import { Button } from "@/components/ui/button";
import { Edit, Eye, Trash } from "lucide-react";

type RowActionsProps = {
  actionId: string;
  onDeleteAction: (id: string) => void;
  onEdit?: (id: string) => void;
  onView?: (id: string) => void;
};

const ActionRowActions = ({ actionId, onDeleteAction, onEdit, onView }: RowActionsProps) => {
  return (
    <div className="flex justify-end space-x-2">
      {onView && (
        <Button 
          variant="outline" 
          size="sm" 
          className="h-8 w-8 p-0"
          onClick={() => onView(actionId)}
        >
          <Eye className="h-4 w-4" />
        </Button>
      )}
      {onEdit && (
        <Button 
          variant="outline" 
          size="sm" 
          className="h-8 w-8 p-0"
          onClick={() => onEdit(actionId)}
        >
          <Edit className="h-4 w-4" />
        </Button>
      )}
      <Button 
        variant="outline" 
        size="sm" 
        className="h-8 w-8 p-0 text-red-500 hover:text-red-600"
        onClick={() => onDeleteAction(actionId)}
      >
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default ActionRowActions;
