
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { inventoryApi } from "@/services/api";
import { Button } from "@/components/ui/button";
import { Plus, Filter, Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { toast } from "sonner";
import AdminLayout from "../AdminLayout";

const InventoryListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  
  const { data: inventoryItems, isLoading, error } = useQuery({
    queryKey: ['inventoryItems'],
    queryFn: inventoryApi.getAll
  });
  
  const deleteMutation = useMutation({
    mutationFn: (id: string) => inventoryApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryItems'] });
      toast.success("Article supprimé avec succès");
    },
    onError: (error: Error) => {
      toast.error(`Erreur lors de la suppression: ${error.message}`);
    }
  });
  
  const handleEdit = (id: string) => {
    navigate(`/admin/inventory/edit/${id}`);
  };
  
  const handleStockMovement = (id: string) => {
    navigate(`/admin/inventory/stock/${id}`);
  };
  
  const handleDelete = (id: string) => {
    if (confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      deleteMutation.mutate(id);
    }
  };
  
  const handleCreate = () => {
    navigate("/admin/inventory/new");
  };
  
  // Filter inventory items based on search query and category filter
  const filteredItems = inventoryItems?.filter(item => {
    const matchesSearch = searchQuery.trim() === "" || 
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description?.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesCategory = categoryFilter === "all" || item.category === categoryFilter;
    
    return matchesSearch && matchesCategory;
  });
  
  // Group filtered items by category for display
  const groupedItems = filteredItems?.reduce((acc, item) => {
    const category = item.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {} as Record<string, typeof filteredItems>);
  
  if (error) {
    return (
      <AdminLayout>
        <div className="p-6">
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Erreur:</strong>
            <span className="block sm:inline"> Impossible de charger les données d'inventaire.</span>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  return (
    <AdminLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Gestion de l'inventaire</h1>
          <Button onClick={handleCreate}>
            <Plus className="mr-2 h-4 w-4" />
            Nouvel article
          </Button>
        </div>
        
        <div className="flex space-x-4 mb-6">
          <div className="relative w-full max-w-sm">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              type="search"
              placeholder="Rechercher un article..."
              className="pl-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          <div className="flex items-center space-x-2">
            <Filter className="h-4 w-4 text-gray-500" />
            <select
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md p-2.5"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="all">Toutes catégories</option>
              <option value="equipment">Équipement</option>
              <option value="clothing">Vêtements</option>
              <option value="consumable">Consommable</option>
              <option value="other">Autre</option>
            </select>
          </div>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
          </div>
        ) : !groupedItems || Object.keys(groupedItems).length === 0 ? (
          <div className="bg-white rounded-lg shadow-sm p-6 text-center">
            <p className="text-gray-600 mb-4">Aucun article d'inventaire trouvé</p>
            <Button onClick={handleCreate}>
              <Plus className="mr-2 h-4 w-4" />
              Ajouter un article
            </Button>
          </div>
        ) : (
          <div className="space-y-8">
            {Object.entries(groupedItems).map(([category, items]) => (
              <Card key={category} className="overflow-hidden">
                <div className="bg-muted px-4 py-3">
                  <h2 className="font-medium capitalize">
                    {category === "equipment" ? "Équipement" : 
                     category === "clothing" ? "Vêtements" :
                     category === "consumable" ? "Consommables" : "Autre"}
                    <span className="ml-2 text-gray-500 text-sm">({items.length})</span>
                  </h2>
                </div>
                <CardContent className="p-0">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b">
                          <th className="text-left font-medium p-3">Nom</th>
                          <th className="text-left font-medium p-3">Quantité</th>
                          <th className="text-left font-medium p-3">Stock minimum</th>
                          <th className="text-left font-medium p-3">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => {
                          const isLowStock = item.minQuantity !== undefined && item.quantity <= item.minQuantity;
                          
                          return (
                            <tr key={item.id} className="border-b last:border-0 hover:bg-gray-50">
                              <td className="p-3">
                                <div className="font-medium">{item.name}</div>
                                <div className="text-sm text-gray-500">{item.description}</div>
                              </td>
                              <td className="p-3">
                                <div className={`font-medium ${isLowStock ? 'text-red-600' : ''}`}>
                                  {item.quantity} {item.unit}
                                  {isLowStock && (
                                    <span className="ml-2 bg-red-100 text-red-800 text-xs px-2 py-0.5 rounded-full">
                                      Stock bas
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="p-3">
                                {item.minQuantity !== undefined ? `${item.minQuantity} ${item.unit}` : '-'}
                              </td>
                              <td className="p-3">
                                <div className="flex space-x-2">
                                  <Button variant="outline" size="sm" onClick={() => handleStockMovement(item.id)}>
                                    Stock
                                  </Button>
                                  <Button variant="outline" size="sm" onClick={() => handleEdit(item.id)}>
                                    Modifier
                                  </Button>
                                  <Button 
                                    variant="outline" 
                                    size="sm" 
                                    className="text-red-600 hover:text-red-700 hover:bg-red-50"
                                    onClick={() => handleDelete(item.id)}
                                  >
                                    Supprimer
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default InventoryListPage;
