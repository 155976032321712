
import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { actionsApi } from "@/services/actions";
import AdminLayout from "../AdminLayout";
import DashboardStats from "./components/DashboardStats";
import RecentActivities from "./components/RecentActivities";
import UpcomingEvents from "./components/UpcomingEvents";
import { Loader2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "@/components/ui/skeleton";

const DashboardHome = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  // Refresh actions data when component mounts
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['actions'] });
  }, [queryClient]);
  
  // Fetch actions data with better error handling
  const { data: actions = [], isLoading, error } = useQuery({
    queryKey: ['actions'],
    queryFn: actionsApi.getAll,
    retry: 1,
  });

  // Display error toast when error occurs
  React.useEffect(() => {
    if (error) {
      console.error("Erreur lors du chargement des actions:", error);
      toast({
        title: "Erreur de chargement",
        description: "Impossible de charger les données. Veuillez rafraîchir la page.",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  // Handle data loading state
  if (isLoading) {
    return (
      <AdminLayout>
        <div className="space-y-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Tableau de bord</h1>
          
          {/* Skeleton loaders for stats */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {[...Array(4)].map((_, i) => (
              <Skeleton key={i} className="h-24 w-full" />
            ))}
          </div>
          
          {/* Skeleton loaders for content */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Skeleton className="h-64 w-full" />
            <Skeleton className="h-64 w-full" />
          </div>
        </div>
      </AdminLayout>
    );
  }

  console.log("Actions loaded for dashboard:", actions.length);

  return (
    <AdminLayout>
      <div className="space-y-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Tableau de bord</h1>
        
        {/* Stats overview */}
        <DashboardStats actions={actions} />
        
        {/* Recent activities and upcoming events */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <RecentActivities actions={actions} />
          <UpcomingEvents actions={actions} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default DashboardHome;
