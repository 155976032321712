
import React from "react";
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { MonthlyTotals } from "@/types/invoice";

interface MonthlyChartProps {
  monthlyTotals: MonthlyTotals[];
  formatCurrency: (amount: number) => string;
}

const MonthlyChart = ({ monthlyTotals, formatCurrency }: MonthlyChartProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Évolution mensuelle des recettes et dépenses</CardTitle>
        <CardDescription>
          Aperçu des montants pour chaque mois de l'année
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={monthlyTotals}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip 
                formatter={(value) => [formatCurrency(value as number), ""]}
                labelFormatter={(label) => `${label}`}
              />
              <Legend />
              <Bar dataKey="income" name="Recettes" fill="#4ade80" />
              <Bar dataKey="expenses" name="Dépenses" fill="#f87171" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default MonthlyChart;
