
import React from "react";
import { TableRow, TableCell } from "@/components/ui/table";
import { CleanupAction } from "@/types/database";
import ActionType from "./ActionType";
import ActionDate from "./ActionDate";
import ActionLocation from "./ActionLocation";
import ActionParticipants from "./ActionParticipants";
import ActionStatus from "./ActionStatus";
import ActionRowActions from "./ActionRowActions";

type ActionRowProps = {
  action: CleanupAction;
  onDeleteAction: (id: string) => void;
  onEdit?: (id: string) => void;
  onView?: (id: string) => void;
};

const ActionRow = ({ action, onDeleteAction, onEdit, onView }: ActionRowProps) => {
  return (
    <TableRow key={action.id}>
      <TableCell className="font-medium">{action.title}</TableCell>
      <TableCell>
        <ActionType type={action.type} />
      </TableCell>
      <TableCell>
        <ActionDate date={action.date} startTime={action.startTime} endTime={action.endTime} />
      </TableCell>
      <TableCell>
        <ActionLocation location={action.location} />
      </TableCell>
      <TableCell>
        <ActionParticipants current={action.currentParticipants} max={action.maxParticipants} />
      </TableCell>
      <TableCell>
        <ActionStatus status={action.status} />
      </TableCell>
      <TableCell className="text-right">
        <ActionRowActions 
          actionId={action.id} 
          onDeleteAction={onDeleteAction}
          onEdit={onEdit}
          onView={onView}
        />
      </TableCell>
    </TableRow>
  );
};

export default ActionRow;
