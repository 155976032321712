
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { InventoryItem } from "@/types/database";

export interface PageHeaderProps {
  item?: InventoryItem;
}

const PageHeader: React.FC<PageHeaderProps> = ({ item }) => {
  return (
    <div className="mb-6">
      <Link to="/admin/inventory">
        <Button variant="ghost" className="p-0 h-auto mb-4">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Retour à l'inventaire
        </Button>
      </Link>
      <h1 className="text-2xl font-bold text-gray-900">
        Mouvements de stock: {item?.name || "Article"}
      </h1>
      <p className="text-gray-500 mt-1">
        Ajoutez ou retirez du stock pour cet article
      </p>
    </div>
  );
};

export default PageHeader;
