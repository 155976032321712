
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { publicRoutes } from '@/routes/publicRoutes';
import { authRoutes } from '@/routes/authRoutes';
import { adminRoutes } from '@/routes/adminRoutes';

// ScrollToTop component to scroll to top on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

/**
 * Main application router component that handles all routes
 */
export const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Auth Routes - these should come before admin routes */}
        {authRoutes}
        
        {/* Admin Routes */}
        {adminRoutes}
        
        {/* Public Routes */}
        {publicRoutes}
      </Routes>
      <Toaster />
    </Router>
  );
};

export default AppRouter;
