
import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { 
  Table, 
  TableHeader, 
  TableBody, 
  TableHead, 
  TableRow, 
  TableCell 
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Edit, Trash2, Download, CheckCircle, Clock, AlertCircle, ArrowUp, ArrowDown } from "lucide-react";
import { Invoice } from "@/types/database";
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface InvoiceTableProps {
  invoices: Invoice[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  onSortChange?: (column: string) => void;
}

const InvoiceTable = ({ 
  invoices, 
  onDelete, 
  onEdit, 
  sortBy = "date", 
  sortDirection = "desc", 
  onSortChange 
}: InvoiceTableProps) => {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  const statusBadgeColor = (status: string) => {
    switch (status) {
      case "paid": return "bg-green-100 text-green-800";
      case "pending": return "bg-yellow-100 text-yellow-800";
      case "overdue": return "bg-red-100 text-red-800";
      default: return "bg-gray-100 text-gray-800";
    }
  };

  const statusIcon = (status: string) => {
    switch (status) {
      case "paid": return <CheckCircle className="h-4 w-4 text-green-600" />;
      case "pending": return <Clock className="h-4 w-4 text-yellow-600" />;
      case "overdue": return <AlertCircle className="h-4 w-4 text-red-600" />;
      default: return null;
    }
  };

  const categoryLabels: Record<string, string> = {
    "equipment": "Équipement",
    "consumables": "Consommables",
    "services": "Services",
    "rent": "Loyer",
    "other": "Autre"
  };

  const renderSortIcon = (column: string) => {
    if (!onSortChange || column !== sortBy) return null;
    
    return sortDirection === "asc" 
      ? <ArrowUp className="inline ml-1 h-4 w-4" /> 
      : <ArrowDown className="inline ml-1 h-4 w-4" />;
  };

  const handleSort = (column: string) => {
    if (onSortChange) {
      onSortChange(column);
    }
  };

  const getSortableHeaderProps = (column: string) => {
    return onSortChange 
      ? { 
          className: "cursor-pointer hover:bg-gray-50", 
          onClick: () => handleSort(column) 
        } 
      : {};
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead {...getSortableHeaderProps("number")}>
              Numéro {renderSortIcon("number")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("title")}>
              Titre {renderSortIcon("title")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("amount")}>
              Montant {renderSortIcon("amount")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("vendor")}>
              Fournisseur {renderSortIcon("vendor")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("category")}>
              Catégorie {renderSortIcon("category")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("date")}>
              Date {renderSortIcon("date")}
            </TableHead>
            <TableHead {...getSortableHeaderProps("status")}>
              Statut {renderSortIcon("status")}
            </TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {invoices.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8} className="text-center py-8 text-gray-500">
                Aucune facture ne correspond à vos critères de recherche
              </TableCell>
            </TableRow>
          ) : (
            invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell className="font-medium">{invoice.number}</TableCell>
                <TableCell>{invoice.title}</TableCell>
                <TableCell>{formatCurrency(invoice.amount)}</TableCell>
                <TableCell>{invoice.vendor}</TableCell>
                <TableCell>
                  <Badge variant="outline">
                    {categoryLabels[invoice.category] || invoice.category}
                  </Badge>
                </TableCell>
                <TableCell>
                  {format(new Date(invoice.date), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    {statusIcon(invoice.status)}
                    <span className={`ml-2 inline-flex text-xs font-medium rounded-full px-2 py-1 ${statusBadgeColor(invoice.status)}`}>
                      {invoice.status === "paid" && "Payée"}
                      {invoice.status === "pending" && "En attente"}
                      {invoice.status === "overdue" && "En retard"}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button 
                      variant="outline" 
                      size="sm" 
                      onClick={() => onEdit(invoice.id)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                    {invoice.fileUrl && (
                      <Button
                        variant="outline"
                        size="sm"
                        asChild
                      >
                        <a href={invoice.fileUrl} target="_blank" rel="noopener noreferrer">
                          <Download className="h-4 w-4" />
                        </a>
                      </Button>
                    )}
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button variant="outline" size="sm">
                          <Trash2 className="h-4 w-4 text-red-500" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Êtes-vous sûr?</AlertDialogTitle>
                          <AlertDialogDescription>
                            Cette action est irréversible. Cela supprimera définitivement la facture
                            de la base de données.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Annuler</AlertDialogCancel>
                          <AlertDialogAction 
                            onClick={() => onDelete(invoice.id)}
                            className="bg-red-500 hover:bg-red-600"
                          >
                            Supprimer
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoiceTable;
