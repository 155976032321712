import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { invoicesApi } from "@/services/api";
import { Invoice } from "@/types/database";
import { useToast } from "@/hooks/use-toast";
import { calculateInvoiceStats } from "@/types/invoice";
import AdminLayout from "../AdminLayout";

// Components
import PageActions from "./components/PageActions";
import StatisticsCards from "./components/StatisticsCards";
import InvoiceFilters from "./components/InvoiceFilters";
import InvoiceTable from "./components/InvoiceTable";
import EmptyState from "./components/EmptyState";
import SearchBar from "./components/SearchBar";
import SortOptions from "./components/SortOptions";

const InvoicesListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const [filterStatus, setFilterStatus] = useState<string>("all");
  const [filterCategory, setFilterCategory] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  
  const { data: invoices, isLoading } = useQuery({
    queryKey: ['invoices'],
    queryFn: invoicesApi.getAll,
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true
  });
  
  const deleteMutation = useMutation({
    mutationFn: (id: string) => invoicesApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      toast({
        title: "Facture supprimée",
        description: "La facture a été supprimée avec succès",
      });
    },
    onError: () => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression",
        variant: "destructive",
      });
    }
  });
  
  const handleAddNew = () => {
    navigate('/admin/invoices/new');
  };

  const handleViewStats = () => {
    navigate('/admin/invoices/stats');
  };
  
  const handleEdit = (id: string) => {
    navigate(`/admin/invoices/edit/${id}`);
  };
  
  const handleDelete = (id: string) => {
    deleteMutation.mutate(id);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(value);
      setSortDirection("desc");
    }
  };

  const processedInvoices = React.useMemo(() => {
    if (!invoices) return [];
    
    let result = invoices.filter(invoice => {
      const matchesStatus = filterStatus === "all" || invoice.status === filterStatus;
      const matchesCategory = filterCategory === "all" || invoice.category === filterCategory;
      
      const matchesSearch = searchQuery === "" || 
        invoice.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.vendor.toLowerCase().includes(searchQuery.toLowerCase());
      
      return matchesStatus && matchesCategory && matchesSearch;
    });
    
    result = [...result].sort((a, b) => {
      let comparison = 0;
      
      switch (sortBy) {
        case "number":
          comparison = a.number.localeCompare(b.number);
          break;
        case "title":
          comparison = a.title.localeCompare(b.title);
          break;
        case "amount":
          comparison = a.amount - b.amount;
          break;
        case "vendor":
          comparison = a.vendor.localeCompare(b.vendor);
          break;
        case "date":
          comparison = new Date(a.date).getTime() - new Date(b.date).getTime();
          break;
        case "status":
          comparison = a.status.localeCompare(b.status);
          break;
        default:
          comparison = new Date(a.date).getTime() - new Date(b.date).getTime();
      }
      
      return sortDirection === "asc" ? comparison : -comparison;
    });
    
    return result;
  }, [invoices, filterStatus, filterCategory, searchQuery, sortBy, sortDirection]);

  const stats = calculateInvoiceStats(invoices || []);
  
  if (isLoading) {
    return (
      <AdminLayout>
        <div className="flex justify-center p-8">Chargement...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Gestion des factures</h1>
          <PageActions onAddNew={handleAddNew} onViewStats={handleViewStats} />
        </div>

        <StatisticsCards stats={stats} />

        <div className="space-y-4">
          <SearchBar value={searchQuery} onChange={setSearchQuery} />
          
          <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
            <InvoiceFilters 
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              filterCategory={filterCategory}
              setFilterCategory={setFilterCategory}
            />
            <SortOptions 
              sortBy={sortBy} 
              sortDirection={sortDirection} 
              onSortChange={handleSortChange} 
            />
          </div>
        </div>
        
        {invoices && invoices.length > 0 ? (
          <>
            <InvoiceTable 
              invoices={processedInvoices} 
              onDelete={handleDelete} 
              onEdit={handleEdit}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSortChange}
            />
            
            <div className="text-sm text-gray-500 mt-2">
              Affichage de {processedInvoices.length} facture(s) sur {invoices.length} au total
            </div>
          </>
        ) : (
          <EmptyState onAddNew={handleAddNew} />
        )}
      </div>
    </AdminLayout>
  );
};

export default InvoicesListPage;
