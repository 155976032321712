
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Download, BarChart } from "lucide-react";

const PageHeader = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <Button variant="outline" size="sm" onClick={() => navigate('/admin/invoices')}>
          <ArrowLeft className="h-4 w-4 mr-2" />
          Retour à la liste
        </Button>
        <div className="flex items-center">
          <BarChart className="h-6 w-6 text-primary mr-2" />
          <h1 className="text-2xl font-bold">Statistiques des factures</h1>
        </div>
      </div>
      <Button variant="outline" className="flex items-center">
        <Download className="h-4 w-4 mr-2" />
        Exporter les données
      </Button>
    </div>
  );
};

export default PageHeader;
