
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { invoicesApi } from "@/services/api";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { calculateInvoiceStats, calculateMonthlyTotals, calculateCategoryTotals } from "@/types/invoice";
import PageHeader from "./components/PageHeader";
import SummaryCards from "./components/SummaryCards";
import MonthlyChart from "./components/MonthlyChart";
import AnnualTrendChart from "./components/AnnualTrendChart";
import CategoryPieChart from "./components/CategoryPieChart";
import InvoiceStatusChart from "./components/InvoiceStatusChart";
import AdminLayout from "../AdminLayout";

const InvoicesStatsPage = () => {
  const navigate = useNavigate();
  const { data: invoices, isLoading } = useQuery({
    queryKey: ['invoices'],
    queryFn: invoicesApi.getAll,
    staleTime: 0 // Forces a refetch on component mount
  });
  
  if (isLoading) {
    return <AdminLayout><div className="container py-6 flex justify-center">Chargement des statistiques...</div></AdminLayout>;
  }
  
  const stats = calculateInvoiceStats(invoices || []);
  const monthlyTotals = calculateMonthlyTotals(invoices || []);
  const categoryTotals = calculateCategoryTotals(invoices || []);
  
  const totalRevenue = invoices?.reduce((sum, invoice) => 
    invoice.amount > 0 ? sum + invoice.amount : sum, 0) || 0;
  
  const totalExpenses = invoices?.reduce((sum, invoice) => 
    invoice.amount < 0 ? sum + Math.abs(invoice.amount) : sum, 0) || 0;
  
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };
  
  const categoryLabels: Record<string, string> = {
    "equipment": "Équipement",
    "consumables": "Consommables",
    "services": "Services",
    "rent": "Loyer", 
    "other": "Autre"
  };
  
  return (
    <AdminLayout>
      <div className="container py-6 space-y-6">
        <PageHeader />
        
        {/* Summary Cards */}
        <SummaryCards 
          totalRevenue={totalRevenue} 
          totalExpenses={totalExpenses} 
          formatCurrency={formatCurrency} 
        />
        
        <Tabs defaultValue="monthly" className="space-y-4">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="monthly">Évolution mensuelle</TabsTrigger>
            <TabsTrigger value="categories">Répartition par catégorie</TabsTrigger>
            <TabsTrigger value="status">Statut des factures</TabsTrigger>
          </TabsList>
          
          <TabsContent value="monthly" className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-lg font-medium mb-4">Évolution mensuelle des factures</h3>
                <MonthlyChart monthlyTotals={monthlyTotals} formatCurrency={formatCurrency} />
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-lg font-medium mb-4">Tendance annuelle</h3>
                <AnnualTrendChart monthlyTotals={monthlyTotals} formatCurrency={formatCurrency} />
              </div>
            </div>
          </TabsContent>
          
          <TabsContent value="categories" className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-lg font-medium mb-4">Répartition des dépenses par catégorie</h3>
              <CategoryPieChart 
                categoryTotals={categoryTotals} 
                formatCurrency={formatCurrency} 
                categoryLabels={categoryLabels} 
              />
            </div>
          </TabsContent>
          
          <TabsContent value="status" className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-lg font-medium mb-4">État des factures</h3>
              <InvoiceStatusChart stats={stats} />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </AdminLayout>
  );
};

export default InvoicesStatsPage;
