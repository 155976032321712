
import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '@/pages/admin/LoginPage';

/**
 * Authentication related routes
 */
export const authRoutes = [
  <Route key="login" path="/admin/login" element={<LoginPage />} />,
];
