
export interface InvoiceStats {
  total: number;
  paid: number;
  pending: number;
  overdue: number;
}

export interface MonthlyTotals {
  month: string;
  income: number;
  expenses: number;
  cumulativeIncome?: number;
  cumulativeExpenses?: number;
}

export interface CategoryTotals {
  category: string;
  amount: number;
  percentage: number;
}

export interface InvoiceFormValues {
  number: string;
  title: string;
  description?: string;
  amount: number;
  date: string;
  dueDate?: string;
  vendor: string;
  category: 'equipment' | 'consumables' | 'services' | 'rent' | 'other';
  status: 'paid' | 'pending' | 'overdue';
  recurring?: boolean;
  notes?: string;
  fileUrl?: string;
}

// Helper functions for invoice statistics
export const calculateInvoiceStats = (invoices: any[]): InvoiceStats => {
  return {
    total: invoices.length,
    paid: invoices.filter(inv => inv.status === 'paid').length,
    pending: invoices.filter(inv => inv.status === 'pending').length,
    overdue: invoices.filter(inv => inv.status === 'overdue').length
  };
};

export const calculateMonthlyTotals = (invoices: any[]): MonthlyTotals[] => {
  // Get all months in the current year
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  
  // Initialize monthly totals with 0 values
  const monthlyData = months.map(month => ({
    month,
    income: 0,
    expenses: 0
  }));
  
  // Calculate income and expenses for each month
  invoices.forEach(invoice => {
    const date = new Date(invoice.date);
    const monthIndex = date.getMonth();
    
    if (invoice.amount > 0) {
      monthlyData[monthIndex].income += invoice.amount;
    } else {
      monthlyData[monthIndex].expenses += Math.abs(invoice.amount);
    }
  });
  
  // Calculate cumulative values
  let cumulativeIncome = 0;
  let cumulativeExpenses = 0;
  
  const dataWithCumulative = monthlyData.map(data => {
    cumulativeIncome += data.income;
    cumulativeExpenses += data.expenses;
    return {
      ...data,
      cumulativeIncome,
      cumulativeExpenses
    };
  });
  
  return dataWithCumulative;
};

export const calculateCategoryTotals = (invoices: any[]): CategoryTotals[] => {
  // Filter expenses (negative amounts)
  const expenses = invoices.filter(inv => inv.amount < 0);
  
  // Calculate total expenses
  const totalExpenses = expenses.reduce((sum, inv) => sum + Math.abs(inv.amount), 0);
  
  // Group by category
  const categories: Record<string, number> = {};
  
  expenses.forEach(invoice => {
    const category = invoice.category;
    const amount = Math.abs(invoice.amount);
    
    if (!categories[category]) {
      categories[category] = 0;
    }
    
    categories[category] += amount;
  });
  
  // Convert to array and calculate percentages
  const categoryTotals = Object.entries(categories).map(([category, amount]) => ({
    category,
    amount,
    percentage: totalExpenses > 0 ? (amount / totalExpenses) * 100 : 0
  }));
  
  // Sort by amount (descending)
  return categoryTotals.sort((a, b) => b.amount - a.amount);
};
