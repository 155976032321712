
import React, { useEffect } from "react";
import { CleanupAction } from "@/types/database";
import LoadingState from "./actionsList/LoadingState";
import ErrorState from "./actionsList/ErrorState";
import EmptyState from "./actionsList/EmptyState";
import ActionsTable from "./actionsList/ActionsTable";
import { useNavigate } from "react-router-dom";

interface ActionsListProps {
  actions: CleanupAction[];
  isLoading: boolean;
  error: unknown;
  onDeleteAction: (id: string) => void;
  searchQuery: string;
  typeFilter: string;
  dateFilter: string;
  onAddAction?: () => void;
}

const ActionsList = ({ 
  actions, 
  isLoading, 
  error, 
  onDeleteAction,
  searchQuery,
  typeFilter,
  dateFilter,
  onAddAction
}: ActionsListProps) => {
  const navigate = useNavigate();
  
  // Add detailed logging to debug the issue
  useEffect(() => {
    console.log("ActionsList RENDER:");
    console.log(`- Raw actions received: ${actions?.length || 0}`);
    console.log(`- Current filters: search="${searchQuery}", type="${typeFilter}", date="${dateFilter}"`);
    if (actions?.length > 0) {
      console.log("- Action IDs received:", actions.map(a => `${a.id} (${a.title})`).join(", "));
    }
  }, [actions, searchQuery, typeFilter, dateFilter]);
  
  // Handle edit action
  const handleEditAction = (id: string) => {
    console.log("Navigating to edit action:", id);
    navigate(`/admin/actions/edit/${id}`);
  };

  // Handle view action
  const handleViewAction = (id: string) => {
    console.log("Navigating to view action:", id);
    navigate(`/admin/actions/edit/${id}`);
  };
  
  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    console.error("Error in ActionsList:", error);
    return <ErrorState />;
  }

  if (!actions || actions.length === 0) {
    console.log("No actions available - showing empty state");
    return <EmptyState hasFilters={false} onAdd={onAddAction} />;
  }
  
  // Display the original list of actions without any filtering
  // We'll implement filtering only when we confirm all actions are showing correctly
  const filteredActions = [...actions];
  
  console.log(`Final filtered actions (should match original): ${filteredActions.length}`);
  
  return (
    <>
      <ActionsTable 
        actions={filteredActions} 
        onDeleteAction={onDeleteAction}
        onEdit={handleEditAction}
        onView={handleViewAction}
      />
      <div className="text-sm text-gray-500 mt-4">
        Affichage de {filteredActions.length} action(s) sur {actions.length} au total
      </div>
    </>
  );
};

export default ActionsList;
