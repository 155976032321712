
import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { 
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Trash2, PieChart } from "lucide-react";
import { ActionFormValues } from "../schemas/actionSchema";

interface WasteDataSectionProps {
  form: UseFormReturn<ActionFormValues>;
  isCompleted: boolean;
}

const WasteDataSection: React.FC<WasteDataSectionProps> = ({ form, isCompleted }) => {
  // Si l'action n'est pas complétée, on ne montre pas cette section
  if (!isCompleted) {
    return null;
  }

  // S'assurer que les pourcentages totalisent 100% (ajuste le dernier)
  const validatePercentages = () => {
    const categories = form.getValues().wasteData?.categories;
    if (!categories) return;
    
    const values = [
      categories.plastic || 0,
      categories.paper || 0,
      categories.metal || 0,
      categories.glass || 0,
      categories.organic || 0,
      categories.cigarettes || 0,
      categories.other || 0
    ];
    
    const total = values.reduce((acc, val) => acc + val, 0);
    
    if (total !== 100 && total > 0) {
      // Ajuster la catégorie "autres" pour arriver à 100%
      const newOther = categories.other || 0 + (100 - total);
      form.setValue('wasteData.categories.other', Math.max(0, newOther));
    }
  };

  // Initialiser les valeurs si elles n'existent pas
  useEffect(() => {
    if (!form.getValues().wasteData) {
      form.setValue('wasteData', {
        totalWeight: 0,
        categories: {
          plastic: 0,
          paper: 0,
          metal: 0,
          glass: 0,
          organic: 0,
          cigarettes: 0,
          other: 0
        }
      });
    }
  }, [form]);

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium flex items-center">
        <Trash2 className="h-5 w-5 mr-2 text-gray-700" />
        Données sur les déchets collectés
      </h3>
      
      <FormField
        control={form.control}
        name="wasteData.totalWeight"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Poids total collecté (kg)</FormLabel>
            <FormControl>
              <Input 
                type="number" 
                step="0.001"
                placeholder="ex: 12.5" 
                {...field} 
                value={field.value || "0"}
                onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      
      <div>
        <div className="flex items-center mb-2">
          <PieChart className="h-5 w-5 mr-2 text-gray-700" />
          <h4 className="font-medium">Répartition des déchets (%)</h4>
        </div>
        <FormDescription className="mb-4">
          Les pourcentages doivent totaliser 100%.
        </FormDescription>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Plastique */}
          <FormField
            control={form.control}
            name="wasteData.categories.plastic"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Plastique (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Papier */}
          <FormField
            control={form.control}
            name="wasteData.categories.paper"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Papier (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Métal */}
          <FormField
            control={form.control}
            name="wasteData.categories.metal"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Métal (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Verre */}
          <FormField
            control={form.control}
            name="wasteData.categories.glass"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Verre (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Organique */}
          <FormField
            control={form.control}
            name="wasteData.categories.organic"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Organique (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Mégots */}
          <FormField
            control={form.control}
            name="wasteData.categories.cigarettes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Mégots (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          {/* Autres */}
          <FormField
            control={form.control}
            name="wasteData.categories.other"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Autres (%)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    min="0" 
                    max="100"
                    {...field} 
                    value={field.value || "0"}
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value) || 0);
                      validatePercentages();
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default WasteDataSection;
