
import React from "react";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { ArrowDownAZ, ArrowUpAZ } from "lucide-react";

interface SortOptionsProps {
  sortBy: string;
  sortDirection: "asc" | "desc";
  onSortChange: (field: string) => void;
}

const SortOptions = ({ sortBy, sortDirection, onSortChange }: SortOptionsProps) => {
  const toggleDirection = () => {
    onSortChange(sortBy);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center">
        <span className="text-sm text-gray-500 mr-2">Trier par:</span>
        <Select value={sortBy} onValueChange={onSortChange}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Choisir un champ" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="date">Date</SelectItem>
            <SelectItem value="amount">Montant</SelectItem>
            <SelectItem value="vendor">Fournisseur</SelectItem>
            <SelectItem value="title">Titre</SelectItem>
            <SelectItem value="number">Numéro</SelectItem>
            <SelectItem value="status">Statut</SelectItem>
          </SelectContent>
        </Select>
      </div>
      
      <Button 
        variant="outline" 
        size="icon" 
        onClick={toggleDirection}
        title={sortDirection === "asc" ? "Ordre croissant" : "Ordre décroissant"}
      >
        {sortDirection === "asc" ? (
          <ArrowUpAZ className="h-4 w-4" />
        ) : (
          <ArrowDownAZ className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

export default SortOptions;
