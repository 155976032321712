
import React from 'react';
import { Route } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import InvoicesListPage from '@/pages/admin/invoices/InvoicesListPage';
import NewInvoicePage from '@/pages/admin/invoices/NewInvoicePage';
import EditInvoicePage from '@/pages/admin/invoices/EditInvoicePage';
import InvoicesStatsPage from '@/pages/admin/invoices/InvoicesStatsPage';

export const invoiceRoutes = [
  <Route
    key="invoices-list"
    path="/admin/invoices"
    element={
      <PermissionGuard>
        <InvoicesListPage />
      </PermissionGuard>
    }
  />,
  <Route
    key="invoices-new"
    path="/admin/invoices/new"
    element={
      <PermissionGuard>
        <NewInvoicePage />
      </PermissionGuard>
    }
  />,
  <Route
    key="invoices-edit"
    path="/admin/invoices/edit/:id"
    element={
      <PermissionGuard>
        <EditInvoicePage />
      </PermissionGuard>
    }
  />,
  <Route
    key="invoices-stats"
    path="/admin/invoices/stats"
    element={
      <PermissionGuard>
        <InvoicesStatsPage />
      </PermissionGuard>
    }
  />
];
