
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Plus, Search, Image, Tag } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { galleryApi } from '@/services/api';
import { GalleryItem } from '@/types/database';
import AdminLayout from '../AdminLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import GalleryGrid from './components/GalleryGrid';
import GalleryTable from './components/GalleryTable';
import DeleteGalleryDialog from './components/DeleteGalleryDialog';

const GalleryListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [itemToDelete, setItemToDelete] = useState<GalleryItem | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('grid');
  
  // Fetch gallery items
  const { data: galleryItems = [], isLoading, isError } = useQuery({
    queryKey: ['galleryItems'],
    queryFn: galleryApi.getAll,
  });

  // Delete gallery item mutation
  const deleteItemMutation = useMutation({
    mutationFn: (id: string) => galleryApi.delete(id),
    onSuccess: () => {
      toast.success('Image supprimée avec succès');
      queryClient.invalidateQueries({ queryKey: ['galleryItems'] });
      setItemToDelete(null);
    },
    onError: (error) => {
      console.error('Error deleting gallery item:', error);
      toast.error('Erreur lors de la suppression de l\'image');
    },
  });

  // Extract all unique tags from gallery items
  const allTags = React.useMemo(() => {
    const tagsSet = new Set<string>();
    galleryItems.forEach(item => {
      item.tags?.forEach(tag => tagsSet.add(tag));
    });
    return Array.from(tagsSet);
  }, [galleryItems]);

  // Handle search and filter
  const filteredItems = React.useMemo(() => {
    return galleryItems.filter(item => {
      const matchesSearch = !searchTerm || 
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase()));
      
      const matchesTag = !selectedTag || 
        (item.tags && item.tags.includes(selectedTag));
      
      return matchesSearch && matchesTag;
    });
  }, [galleryItems, searchTerm, selectedTag]);

  // Handle add new gallery item
  const handleAddNew = () => {
    navigate('/admin/gallery/new');
  };

  // Handle edit gallery item
  const handleEdit = (id: string) => {
    navigate(`/admin/gallery/edit/${id}`);
  };

  // Handle delete gallery item
  const handleDelete = (item: GalleryItem) => {
    setItemToDelete(item);
  };

  // Confirm deletion
  const confirmDelete = () => {
    if (itemToDelete) {
      deleteItemMutation.mutate(itemToDelete.id);
    }
  };

  return (
    <AdminLayout>
      <div className="container py-6">
        <div className="flex flex-col space-y-6">
          {/* Header with title and actions */}
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Gestion de la galerie</h1>
            <Button onClick={handleAddNew}>
              <Plus className="mr-2 h-4 w-4" />
              Ajouter une image
            </Button>
          </div>

          {/* Search and filter controls */}
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input
                className="pl-10"
                placeholder="Rechercher par titre ou description..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="flex gap-2 flex-wrap md:flex-nowrap">
              <Button 
                variant={selectedTag ? "default" : "outline"} 
                size="sm"
                onClick={() => setSelectedTag(null)}
                className="min-w-20"
              >
                <Tag className="h-4 w-4 mr-1" />
                Tous
              </Button>
              
              {allTags.slice(0, 5).map(tag => (
                <Button
                  key={tag}
                  variant={selectedTag === tag ? "default" : "outline"}
                  size="sm"
                  onClick={() => setSelectedTag(tag === selectedTag ? null : tag)}
                  className="min-w-20"
                >
                  {tag}
                </Button>
              ))}
            </div>
            
            <Tabs value={viewMode} onValueChange={(value) => setViewMode(value as 'grid' | 'table')} className="hidden md:block">
              <TabsList>
                <TabsTrigger value="grid">Grille</TabsTrigger>
                <TabsTrigger value="table">Tableau</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          {/* Gallery content based on view mode */}
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <p>Chargement des images...</p>
              </div>
            ) : isError ? (
              <div className="flex justify-center items-center h-64 text-destructive">
                <p>Erreur lors du chargement des images.</p>
              </div>
            ) : filteredItems.length === 0 ? (
              <div className="flex flex-col justify-center items-center h-64 text-muted-foreground">
                <Image className="h-12 w-12 mb-4 opacity-30" />
                <p>Aucune image trouvée.</p>
                {searchTerm || selectedTag ? (
                  <Button 
                    variant="ghost" 
                    className="mt-2"
                    onClick={() => {
                      setSearchTerm('');
                      setSelectedTag(null);
                    }}
                  >
                    Réinitialiser les filtres
                  </Button>
                ) : (
                  <Button 
                    variant="outline" 
                    className="mt-4"
                    onClick={handleAddNew}
                  >
                    <Plus className="mr-2 h-4 w-4" />
                    Ajouter votre première image
                  </Button>
                )}
              </div>
            ) : viewMode === 'grid' ? (
              <GalleryGrid 
                items={filteredItems} 
                onEdit={handleEdit} 
                onDelete={handleDelete} 
              />
            ) : (
              <GalleryTable 
                items={filteredItems} 
                onEdit={handleEdit} 
                onDelete={handleDelete} 
              />
            )}
          </div>
        </div>
      </div>

      {/* Delete confirmation dialog */}
      {itemToDelete && (
        <DeleteGalleryDialog
          isOpen={!!itemToDelete}
          onClose={() => setItemToDelete(null)}
          onConfirm={confirmDelete}
          itemTitle={itemToDelete.title}
          isDeleting={deleteItemMutation.isPending}
        />
      )}
    </AdminLayout>
  );
};

export default GalleryListPage;
