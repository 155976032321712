
import { CleanupAction } from '@/types/database';

export const transformActionFromDb = (action: any): CleanupAction => {
  // Log the incoming action data for debugging
  console.log('Transforming action from DB:', action.id);
  
  // Safely parse JSON fields if they're strings
  const parseJsonField = (field: any) => {
    if (!field) return null;
    if (typeof field === 'string') {
      try {
        return JSON.parse(field);
      } catch (e) {
        console.error(`Error parsing JSON field:`, e);
        return null;
      }
    }
    return field;
  };
  
  // Ensure coordinates is properly structured
  const coordinates = parseJsonField(action.coordinates) || { latitude: 0, longitude: 0 };
  
  // Ensure waste_data is properly structured
  const wasteData = parseJsonField(action.waste_data) || {
    totalWeight: 0,
    categories: {
      plastic: 0,
      paper: 0,
      metal: 0,
      glass: 0,
      organic: 0,
      cigarettes: 0,
      other: 0
    }
  };

  return {
    id: action.id,
    title: action.title || '',
    description: action.description || '',
    type: action.type || 'cleanup',
    date: action.date || new Date().toISOString(),
    startTime: action.start_time || '10:00',
    endTime: action.end_time || '12:00',
    location: action.location || '',
    coordinates: coordinates,
    maxParticipants: action.max_participants || 20,
    currentParticipants: action.current_participants || 0,
    status: action.status || 'upcoming',
    imageUrl: action.image_url || null,
    distance: action.distance || null,
    duration: action.duration || null,
    difficulty: action.difficulty || 'medium',
    elevation: action.elevation || null,
    wasteData: wasteData,
    createdAt: action.created_at || new Date().toISOString(),
    updatedAt: action.updated_at || new Date().toISOString()
  };
};

export const transformActionToDb = (action: any) => {
  // Log the incoming action data for debugging
  console.log('Transforming action to DB format:', action);
  
  // Convert coordinates and wasteData to proper format for storage
  const coordinates = typeof action.coordinates === 'string' 
    ? action.coordinates 
    : JSON.stringify(action.coordinates);
  
  const waste_data = action.wasteData && typeof action.wasteData !== 'string' 
    ? JSON.stringify(action.wasteData) 
    : action.wasteData || null;
  
  return {
    title: action.title,
    description: action.description,
    type: action.type,
    date: action.date,
    start_time: action.startTime,  // Convert camelCase to snake_case for DB
    end_time: action.endTime,      // Convert camelCase to snake_case for DB
    location: action.location,
    coordinates: coordinates,
    max_participants: action.maxParticipants,  // Convert camelCase to snake_case for DB
    current_participants: action.currentParticipants,  // Convert camelCase to snake_case for DB
    status: action.status,
    image_url: action.imageUrl,    // Convert camelCase to snake_case for DB
    distance: action.distance,
    duration: action.duration,
    difficulty: action.difficulty,
    elevation: action.elevation,
    waste_data: waste_data   // Convert camelCase to snake_case for DB
  };
};
