
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { CalendarDays, MapPin, Users, Scale } from 'lucide-react';

// Données pour les actions passées
const pastActions = [
  {
    id: 1,
    title: "Grande collecte au Parc National des Calanques",
    date: "15 avril 2023",
    location: "Marseille",
    participants: 42,
    wasteCollected: 78.5,
    description: "Une journée exceptionnelle avec nos bénévoles qui a permis de débarrasser les Calanques de près de 80kg de déchets.",
    imageUrl: "https://images.unsplash.com/photo-1565014173859-4e252b4494f4?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 2,
    title: "Nettoyage des berges de la Seine",
    date: "28 mai 2023",
    location: "Paris",
    participants: 35,
    wasteCollected: 65,
    description: "Action menée en collaboration avec la mairie de Paris pour nettoyer les berges de la Seine des déchets plastiques.",
    imageUrl: "https://images.unsplash.com/photo-1605900542637-951f11f9d895?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 3,
    title: "Journée de sensibilisation et clean-up",
    date: "12 juin 2023",
    location: "Lac d'Annecy",
    participants: 28,
    wasteCollected: 43.2,
    description: "Journée combinant nettoyage et ateliers de sensibilisation sur l'importance de préserver nos lacs.",
    imageUrl: "https://images.unsplash.com/photo-1529602383563-f9aaf9a36bba?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 4,
    title: "Opération forêt propre",
    date: "2 juillet 2023",
    location: "Forêt de Fontainebleau",
    participants: 25,
    wasteCollected: 37.8,
    description: "Randonnée de nettoyage dans l'une des plus belles forêts de France, suivie d'un pique-nique zéro déchet.",
    imageUrl: "https://images.unsplash.com/photo-1448375240586-882707db888b?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 5,
    title: "Clean-up urbain",
    date: "18 août 2023",
    location: "Lyon",
    participants: 32,
    wasteCollected: 51.3,
    description: "Nettoyage des rues et des espaces verts dans plusieurs quartiers de Lyon, en partenariat avec des associations locales.",
    imageUrl: "https://images.unsplash.com/photo-1584974292709-5c2f0619971b?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 6,
    title: "Littoral propre",
    date: "9 septembre 2023",
    location: "Biarritz",
    participants: 45,
    wasteCollected: 83.7,
    description: "Grande action de nettoyage sur les plages de Biarritz, avec tri et analyse des déchets collectés.",
    imageUrl: "https://images.unsplash.com/photo-1626268220023-33e6d873992b?auto=format&fit=crop&w=800&q=80"
  },
];

// Calculer les statistiques totales
const totalStats = pastActions.reduce((acc, action) => {
  return {
    totalParticipants: acc.totalParticipants + action.participants,
    totalWasteCollected: acc.totalWasteCollected + action.wasteCollected,
    totalActions: acc.totalActions + 1
  };
}, { totalParticipants: 0, totalWasteCollected: 0, totalActions: 0 });

const ActionsPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="pt-32 pb-16 bg-green-50">
        <div className="container-custom">
          <div className="flex flex-col items-center text-center mb-8">
            <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
              Historique
            </span>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">Nos actions passées</h1>
            <p className="text-lg text-gray-600 max-w-3xl text-balance">
              Découvrez les actions de nettoyage que nous avons menées et l'impact que nous avons eu sur l'environnement.
            </p>
          </div>
          
          {/* Global Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
            <div className="bg-white rounded-xl shadow-md p-6 flex items-center">
              <div className="bg-green-100 p-3 rounded-full mr-4">
                <CalendarDays className="w-8 h-8 text-green-700" />
              </div>
              <div>
                <div className="text-3xl font-bold text-green-700">{totalStats.totalActions}</div>
                <div className="text-gray-600">Actions réalisées</div>
              </div>
            </div>
            
            <div className="bg-white rounded-xl shadow-md p-6 flex items-center">
              <div className="bg-green-100 p-3 rounded-full mr-4">
                <Users className="w-8 h-8 text-green-700" />
              </div>
              <div>
                <div className="text-3xl font-bold text-green-700">{totalStats.totalParticipants}</div>
                <div className="text-gray-600">Participants</div>
              </div>
            </div>
            
            <div className="bg-white rounded-xl shadow-md p-6 flex items-center">
              <div className="bg-green-100 p-3 rounded-full mr-4">
                <Scale className="w-8 h-8 text-green-700" />
              </div>
              <div>
                <div className="text-3xl font-bold text-green-700">{totalStats.totalWasteCollected.toFixed(1)} kg</div>
                <div className="text-gray-600">Déchets collectés</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Past Actions List */}
      <section className="py-24 bg-white">
        <div className="container-custom">
          <h2 className="section-title text-center mb-16">Toutes nos actions</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pastActions.map(action => (
              <div key={action.id} className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300">
                <div className="relative h-60 overflow-hidden">
                  <img 
                    src={action.imageUrl} 
                    alt={action.title} 
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                  />
                  <div className="absolute top-4 right-4 bg-green-700 text-white px-3 py-1 rounded-full text-sm font-medium">
                    {action.date}
                  </div>
                </div>
                
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 mb-3">{action.title}</h3>
                  
                  <div className="flex items-center text-gray-600 mb-2">
                    <MapPin className="w-4 h-4 mr-2 text-green-600" />
                    <span>{action.location}</span>
                  </div>
                  
                  <div className="flex justify-between mb-4">
                    <div className="flex items-center">
                      <Users className="w-4 h-4 mr-1 text-green-600" />
                      <span className="text-gray-700">{action.participants} participants</span>
                    </div>
                    <div className="flex items-center">
                      <Scale className="w-4 h-4 mr-1 text-green-600" />
                      <span className="text-gray-700">{action.wasteCollected} kg</span>
                    </div>
                  </div>
                  
                  <p className="text-gray-600 mb-4">
                    {action.description}
                  </p>
                  
                  <a 
                    href={`#action-${action.id}`} 
                    className="inline-flex items-center text-green-700 hover:text-green-600 font-medium"
                  >
                    Voir les photos
                    <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* Annual Reports */}
      <section className="py-24 bg-green-50">
        <div className="container-custom">
          <div className="flex flex-col items-center text-center mb-16">
            <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
              Rapports
            </span>
            <h2 className="section-title mb-4">
              Nos rapports annuels
            </h2>
            <p className="text-lg text-gray-600 max-w-3xl text-balance">
              Consultez nos rapports détaillés sur les actions réalisées chaque année
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <a 
              href="#report-2023" 
              className="bg-white rounded-xl p-8 shadow-md hover:shadow-lg transition-all flex flex-col items-center"
            >
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="text-green-700">
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Rapport 2023</h3>
              <p className="text-gray-600 text-center mb-4">Une année record avec plus de 350kg de déchets collectés lors de nos actions.</p>
              <span className="text-green-700 font-medium">Télécharger (PDF)</span>
            </a>
            
            <a 
              href="#report-2022" 
              className="bg-white rounded-xl p-8 shadow-md hover:shadow-lg transition-all flex flex-col items-center"
            >
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="text-green-700">
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Rapport 2022</h3>
              <p className="text-gray-600 text-center mb-4">L'année de notre création avec nos premières actions et succès.</p>
              <span className="text-green-700 font-medium">Télécharger (PDF)</span>
            </a>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default ActionsPage;
