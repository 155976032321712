
import React from 'react';
import { Route } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import InventoryListPage from '@/pages/admin/inventory/InventoryListPage';
import NewInventoryItemPage from '@/pages/admin/inventory/NewInventoryItemPage';
import EditInventoryItemPage from '@/pages/admin/inventory/EditInventoryItemPage';
import StockMovementPage from '@/pages/admin/inventory/StockMovementPage';

/**
 * Inventory management routes with permission guards
 */
export const inventoryRoutes = [
  <Route
    key="inventory"
    path="/admin/inventory"
    element={
      <PermissionGuard permission="manage_inventory">
        <InventoryListPage />
      </PermissionGuard>
    }
  />,
  <Route
    key="inventory-new"
    path="/admin/inventory/new"
    element={
      <PermissionGuard permission="manage_inventory">
        <NewInventoryItemPage />
      </PermissionGuard>
    }
  />,
  <Route
    key="inventory-edit"
    path="/admin/inventory/edit/:id"
    element={
      <PermissionGuard permission="manage_inventory">
        <EditInventoryItemPage />
      </PermissionGuard>
    }
  />,
  <Route
    key="inventory-stock"
    path="/admin/inventory/stock/:id"
    element={
      <PermissionGuard permission="manage_inventory">
        <StockMovementPage />
      </PermissionGuard>
    }
  />,
];
