
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { CleanupAction } from "@/types/database";
import { ActionFormValues } from "../schemas/actionSchema";
import { actionsApi } from "@/services/actions";
import { useQueryClient } from "@tanstack/react-query";

export const useFormSubmit = (action?: CleanupAction) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const formatWasteData = (values: ActionFormValues) => {
    return values.status === "completed" 
      ? {
          totalWeight: values.wasteData?.totalWeight || 0,
          categories: {
            plastic: values.wasteData?.categories?.plastic || 0,
            paper: values.wasteData?.categories?.paper || 0,
            metal: values.wasteData?.categories?.metal || 0,
            glass: values.wasteData?.categories?.glass || 0,
            organic: values.wasteData?.categories?.organic || 0,
            cigarettes: values.wasteData?.categories?.cigarettes || 0,
            other: values.wasteData?.categories?.other || 0
          }
        }
      : {
          totalWeight: 0,
          categories: {
            plastic: 0,
            paper: 0,
            metal: 0,
            glass: 0,
            organic: 0,
            cigarettes: 0,
            other: 0
          }
        };
  };

  const onSubmit = async (values: ActionFormValues) => {
    setIsSubmitting(true);
    console.log("Submitting form with values:", values);
    
    try {
      // Ensure wasteData is always properly formatted with required fields
      const formattedWasteData = formatWasteData(values);
      
      // Parse numeric fields to ensure they're numbers not strings
      const maxParticipants = typeof values.maxParticipants === 'string' 
        ? parseInt(values.maxParticipants as string, 10) 
        : values.maxParticipants;
      
      if (action?.id) {
        // Update existing action using the dedicated update function
        console.log("Updating action with ID:", action.id);
        await actionsApi.update(action.id, {
          title: values.title,
          description: values.description,
          type: values.type,
          date: values.date,
          startTime: values.startTime,
          endTime: values.endTime,
          location: values.location,
          coordinates: {
            latitude: values.coordinates.latitude,
            longitude: values.coordinates.longitude
          },
          maxParticipants: maxParticipants,
          status: values.status,
          imageUrl: values.imageUrl,
          // New properties
          distance: values.distance,
          duration: values.duration,
          difficulty: values.difficulty,
          elevation: values.elevation,
          // Using our formatted wasteData to ensure type correctness
          wasteData: formattedWasteData
        });
        toast({
          title: "Action modifiée",
          description: "L'action a été mise à jour avec succès",
        });
      } else {
        // Create new action using direct Supabase service
        console.log("Creating new action with date:", values.date);
        await actionsApi.create({
          title: values.title,
          description: values.description,
          type: values.type,
          date: values.date,
          startTime: values.startTime,
          endTime: values.endTime,
          location: values.location,
          coordinates: {
            latitude: values.coordinates.latitude,
            longitude: values.coordinates.longitude
          },
          maxParticipants: maxParticipants,
          status: values.status,
          currentParticipants: 0,
          imageUrl: values.imageUrl,
          distance: values.distance,
          duration: values.duration,
          difficulty: values.difficulty,
          elevation: values.elevation,
          wasteData: formattedWasteData
        });
        toast({
          title: "Action créée",
          description: "L'action a été créée avec succès",
        });
      }
      
      // Invalidate both the 'actions' query and any specific action query
      // This will force a refresh of the actions list when returning to it
      queryClient.invalidateQueries({ queryKey: ['actions'] });
      if (action?.id) {
        queryClient.invalidateQueries({ queryKey: ['action', action.id] });
      }
      
      navigate('/admin/actions');
    } catch (error) {
      console.error("Error saving action:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'enregistrement: " + (error.message || error),
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    onSubmit,
    isSubmitting
  };
};
