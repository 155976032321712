
import React from "react";

type StatusProps = {
  status: string;
};

const ActionStatus = ({ status }: StatusProps) => {
  // Get status display name
  const getStatusDisplay = (status: string) => {
    switch (status) {
      case 'upcoming': return 'À venir';
      case 'completed': return 'Terminée';
      case 'cancelled': return 'Annulée';
      default: return status;
    }
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs ${
      status === 'upcoming' ? 'bg-green-100 text-green-800' : 
      status === 'cancelled' ? 'bg-red-100 text-red-800' :
      'bg-gray-100 text-gray-800'
    }`}>
      {getStatusDisplay(status)}
    </span>
  );
};

export default ActionStatus;
