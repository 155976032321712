
import React from "react";
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { MonthlyTotals } from "@/types/invoice";

interface AnnualTrendChartProps {
  monthlyTotals: MonthlyTotals[];
  formatCurrency: (amount: number) => string;
}

const AnnualTrendChart = ({ monthlyTotals, formatCurrency }: AnnualTrendChartProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Tendance annuelle</CardTitle>
        <CardDescription>
          Évolution cumulée des recettes et dépenses
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={monthlyTotals}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip 
                formatter={(value) => [formatCurrency(value as number), ""]}
                labelFormatter={(label) => `${label}`}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="income" 
                name="Recettes" 
                stroke="#4ade80" 
                activeDot={{ r: 8 }} 
              />
              <Line 
                type="monotone" 
                dataKey="expenses" 
                name="Dépenses" 
                stroke="#f87171" 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default AnnualTrendChart;
