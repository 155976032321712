
import { CleanupAction } from '@/types/database';

// Export the existing actions API methods
import { getAll, getById } from './getActions';
import { create } from './createAction';
import { update } from './updateAction';
import { deleteAction } from './deleteAction';

// Export a unified API for actions that doesn't check permissions
export const actionsApi = {
  getAll,
  getById,
  create,
  update,
  delete: deleteAction
};
