
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CleanupAction } from "@/types/database";
import { actionSchema, ActionFormValues } from "../schemas/actionSchema";
import { getInitialFormValues } from "./useFormInitializer";
import { useCoordinates } from "./useCoordinates";
import { useFormSubmit } from "./useFormSubmit";

export const useActionForm = (action?: CleanupAction) => {
  // Get initial values for the form
  const { initialValues, initialCoordinates, initialDate } = getInitialFormValues(action);
  
  // Setup form with validation
  const form = useForm<ActionFormValues>({
    resolver: zodResolver(actionSchema),
    defaultValues: initialValues,
  });
  
  // Setup coordinates handling
  const { 
    latitude, 
    longitude, 
    setLatitude, 
    setLongitude, 
    handleCoordinateChange 
  } = useCoordinates(form, initialCoordinates);
  
  // Setup form submission handling
  const { onSubmit, isSubmitting } = useFormSubmit(action);

  return {
    form,
    latitude,
    longitude,
    setLatitude,
    setLongitude,
    handleCoordinateChange,
    onSubmit,
    isSubmitting
  };
};
