
import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import StatsCard from './StatsCard';
import { CleanupAction } from '@/types/database';

const Stats = () => {
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef<HTMLDivElement>(null);
  
  // Fetch actions directly from Supabase, setting staleTime to 0 to ensure fresh data
  const { data: actions = [], isLoading } = useQuery({
    queryKey: ['actions-stats-direct'],
    queryFn: async () => {
      console.log('Stats component is fetching actions data directly from Supabase...');
      
      // Direct query with logging
      const { data, error } = await supabase
        .from('actions')
        .select('*');
      
      if (error) {
        console.error('Error fetching stats data:', error);
        throw error;
      }
      
      console.log(`Stats received ${data?.length || 0} actions directly from Supabase`);
      
      if (data && data.length > 0) {
        console.log('Sample action data:', data[0]);
      } else {
        console.log('No actions data received from Supabase');
      }
      
      return data || [];
    },
    staleTime: 0, // Fetch fresh data every time
    refetchOnWindowFocus: true,
  });
  
  useEffect(() => {
    console.log(`Stats component has ${actions.length} actions loaded`);
    if (actions.length > 0) {
      console.log('First action sample:', actions[0]);
    }
  }, [actions]);
  
  // Calculate statistics from actions data
  const calculateStats = (actionsData: any[]) => {
    console.log(`Calculating stats from ${actionsData.length} actions`);
    
    if (!actionsData || actionsData.length === 0) {
      console.log('No actions data available for stats calculation');
      return { wasteCollected: 0, participants: 0, completedSessions: 0 };
    }
    
    // Only use completed actions for waste calculation
    const completedActions = actionsData.filter(action => action.status === 'completed');
    console.log(`Found ${completedActions.length} completed actions for stats`);
    
    // Calculate total waste collected
    let totalWaste = 0;
    completedActions.forEach(action => {
      try {
        if (!action.waste_data) {
          console.log(`Action ${action.id} has no waste_data`);
          return;
        }
        
        // Handle waste_data properly whether it's a string or an object
        let wasteData = action.waste_data;
        if (typeof wasteData === 'string') {
          try {
            wasteData = JSON.parse(wasteData);
          } catch (e) {
            console.error(`Error parsing waste_data for action ${action.id}:`, e);
            wasteData = { totalWeight: 0 };
          }
        }
        
        const weight = wasteData?.totalWeight || 0;
        console.log(`Action ${action.id}: adding ${weight}kg waste`);
        totalWaste += weight;
      } catch (e) {
        console.error(`Error processing waste data for action ${action.id}:`, e);
      }
    });
    
    // Count total participants across all actions
    const totalParticipants = actionsData.reduce((sum, action) => {
      return sum + (action.current_participants || 0);
    }, 0);
    
    // Count number of completed sessions
    const completedSessions = completedActions.length;
    
    const result = {
      wasteCollected: Math.round(totalWaste),
      participants: totalParticipants,
      completedSessions
    };
    
    console.log('Calculated stats:', result);
    
    return result;
  };
  
  const stats = calculateStats(actions);
  
  // Intersection Observer to trigger animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log('Stats section is now visible, triggering animation');
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    
    if (statsRef.current) {
      observer.observe(statsRef.current);
    }
    
    return () => observer.disconnect();
  }, []);
  
  return (
    <div ref={statsRef} className="py-16 bg-green-50">
      <div className="container-custom">
        <h2 className="section-title text-center mb-12">Notre impact</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Waste collected stat */}
          <StatsCard
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </svg>
            }
            value={`${stats.wasteCollected} kg`}
            title="de déchets collectés"
            description="récupérés lors de nos randonnées"
            isVisible={isVisible}
          />
          
          {/* Participants stat */}
          <StatsCard
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            }
            value={stats.participants}
            title="participants"
            description="qui nous ont rejoints lors de nos actions"
            delay={100}
            isVisible={isVisible}
          />
          
          {/* Sessions stat */}
          <StatsCard
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            }
            value={stats.completedSessions}
            title="sessions"
            description="de nettoyage organisées depuis notre création"
            delay={200}
            isVisible={isVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
