
import React from 'react';
import AdminLayout from '../AdminLayout';
import GalleryForm from './GalleryForm';

const NewGalleryItemPage = () => {
  return (
    <AdminLayout>
      <GalleryForm />
    </AdminLayout>
  );
};

export default NewGalleryItemPage;
