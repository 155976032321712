
import React from "react";
import { Card } from "@/components/ui/card";
import { Calendar, Users } from "lucide-react";
import { CleanupAction } from "@/types/database";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

interface UpcomingEventsProps {
  actions: CleanupAction[];
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ actions = [] }) => {
  // Get upcoming events
  const getUpcomingEvents = () => {
    if (!actions || actions.length === 0) {
      return [];
    }

    const now = new Date();

    // Filter for upcoming actions and sort by date
    const upcomingActions = actions
      .filter(action => action.status === "upcoming" && new Date(action.date) >= now)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // Return the next 5 upcoming events with formatted data
    return upcomingActions.slice(0, 5).map(action => ({
      id: action.id,
      title: action.title,
      date: format(new Date(action.date), "d MMMM yyyy", { locale: fr }),
      time: `${action.startTime} - ${action.endTime}`,
      location: action.location,
      attendees: action.currentParticipants,
      maxAttendees: action.maxParticipants
    }));
  };

  const upcomingEvents = getUpcomingEvents();

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Actions à venir</h2>
        <Calendar className="h-5 w-5 text-gray-400" />
      </div>
      <div className="space-y-4">
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map((event) => (
            <div key={event.id} className="flex items-start border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <div className="flex-1">
                <h3 className="text-sm font-medium">{event.title}</h3>
                <div className="flex items-center text-xs text-gray-500 mt-1">
                  <span>{event.date}</span>
                  <span className="mx-2">•</span>
                  <span>{event.time}</span>
                </div>
                <div className="text-xs text-gray-600 mt-1">{event.location}</div>
              </div>
              <div className="text-right">
                <div className="flex items-center justify-end">
                  <Users className="h-3.5 w-3.5 text-gray-400 mr-1" />
                  <span className="text-xs text-gray-500">{event.attendees}/{event.maxAttendees}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4 text-gray-500">
            Aucune action à venir
          </div>
        )}
      </div>
    </Card>
  );
};

export default UpcomingEvents;
