
import React from "react";
import { Users } from "lucide-react";

type ParticipantsProps = {
  current: number;
  max: number;
};

const ActionParticipants = ({ current, max }: ParticipantsProps) => {
  // Calculate percentage of participants
  const percentage = max > 0 ? (current / max) * 100 : 0;
  
  // Determine color based on capacity
  let textColor = "text-green-600";
  if (percentage >= 85) {
    textColor = "text-red-600"; // Almost full
  } else if (percentage >= 60) {
    textColor = "text-amber-600"; // More than half capacity
  }
  
  return (
    <div className="flex items-center gap-1">
      <Users className="h-3.5 w-3.5 text-gray-500" />
      <span className={`font-medium ${textColor}`}>
        {current}/{max}
      </span>
    </div>
  );
};

export default ActionParticipants;
