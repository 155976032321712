
import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for default marker icons in Leaflet
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Définition des lieux de passage de GreenWild
const cleanupLocations = [
  { name: "Ambrussum, Villetelle", description: "Nettoyage en balade du site archéologique", coordinates: [43.7190242, 4.1480012] },
  { name: "Massif de la Gardiole, Gigean", description: "Nettoyage en randonnée", coordinates: [43.48, 3.730556] },
  { name: "Promenade du Lido, Sète", description: "Nettoyage en balade du Lido", coordinates: [43.393242, 3.660221] },
  { name: "Le Rocher des deux trous", description: "Nettoyage en randonnée", coordinates: [43.762324, 4.84167] },
  { name: "Pointe des Poulains, Sauzon", description: "Découverte et nettoyage de l'une des pointes les plus célèbres de Belle-Île-en-Mer", coordinates: [47.3893536, -3.2520276] },
  { name: "Plage des Galères, Sauzon", description: "Nettoyage de la plage des Galères", coordinates: [47.310842, -3.057186] },
  { name: "Port Lost-Kah, Locmaria", description: "Activité de nettoyage au Port Lost-Kah", coordinates: [47.288473, -3.1136996] },
];

const Map = () => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      // Créer un icône par défaut pour les marqueurs
      const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });
      
      L.Marker.prototype.options.icon = DefaultIcon;
      
      // Initialiser la carte
      const mapInstance = L.map(mapRef.current).setView([46.5, 2.3522], 5);
      mapInstanceRef.current = mapInstance;
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapInstance);
      
      // Ajouter les marqueurs pour chaque lieu
      cleanupLocations.forEach(location => {
        const marker = L.marker([location.coordinates[0], location.coordinates[1]]).addTo(mapInstance);
        marker.bindPopup(`
          <div class="p-2">
            <strong class="block text-green-800 mb-1">${location.name}</strong>
            <p class="text-gray-700 text-sm">${location.description}</p>
          </div>
        `);
      });
    }
    
    // Nettoyage lors du démontage du composant
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, []);
  
  return (
    <div ref={mapRef} className="relative w-full h-[500px] rounded-xl overflow-hidden shadow-lg"></div>
  );
};

export default Map;
