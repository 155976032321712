
import React from 'react';
import { Edit, Trash, Eye } from 'lucide-react';
import { GalleryItem } from '@/types/database';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface GalleryGridProps {
  items: GalleryItem[];
  onEdit: (id: string) => void;
  onDelete: (item: GalleryItem) => void;
}

const GalleryGrid: React.FC<GalleryGridProps> = ({ items, onEdit, onDelete }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {items.map((item) => (
        <div key={item.id} className="group relative overflow-hidden bg-white border rounded-lg shadow-sm">
          <div className="relative aspect-w-16 aspect-h-9 bg-gray-200 overflow-hidden">
            <img
              src={item.imageUrl}
              alt={item.title}
              className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
              loading="lazy"
            />
            
            {/* Image actions overlay */}
            <div className="absolute inset-0 bg-black/0 group-hover:bg-black/50 transition-colors duration-300">
              <div className="absolute inset-0 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Button
                  size="sm"
                  variant="secondary"
                  className="h-9 w-9 p-0 rounded-full bg-white/90 text-gray-700 hover:bg-white"
                  onClick={() => window.open(item.imageUrl, '_blank')}
                >
                  <Eye className="h-4 w-4" />
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  className="h-9 w-9 p-0 rounded-full bg-white/90 text-gray-700 hover:bg-white"
                  onClick={() => onEdit(item.id)}
                >
                  <Edit className="h-4 w-4" />
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  className="h-9 w-9 p-0 rounded-full bg-white/90 text-red-500 hover:bg-white hover:text-red-600"
                  onClick={() => onDelete(item)}
                >
                  <Trash className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
          
          <div className="p-4">
            <h3 className="font-semibold text-lg line-clamp-1">{item.title}</h3>
            {item.description && (
              <p className="text-gray-600 text-sm mt-1 line-clamp-2">{item.description}</p>
            )}
            
            <div className="mt-2 flex flex-wrap gap-1">
              {item.tags && item.tags.map((tag) => (
                <span 
                  key={tag} 
                  className="inline-block px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800"
                >
                  {tag}
                </span>
              ))}
            </div>
            
            <div className="mt-3 text-xs text-gray-500">
              {item.date && format(new Date(item.date), 'PPP', { locale: fr })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GalleryGrid;
