
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { galleryApi } from '@/services/api';
import AdminLayout from '../AdminLayout';
import GalleryForm from './GalleryForm';

const EditGalleryItemPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const { data: galleryItem, isLoading, isError } = useQuery({
    queryKey: ['galleryItem', id],
    queryFn: () => id ? galleryApi.getById(id) : Promise.reject('No ID provided'),
    enabled: !!id,
  });
  
  if (isLoading) {
    return (
      <AdminLayout>
        <div className="container py-6 flex justify-center">
          Chargement de l'image...
        </div>
      </AdminLayout>
    );
  }
  
  if (isError || !galleryItem) {
    return (
      <AdminLayout>
        <div className="container py-6">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-red-600">
              Erreur : Image non trouvée
            </h2>
            <p className="mt-2 text-gray-600">
              L'image que vous cherchez n'existe pas ou a été supprimée.
            </p>
            <button
              onClick={() => navigate('/admin/gallery')}
              className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              Retour à la galerie
            </button>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  return (
    <AdminLayout>
      <GalleryForm galleryItem={galleryItem} />
    </AdminLayout>
  );
};

export default EditGalleryItemPage;
