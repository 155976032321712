
import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { inventoryApi } from "@/services/api";
import InventoryForm from "./InventoryForm";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Loader2, AlertTriangle } from "lucide-react";
import AdminLayout from "../AdminLayout";

const EditInventoryItemPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const { data: inventoryItem, isLoading, error } = useQuery({
    queryKey: ['inventoryItem', id],
    queryFn: () => inventoryApi.getById(id!),
    enabled: !!id,
  });
  
  if (isLoading) {
    return (
      <AdminLayout>
        <div className="container py-6 flex justify-center">
          <div className="flex flex-col items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin text-green-600 mb-4" />
            <p className="text-gray-500">Chargement de l'article...</p>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  if (error || !inventoryItem) {
    return (
      <AdminLayout>
        <div className="container py-6 flex justify-center">
          <div className="flex flex-col items-center justify-center">
            <AlertTriangle className="h-12 w-12 text-red-500 mb-4" />
            <h3 className="text-lg font-semibold">Article non trouvé</h3>
            <p className="text-gray-500 mt-1 mb-4">L'article que vous cherchez n'existe pas ou a été supprimé</p>
            <Button onClick={() => navigate('/admin/inventory')}>
              Retour à la liste
            </Button>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  return (
    <AdminLayout>
      <div className="container py-6">
        <div className="mb-6">
          <Link to="/admin/inventory">
            <Button variant="ghost" className="p-0 h-auto mb-4">
              <ArrowLeft className="h-4 w-4 mr-2" />
              Retour à la liste
            </Button>
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">Modifier un article</h1>
          <p className="text-gray-500 mt-1">
            Modifiez les détails de l'article
          </p>
        </div>
        <InventoryForm inventoryItem={inventoryItem} />
      </div>
    </AdminLayout>
  );
};

export default EditInventoryItemPage;
