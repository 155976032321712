
import { useState, useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { ActionFormValues } from "../schemas/actionSchema";

export const useCoordinates = (
  form: UseFormReturn<ActionFormValues>,
  initialCoordinates: { latitude: number; longitude: number }
) => {
  const [latitude, setLatitude] = useState(initialCoordinates.latitude);
  const [longitude, setLongitude] = useState(initialCoordinates.longitude);

  // Update the coordinates in the form when lat/lng inputs change
  const handleCoordinateChange = useCallback(() => {
    form.setValue('coordinates', { 
      latitude, 
      longitude 
    });
  }, [form, latitude, longitude]);

  return {
    latitude,
    longitude,
    setLatitude,
    setLongitude,
    handleCoordinateChange
  };
};
