
import React from "react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Loader2 } from "lucide-react";

export interface FormActionsProps {
  isSubmitting: boolean;
  isEditing?: boolean;
  submitText: string;
  cancelHref: string;
  showDelete?: boolean;
  onDelete?: () => void;
}

const FormActions: React.FC<FormActionsProps> = ({
  isSubmitting,
  isEditing,
  submitText,
  cancelHref,
  showDelete = false,
  onDelete,
}) => {
  return (
    <div className="flex items-center justify-end space-x-2 pt-4">
      {showDelete && isEditing && (
        <Button
          variant="destructive"
          type="button"
          onClick={onDelete}
          disabled={isSubmitting}
        >
          Supprimer
        </Button>
      )}
      <div className="flex-grow"></div>
      <Button
        variant="outline"
        type="button"
        asChild
      >
        <Link to={cancelHref}>Annuler</Link>
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {submitText}
      </Button>
    </div>
  );
};

export default FormActions;
