
import { useState, useEffect, useRef } from "react";
import { Leaf, Heart, Globe, Users } from "lucide-react";

const features = [
  {
    title: "Passion pour la nature",
    description:
      "Notre équipe est animée par un amour profond pour la nature et une volonté inébranlable de la préserver pour les générations futures.",
    icon: <Heart className="w-10 h-10 text-green-600 group-hover:scale-110 transition-transform duration-300" />,
  },
  {
    title: "Actions concrètes",
    description:
      "Nous organisons régulièrement des randonnées de nettoyage et des événements écologiques pour avoir un impact tangible sur notre environnement.",
    icon: <Leaf className="w-10 h-10 text-green-600 group-hover:scale-110 transition-transform duration-300" />,
  },
  {
    title: "Engagement communautaire",
    description:
      "Nous croyons en la force du collectif. Chacun peut contribuer, à son échelle, à la préservation de notre planète.",
    icon: <Users className="w-10 h-10 text-green-600 group-hover:scale-110 transition-transform duration-300" />,
  },
  {
    title: "Vision durable",
    description:
      "Notre objectif est de sensibiliser et d'éduquer pour créer un mouvement de préservation de l'environnement qui perdure dans le temps.",
    icon: <Globe className="w-10 h-10 text-green-600 group-hover:scale-110 transition-transform duration-300" />,
  },
];

const Features = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // Start revealing items with a staggered delay
          const timers: NodeJS.Timeout[] = [];
          features.forEach((_, index) => {
            const timer = setTimeout(() => {
              setVisibleItems((prev) => [...prev, index]);
            }, 200 * index);
            timers.push(timer);
          });

          return () => {
            timers.forEach(clearTimeout);
          };
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      id="about"
      ref={sectionRef}
      className="py-24 bg-gradient-to-b from-white to-green-50"
    >
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-16">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Qui sommes-nous
          </span>
          <h2 className="section-title mb-4">
            Une équipe engagée pour l'environnement
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl text-balance">
            GreenWild est né de la volonté d'un groupe d'amis passionnés par la nature de faire une différence concrète 
            pour préserver nos écosystèmes. Notre mission est simple : nettoyer, préserver et sensibiliser.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`group p-6 rounded-xl bg-white border border-green-100 shadow-sm transition-all duration-700 transform hover:shadow-lg hover:-translate-y-2 ${
                visibleItems.includes(index)
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="mb-4 transition-transform duration-300">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-3 group-hover:text-green-700 transition-colors">{feature.title}</h3>
              <p className="text-gray-600 group-hover:text-gray-700 transition-colors">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
