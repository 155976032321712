
import React from "react";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import { useNavigate } from "react-router-dom";

type EmptyStateProps = {
  hasFilters?: boolean;
  onAdd?: () => void;
};

const EmptyState = ({ hasFilters = false, onAdd }: EmptyStateProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center p-12 text-center">
      <Calendar className="h-12 w-12 text-gray-400 mb-4" />
      <h3 className="text-lg font-semibold">Aucune action trouvée</h3>
      <p className="text-gray-500 mt-1">
        {hasFilters 
          ? "Aucune action ne correspond aux filtres sélectionnés" 
          : "Commencez par créer votre première action"}
      </p>
      {!hasFilters && (
        <Button 
          className="mt-4"
          onClick={onAdd ? onAdd : () => navigate('/admin/actions/new')}
        >
          <Calendar className="h-4 w-4 mr-2" />
          Créer une action
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
