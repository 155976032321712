
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { 
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { Clock, ArrowUp, Compass } from "lucide-react";
import { ActionFormValues } from "../schemas/actionSchema";

interface HikingDetailsSectionProps {
  form: UseFormReturn<ActionFormValues>;
}

const HikingDetailsSection: React.FC<HikingDetailsSectionProps> = ({ form }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Détails de la randonnée</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Durée */}
        <FormField
          control={form.control}
          name="duration"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center">
                <Clock className="h-4 w-4 mr-2 text-gray-500" />
                Durée
              </FormLabel>
              <FormControl>
                <Input placeholder="ex: 3h30" {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        
        {/* Distance */}
        <FormField
          control={form.control}
          name="distance"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Distance</FormLabel>
              <FormControl>
                <Input placeholder="ex: 8km" {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        
        {/* Difficulté */}
        <FormField
          control={form.control}
          name="difficulty"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center">
                <Compass className="h-4 w-4 mr-2 text-gray-500" />
                Difficulté
              </FormLabel>
              <Select 
                onValueChange={field.onChange} 
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Sélectionner la difficulté" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="easy">Facile</SelectItem>
                  <SelectItem value="medium">Moyen</SelectItem>
                  <SelectItem value="hard">Difficile</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        
        {/* Dénivelé */}
        <FormField
          control={form.control}
          name="elevation"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center">
                <ArrowUp className="h-4 w-4 mr-2 text-gray-500" />
                Dénivelé
              </FormLabel>
              <FormControl>
                <Input placeholder="ex: 450m" {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default HikingDetailsSection;
