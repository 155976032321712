
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { invoicesApi } from "@/services/api";
import InvoiceForm from "./InvoiceForm";
import AdminLayout from "../AdminLayout";

const EditInvoicePage = () => {
  const { id } = useParams<{ id: string }>();
  
  const { data: invoice, isLoading } = useQuery({
    queryKey: ['invoice', id],
    queryFn: () => invoicesApi.getById(id!),
    enabled: !!id,
    staleTime: 0 // Forces a refetch on component mount
  });
  
  if (isLoading) {
    return <AdminLayout><div className="container py-6 flex justify-center">Chargement...</div></AdminLayout>;
  }
  
  if (!invoice) {
    return (
      <AdminLayout>
        <div className="container py-6 flex justify-center">
          Facture non trouvée. Vérifiez l'URL ou retournez à la liste des factures.
        </div>
      </AdminLayout>
    );
  }
  
  return (
    <AdminLayout>
      <div className="container py-6">
        <InvoiceForm invoice={invoice} />
      </div>
    </AdminLayout>
  );
};

export default EditInvoicePage;
