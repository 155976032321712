import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useToast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invoicesApi } from "@/services/api";
import { Invoice } from "@/types/database";
import { InvoiceFormValues } from "@/types/invoice";
import { ArrowLeft } from "lucide-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Loader2, Upload, FileText } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import FormActions from "../actions/components/FormActions";

const invoiceFormSchema = z.object({
  number: z.string().min(1, { message: "Le numéro de facture est requis" }),
  title: z.string().min(3, { message: "Le titre doit contenir au moins 3 caractères" }),
  vendor: z.string().min(1, { message: "Le fournisseur est requis" }),
  amount: z.coerce.number().min(0, { message: "Le montant doit être un nombre positif" }),
  category: z.enum(["equipment", "consumables", "services", "rent", "other"], {
    required_error: "Veuillez sélectionner une catégorie",
  }),
  date: z.string().min(1, { message: "La date est requise" }),
  dueDate: z.string().optional(),
  status: z.enum(["paid", "pending", "overdue"], {
    required_error: "Veuillez sélectionner un statut",
  }),
  recurring: z.boolean().default(false),
  notes: z.string().optional(),
  fileUrl: z.string().optional(),
});

type InvoiceFormSchema = z.infer<typeof invoiceFormSchema>;

interface InvoiceFormProps {
  invoice?: Invoice;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({ invoice }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  const form = useForm<InvoiceFormSchema>({
    resolver: zodResolver(invoiceFormSchema),
    defaultValues: {
      number: invoice?.number || "",
      title: invoice?.title || "",
      vendor: invoice?.vendor || "",
      amount: invoice?.amount || 0,
      category: invoice?.category || "other",
      date: invoice?.date ? invoice.date.split('T')[0] : new Date().toISOString().split('T')[0],
      dueDate: invoice?.dueDate ? invoice.dueDate.split('T')[0] : "",
      status: invoice?.status || "pending",
      recurring: invoice?.recurring || false,
      notes: invoice?.notes || "",
      fileUrl: invoice?.fileUrl || "",
    },
  });
  
  const createMutation = useMutation({
    mutationFn: (data: InvoiceFormValues) => {
      return invoicesApi.create(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      toast({
        title: "Succès",
        description: "La facture a été ajoutée avec succès",
        variant: "default",
      });
      navigate('/admin/invoices');
    },
    onError: (error) => {
      console.error("Error creating invoice:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'ajout de la facture",
        variant: "destructive",
      });
    }
  });
  
  const updateMutation = useMutation({
    mutationFn: ({ id, ...data }: { id: string } & Partial<Invoice>) => invoicesApi.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      toast({
        title: "Succès",
        description: "La facture a été mise à jour avec succès",
        variant: "default",
      });
      navigate('/admin/invoices');
    },
    onError: (error) => {
      console.error("Error updating invoice:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la mise à jour de la facture",
        variant: "destructive",
      });
    }
  });
  
  const handleGoBack = () => {
    navigate('/admin/invoices');
  };
  
  const onSubmit = (values: InvoiceFormSchema) => {
    const formData: InvoiceFormValues = {
      number: values.number,
      title: values.title,
      vendor: values.vendor,
      amount: values.amount,
      category: values.category,
      date: values.date,
      dueDate: values.dueDate || undefined,
      status: values.status,
      recurring: values.recurring,
      notes: values.notes || undefined,
      fileUrl: values.fileUrl || undefined,
    };
    
    if (invoice) {
      updateMutation.mutate({
        id: invoice.id,
        ...formData,
      });
    } else {
      createMutation.mutate(formData);
    }
  };
  
  const isSubmitting = createMutation.isPending || updateMutation.isPending;
  
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleGoBack}
            className="flex items-center gap-2"
          >
            <ArrowLeft className="h-4 w-4" />
            Retour
          </Button>
          <h1 className="text-2xl font-bold tracking-tight">
            {invoice ? "Modifier une facture" : "Ajouter une facture"}
          </h1>
        </div>
      </div>
      
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name="number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Numéro de facture</FormLabel>
                  <FormControl>
                    <Input placeholder="FAC-2023-001" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Titre</FormLabel>
                  <FormControl>
                    <Input placeholder="Achat de matériel" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="vendor"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Fournisseur</FormLabel>
                  <FormControl>
                    <Input placeholder="Nom du fournisseur" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Montant (€)</FormLabel>
                  <FormControl>
                    <Input type="number" step="0.01" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Catégorie</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Sélectionner une catégorie" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="equipment">Équipement</SelectItem>
                      <SelectItem value="consumables">Consommables</SelectItem>
                      <SelectItem value="services">Services</SelectItem>
                      <SelectItem value="rent">Loyer</SelectItem>
                      <SelectItem value="other">Autre</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Statut</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Sélectionner un statut" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="paid">Payée</SelectItem>
                      <SelectItem value="pending">En attente</SelectItem>
                      <SelectItem value="overdue">En retard</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date</FormLabel>
                  <FormControl>
                    <Input type="date" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="dueDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date d'échéance (optionnel)</FormLabel>
                  <FormControl>
                    <Input type="date" {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          
          <FormField
            control={form.control}
            name="recurring"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Récurrente</FormLabel>
                  <FormDescription>
                    Cochez cette case si cette facture est récurrente (mensuelle, annuelle, etc.)
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="fileUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>URL du fichier (optionnel)</FormLabel>
                <FormControl>
                  <div className="flex space-x-2">
                    <Input 
                      placeholder="https://exemple.com/facture.pdf" 
                      {...field} 
                      value={field.value || ""}
                    />
                    <div className="flex h-10 w-10 items-center justify-center rounded-md border">
                      <FileText className="h-5 w-5" />
                    </div>
                  </div>
                </FormControl>
                <FormDescription>
                  Lien vers le document de la facture
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Notes (optionnel)</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Informations supplémentaires..."
                    className="min-h-[100px]"
                    {...field}
                    value={field.value || ""}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <div className="flex justify-between">
            <Button 
              type="button" 
              variant="outline" 
              onClick={handleGoBack}
              className="gap-2"
            >
              <ArrowLeft className="h-4 w-4" />
              Annuler
            </Button>
            <Button 
              type="submit" 
              disabled={isSubmitting}
              className="gap-2"
            >
              {isSubmitting && <Loader2 className="h-4 w-4 animate-spin" />}
              {invoice ? "Mettre à jour" : "Ajouter"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default InvoiceForm;
