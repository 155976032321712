
import { CleanupAction } from "@/types/database";
import { format } from "date-fns";
import { ActionFormValues } from "../schemas/actionSchema";

/**
 * Prepares initial values for the action form
 */
export const getInitialFormValues = (action?: CleanupAction): {
  initialValues: Partial<ActionFormValues>;
  initialCoordinates: { latitude: number; longitude: number };
  initialDate: string;
} => {
  // Parse coordinates for the form - ensure they are numbers
  const initialCoordinates = action?.coordinates || { latitude: 43.6, longitude: 3.9 };

  // Format date correctly for the form
  let initialDate = "";
  if (action?.date) {
    try {
      // Convert to ISO string and take just the date part
      const dateObj = new Date(action.date);
      if (!isNaN(dateObj.getTime())) {
        initialDate = format(dateObj, 'yyyy-MM-dd');
      }
    } catch (e) {
      console.error("Date parsing error:", e);
    }
  }

  // Prepare default waste data
  const initialWasteData = action?.wasteData || {
    totalWeight: 0,
    categories: {
      plastic: 0,
      paper: 0,
      metal: 0,
      glass: 0,
      organic: 0,
      cigarettes: 0,
      other: 0
    }
  };

  return {
    initialCoordinates,
    initialDate,
    initialValues: {
      title: action?.title || "",
      description: action?.description || "",
      type: action?.type || "cleanup",
      date: initialDate,
      startTime: action?.startTime || "09:00",
      endTime: action?.endTime || "12:00",
      location: action?.location || "",
      coordinates: initialCoordinates,
      maxParticipants: action?.maxParticipants || 20,
      status: action?.status || "upcoming",
      imageUrl: action?.imageUrl || "",
      // New properties
      distance: action?.distance || "",
      duration: action?.duration || "",
      difficulty: action?.difficulty || "medium",
      elevation: action?.elevation || "",
      // Waste data
      wasteData: initialWasteData
    }
  };
};
