
import React from 'react';

const Testimonial = () => {
  return (
    <section className="py-24 bg-white">
      <div className="container-custom">
        <div className="max-w-5xl mx-auto">
          <div className="bg-white rounded-2xl p-8 md:p-12 shadow-lg relative">
            <svg
              className="absolute top-0 left-10 transform -translate-y-1/2 text-green-700 w-12 h-12"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
            </svg>
            
            <div className="md:text-center">
              <p className="text-xl md:text-2xl text-gray-800 font-['Playfair_Display'] italic mt-6 mb-8">
                "Avec GreenWild, on organise des randonnées pour ramasser les déchets et dépolluer la nature. Ce sont des moments conviviaux où chacun apporte sa petite contribution pour un environnement plus propre. Voir l'impact concret de nos actions, même à petite échelle, est vraiment motivant."
              </p>
              
              <div className="flex flex-col md:flex-row items-center justify-center gap-4">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <img
                    src="\lovable-uploads\maleaume_rouaz.jpg"
                    alt="Maleaume Rouaz"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="text-center md:text-left">
                  <h4 className="font-semibold text-gray-900">Maleaume Rouaz</h4>
                  <p className="text-gray-600">Fondateur de GreenWild</p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Navigation dots */}
          <div className="flex justify-center mt-8 space-x-2">
            <span className="w-3 h-3 rounded-full bg-green-700"></span>
            <span className="w-3 h-3 rounded-full bg-green-200"></span>
            <span className="w-3 h-3 rounded-full bg-green-200"></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
