import React, { useState, useEffect, useRef } from 'react';

const teamMembers = [
  {
    name: "Maleaume",
    role: "Président et Fondateur",
    bio: "Passionné d'aventure et de nature, j'ai créé GreenWild pour rassembler notre communauté autour d'actions concrètes pour l'environnement.",
    image: "/lovable-uploads/90f80df2-43c2-4449-be78-539d62194428.png"
  },
  {
    name: "Fanny",
    role: "Co-Fondatrice et Directrice Artistique",
    bio: "Passionnée de photographie et de nature, je m'occupe de l'identité visuelle et de la direction artistique de l'association.",
    image: "/lovable-uploads/a2a9a9b5-0683-41e8-92fa-4b7724020c5e.png"
  },
  {
    name: "Alexis",
    role: "Développeur",
    bio: "Amoureux de la nature et du code, je développe les outils numériques qui facilitent nos actions et notre organisation.",
    image: "/lovable-uploads/32d7a268-0d30-40c5-8ce0-f8de2c660aeb.png"
  },
  {
    name: "Jérémy",
    role: "Développeur",
    bio: "Développeur passionné par l'écologie, je contribue à la création des outils digitaux de l'association.",
    image: "/lovable-uploads/6cb2a5e9-8788-44b0-a921-35524da263e2.png"
  },
  {
    name: "Phil",
    role: "Trésorier et Responsable Evenementiel",
    bio: "Passionné de randonnée et de protection de l'environnement, je gère la trésorerie et l'organisation des événements.",
    image: "/lovable-uploads/90ecc687-d4ef-496b-8417-fd3b13da6390.png"
  },
  {
    name: "Ugo",
    role: "Co-Responsable Evenementiel",
    bio: "Guide nature, amateur de défis sportifs et passionné d'escalade, je participe à l'organisation des événements et des sorties nature.",
    image: "/lovable-uploads/070de736-e018-4449-8729-3d9849984d64.png"
  },
  {
    name: "Corentin",
    role: "Trésorier",
    bio: "Passionné par la nature et les chiffres, je m'assure de la bonne gestion financière de l'association.",
    image: "/lovable-uploads/coco.jpg"
  },
  {
    name: "Clara",
    role: "Responsable Marketing et Communication",
    bio: "Experte en communication digitale et amoureuse de la nature, je gère la stratégie de communication de l'association.",
    image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d"
  }
];

const Team = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const timers: NodeJS.Timeout[] = [];
          teamMembers.forEach((_, index) => {
            const timer = setTimeout(() => {
              setVisibleItems((prev) => [...prev, index]);
            }, 200 * index);
            timers.push(timer);
          });

          return () => {
            timers.forEach(clearTimeout);
          };
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      id="team"
      ref={sectionRef}
      className="py-24 bg-white"
    >
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-16">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Notre Équipe
          </span>
          <h2 className="section-title mb-4">
            Une équipe passionnée à votre service
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl text-balance">
            Découvrez les membres de GreenWild, une équipe dynamique et engagée qui œuvre au quotidien 
            pour la préservation de notre environnement et l'organisation d'actions concrètes.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`group overflow-hidden rounded-xl bg-white border border-gray-100 shadow-sm transition-all duration-700 transform hover:-translate-y-2 hover:shadow-lg ${
                visibleItems.includes(index)
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="aspect-square overflow-hidden">
                <img 
                  src={member.image} 
                  alt={member.name} 
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 group-hover:text-green-700 transition-colors">
                  {member.name}
                </h3>
                <p className="text-green-700 mb-3 font-medium">{member.role}</p>
                <p className="text-gray-600 text-sm mb-4">{member.bio}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
