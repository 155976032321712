import React from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { actionSchema } from "../schemas/actionSchema";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

type DateLocationSectionProps = {
  form: UseFormReturn<z.infer<typeof actionSchema>>;
  latitude: number;
  longitude: number;
  setLatitude: (value: number) => void;
  setLongitude: (value: number) => void;
  handleCoordinateChange: () => void;
};

const DateLocationSection = ({ 
  form, 
  latitude, 
  longitude, 
  setLatitude, 
  setLongitude, 
  handleCoordinateChange 
}: DateLocationSectionProps) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel>Date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value ? (
                        format(new Date(field.value), "dd/MM/yyyy")
                      ) : (
                        <span className="w-full inline-block truncate">Sélectionner</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50 flex-shrink-0" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value ? new Date(field.value) : undefined}
                    onSelect={(date) => field.onChange(date ? date.toISOString().split('T')[0] : '')}
                    initialFocus
                    className={cn("p-3 pointer-events-auto")}
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="startTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Début</FormLabel>
              <FormControl>
                <Input type="time" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="endTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Fin</FormLabel>
              <FormControl>
                <Input type="time" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={form.control}
        name="location"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Lieu</FormLabel>
            <FormControl>
              <Input placeholder="Lieu de l'action" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="grid grid-cols-2 gap-4">
        <FormItem>
          <FormLabel>Latitude</FormLabel>
          <Input 
            type="number" 
            step="0.000001"
            value={latitude}
            onChange={(e) => {
              setLatitude(parseFloat(e.target.value) || 0);
              handleCoordinateChange();
            }}
          />
        </FormItem>

        <FormItem>
          <FormLabel>Longitude</FormLabel>
          <Input 
            type="number" 
            step="0.000001"
            value={longitude}
            onChange={(e) => {
              setLongitude(parseFloat(e.target.value) || 0);
              handleCoordinateChange();
            }}
          />
        </FormItem>
      </div>

      <FormField
        control={form.control}
        name="maxParticipants"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Nombre de participants maximum</FormLabel>
            <FormControl>
              <Input 
                type="number" 
                min={1}
                {...field}
                onChange={(e) => field.onChange(parseInt(e.target.value) || 20)} 
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="imageUrl"
        render={({ field }) => (
          <FormItem>
            <FormLabel>URL de l'image (optionnel)</FormLabel>
            <FormControl>
              <Input placeholder="https://example.com/image.jpg" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default DateLocationSection;
