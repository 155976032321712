
import { supabase } from '@/integrations/supabase/client';
import { CleanupAction } from '@/types/database';
import { CreateActionData } from './types';
import { transformActionFromDb, transformActionToDb } from './transformers';
import { toast } from 'sonner';

/**
 * Create a new action in the database
 * No authentication required - public access
 */
export const create = async (actionData: CreateActionData): Promise<CleanupAction> => {
  try {
    // Convert the data to database format
    const dbData = transformActionToDb(actionData);
    
    // Make sure required fields are present
    if (!dbData.title || !dbData.description || !dbData.type) {
      throw new Error('Missing required fields for action creation');
    }
    
    console.log('Creating action with data:', JSON.stringify(dbData, null, 2));
    
    // Ensure date is in ISO format for database
    let formattedDate;
    try {
      // Handle different date formats
      if (dbData.date) {
        // Convert to date object first to validate
        const dateObj = new Date(dbData.date);
        if (isNaN(dateObj.getTime())) {
          throw new Error('Invalid date format');
        }
        formattedDate = dateObj.toISOString();
      } else {
        throw new Error('Date is required');
      }
    } catch (error) {
      console.error('Date parsing error:', error);
      throw new Error(`Invalid date format: ${dbData.date}`);
    }
    
    // Ensure coordinates is properly structured as JSONB
    let coordinates = { latitude: 43.6, longitude: 3.9 }; // Default fallback
    try {
      if (dbData.coordinates) {
        coordinates = typeof dbData.coordinates === 'string' 
          ? JSON.parse(dbData.coordinates) 
          : dbData.coordinates;
      }
    } catch (error) {
      console.error('Coordinates parsing error:', error);
    }
    
    // Ensure waste_data is properly structured as JSONB
    let wasteData = {
      totalWeight: 0,
      categories: {
        plastic: 0,
        paper: 0,
        metal: 0,
        glass: 0,
        organic: 0,
        cigarettes: 0,
        other: 0
      }
    }; // Default fallback
    
    try {
      if (dbData.waste_data) {
        wasteData = typeof dbData.waste_data === 'string'
          ? JSON.parse(dbData.waste_data)
          : dbData.waste_data;
      }
    } catch (error) {
      console.error('Waste data parsing error:', error);
    }
    
    console.log('Formatted data for insert:', {
      date: formattedDate,
      coordinates,
      wasteData
    });
    
    // Convert numeric string fields to actual numbers
    const maxParticipants = typeof dbData.max_participants === 'string' 
      ? parseInt(dbData.max_participants, 10) 
      : dbData.max_participants || 20;
    
    const currentParticipants = typeof dbData.current_participants === 'string'
      ? parseInt(dbData.current_participants, 10)
      : dbData.current_participants || 0;
    
    // Modifier la requête pour qu'elle s'exécute avec les droits d'accès publics
    const { data, error } = await supabase
      .from('actions')
      .insert({
        title: dbData.title,
        description: dbData.description,
        type: dbData.type,
        date: formattedDate,
        start_time: dbData.start_time || '09:00',
        end_time: dbData.end_time || '12:00',
        location: dbData.location || 'À définir',
        coordinates: coordinates,
        max_participants: maxParticipants,
        current_participants: currentParticipants,
        status: dbData.status || 'upcoming',
        image_url: dbData.image_url || '',
        distance: dbData.distance || null,
        duration: dbData.duration || null,
        difficulty: dbData.difficulty || 'medium',
        elevation: dbData.elevation || null,
        waste_data: wasteData
      })
      .select('*')
      .maybeSingle();
    
    if (error) {
      console.error('Error creating action:', error);
      toast.error(`Erreur lors de la création: ${error.message}`);
      throw error;
    }
    
    if (!data) {
      toast.error("Aucune donnée retournée après la création");
      throw new Error('No data returned from action creation');
    }
    
    console.log('Action created successfully:', data);
    toast.success("Action créée avec succès");
    return transformActionFromDb(data);
  } catch (error) {
    console.error('Error in create action:', error);
    throw error;
  }
};
