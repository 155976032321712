
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export interface CurrentStockCardProps {
  quantity: number;
  unit: string;
  minQuantity?: number;
}

const CurrentStockCard: React.FC<CurrentStockCardProps> = ({ 
  quantity, 
  unit, 
  minQuantity 
}) => {
  const isLowStock = minQuantity !== undefined && quantity <= minQuantity;
  
  return (
    <Card>
      <CardHeader className="pb-2">
        <CardTitle className="text-lg">Stock actuel</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-2">
          <div className="flex justify-between items-center">
            <span className="text-gray-500">Quantité:</span>
            <span className={`font-semibold text-lg ${isLowStock ? 'text-red-600' : ''}`}>
              {quantity} {unit}
            </span>
          </div>
          
          {minQuantity !== undefined && (
            <div className="flex justify-between items-center">
              <span className="text-gray-500">Stock minimum:</span>
              <span className="font-semibold">
                {minQuantity} {unit}
              </span>
            </div>
          )}
          
          {isLowStock && (
            <div className="mt-2 bg-red-50 text-red-700 px-3 py-2 rounded-md text-sm">
              Stock bas! La quantité actuelle est inférieure ou égale au minimum recommandé.
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CurrentStockCard;
