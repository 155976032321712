
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { SidebarProvider } from "@/components/ui/sidebar";
import { useAuth } from "@/contexts/AuthContext";
import AdminSidebar from "./components/AdminSidebar";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";

interface AdminLayoutProps {
  children: ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();
  const isMobile = useIsMobile();
  
  const navigation = [
    { name: "Tableau de bord", href: "/admin/dashboard" },
    { name: "Actions", href: "/admin/actions" },
    { name: "Galerie", href: "/admin/gallery" },
    { name: "Factures", href: "/admin/invoices" },
    { name: "Inventaire", href: "/admin/inventory" },
    { name: "Utilisateurs", href: "/admin/users" },
  ];

  // Find the current page name for the header
  const currentPage = navigation.find(item => 
    item.href === location.pathname || 
    (item.href !== "/admin/dashboard" && location.pathname.startsWith(item.href))
  )?.name || "Admin";

  // État local pour gérer l'ouverture du menu sur mobile
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);

  return (
    <SidebarProvider defaultOpen={!isMobile}>
      <div className="flex h-screen overflow-hidden bg-gray-50 w-full">
        <AdminSidebar openMobile={openMobileMenu} setOpenMobile={setOpenMobileMenu} />
        
        {/* Main content */}
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center">
                {isMobile && (
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="mr-2"
                    onClick={() => setOpenMobileMenu(true)}
                  >
                    <Menu className="h-5 w-5" />
                  </Button>
                )}
                <h2 className="text-xl font-semibold text-gray-800">
                  {currentPage}
                </h2>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-500">{user?.email || 'admin@greenwild.org'}</span>
                </div>
              </div>
            </div>
          </div>
          
          <main className="flex-1 relative overflow-y-auto focus:outline-none bg-gray-50">
            <div className="py-6 px-4 sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default AdminLayout;
