
import React from "react";
import { Link } from "react-router-dom";
import { Calendar, Clock, MapPin } from "lucide-react";

const FallbackActionCards = () => {
  return (
    <>
      {/* Activity 1 */}
      <div className="group rounded-xl overflow-hidden shadow-md transition-all duration-500 hover:shadow-xl">
        <div className="relative h-64 overflow-hidden">
          <img 
            src="https://images.unsplash.com/photo-1567095761054-7a02e69e5c43" 
            alt="Nettoyage en forêt" 
            className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
          <div className="absolute bottom-4 left-4 right-4">
            <span className="inline-block px-2 py-1 text-xs font-medium bg-green-700 text-white rounded mb-2">
              Randonnée
            </span>
            <h3 className="text-xl font-bold text-white">Pic Saint-Loup</h3>
          </div>
        </div>
        <div className="p-6">
          <div className="space-y-3 mb-4">
            <div className="flex items-center text-sm text-gray-600">
              <MapPin className="w-4 h-4 mr-2 text-green-700" />
              <span>Saint-Mathieu-de-Tréviers</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Calendar className="w-4 h-4 mr-2 text-green-700" />
              <span>15 juillet 2024</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Clock className="w-4 h-4 mr-2 text-green-700" />
              <span>3h30 • 8km • Moyen • ↑ 450m</span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm text-green-700 font-medium">Places limitées</span>
            <Link to="/#contact" className="px-3 py-1 border border-green-700 text-green-700 rounded hover:bg-green-700 hover:text-white transition-colors">
              S'inscrire
            </Link>
          </div>
        </div>
      </div>
      
      {/* Activity 2 */}
      <div className="group rounded-xl overflow-hidden shadow-md transition-all duration-500 hover:shadow-xl">
        <div className="relative h-64 overflow-hidden">
          <img 
            src="https://images.unsplash.com/photo-1621451537084-482c73073a0f" 
            alt="Nettoyage de plage" 
            className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
          <div className="absolute bottom-4 left-4 right-4">
            <span className="inline-block px-2 py-1 text-xs font-medium bg-green-700 text-white rounded mb-2">
              Littoral
            </span>
            <h3 className="text-xl font-bold text-white">Plage de l'Espiguette</h3>
          </div>
        </div>
        <div className="p-6">
          <div className="space-y-3 mb-4">
            <div className="flex items-center text-sm text-gray-600">
              <MapPin className="w-4 h-4 mr-2 text-green-700" />
              <span>Le Grau-du-Roi</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Calendar className="w-4 h-4 mr-2 text-green-700" />
              <span>24 juillet 2024</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Clock className="w-4 h-4 mr-2 text-green-700" />
              <span>2h30 • 5km • Facile • ↑ 10m</span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm text-green-700 font-medium">Places limitées</span>
            <Link to="/#contact" className="px-3 py-1 border border-green-700 text-green-700 rounded hover:bg-green-700 hover:text-white transition-colors">
              S'inscrire
            </Link>
          </div>
        </div>
      </div>
      
      {/* Activity 3 */}
      <div className="group rounded-xl overflow-hidden shadow-md transition-all duration-500 hover:shadow-xl">
        <div className="relative h-64 overflow-hidden">
          <img 
            src="https://images.unsplash.com/photo-1617358805546-00e4fd5686e0" 
            alt="Atelier sensibilisation" 
            className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
          <div className="absolute bottom-4 left-4 right-4">
            <span className="inline-block px-2 py-1 text-xs font-medium bg-green-700 text-white rounded mb-2">
              Atelier
            </span>
            <h3 className="text-xl font-bold text-white">Sensibilisation écologique</h3>
          </div>
        </div>
        <div className="p-6">
          <div className="space-y-3 mb-4">
            <div className="flex items-center text-sm text-gray-600">
              <MapPin className="w-4 h-4 mr-2 text-green-700" />
              <span>Montpellier</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Calendar className="w-4 h-4 mr-2 text-green-700" />
              <span>10 août 2024</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Clock className="w-4 h-4 mr-2 text-green-700" />
              <span>1h30 • Tout public • Accessible PMR</span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm text-green-700 font-medium">Places limitées</span>
            <Link to="/#contact" className="px-3 py-1 border border-green-700 text-green-700 rounded hover:bg-green-700 hover:text-white transition-colors">
              S'inscrire
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FallbackActionCards;
