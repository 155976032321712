
import React from "react";
import { Search, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

interface ActionFiltersProps {
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
  typeFilter?: string;
  setTypeFilter?: (type: string) => void;
  dateFilter?: string;
  setDateFilter?: (date: string) => void;
  selectedTypes?: string[];
  setSelectedTypes?: (types: string[]) => void;
  selectedStatus?: string[];
  setSelectedStatus?: (status: string[]) => void;
  onResetFilters?: () => void;
}

const ActionFilters = ({
  searchQuery = "",
  setSearchQuery,
  typeFilter = "",
  setTypeFilter,
  dateFilter = "all",
  setDateFilter,
  selectedTypes = [],
  setSelectedTypes,
  selectedStatus = [],
  setSelectedStatus,
  onResetFilters
}: ActionFiltersProps) => {
  const hasActiveFilters = searchQuery || typeFilter || dateFilter !== "all" || 
                          (selectedTypes && selectedTypes.length > 0) || 
                          (selectedStatus && selectedStatus.length > 0);
  
  return (
    <Card className="mb-6">
      <div className="p-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <Input 
              placeholder="Rechercher par titre, lieu..." 
              className="pl-10"
              value={searchQuery}
              onChange={(e) => setSearchQuery && setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex gap-4">
            {setTypeFilter && (
              <select 
                className="px-3 py-2 border rounded-md text-sm w-full md:w-40"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
              >
                <option value="">Tous les types</option>
                <option value="cleanup">Nettoyage</option>
                <option value="workshop">Atelier</option>
                <option value="awareness">Sensibilisation</option>
              </select>
            )}
            {setDateFilter && (
              <select 
                className="px-3 py-2 border rounded-md text-sm w-full md:w-40"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <option value="all">Toutes les dates</option>
                <option value="upcoming">À venir</option>
                <option value="past">Passées</option>
                <option value="thisMonth">Ce mois</option>
                <option value="nextMonth">Mois prochain</option>
              </select>
            )}
          </div>
        </div>
        
        <div className="flex flex-wrap justify-between items-center mt-4">
          <div className="flex flex-wrap gap-2">
            {selectedTypes && setSelectedTypes && selectedTypes.map(type => (
              <div key={type} className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm flex items-center">
                <span>{type === 'cleanup' ? 'Nettoyage' : 
                      type === 'workshop' ? 'Atelier' : 
                      type === 'awareness' ? 'Sensibilisation' : type}</span>
                <button 
                  className="ml-1 text-green-600 hover:text-green-800"
                  onClick={() => setSelectedTypes(selectedTypes.filter(t => t !== type))}
                >
                  ×
                </button>
              </div>
            ))}
            
            {selectedStatus && setSelectedStatus && selectedStatus.map(status => (
              <div key={status} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
                <span>{status === 'upcoming' ? 'À venir' : 
                      status === 'completed' ? 'Terminé' : 
                      status === 'cancelled' ? 'Annulé' : status}</span>
                <button 
                  className="ml-1 text-blue-600 hover:text-blue-800"
                  onClick={() => setSelectedStatus(selectedStatus.filter(s => s !== status))}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          
          {hasActiveFilters && onResetFilters && (
            <Button 
              variant="outline" 
              size="sm" 
              className="text-xs mt-2 md:mt-0"
              onClick={onResetFilters}
            >
              <X className="h-3 w-3 mr-1" />
              Réinitialiser les filtres
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ActionFilters;
