
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { useAuth } from '@/contexts/AuthContext';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    navigate("/");
  };
  
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white/80 backdrop-blur-lg shadow-sm py-3" : "bg-transparent py-5"
      }`}
    >
      <div className="container-custom">
        <nav className="flex items-center justify-between">
          {/* Logo */}
          <div 
            onClick={scrollToTop} 
            className="flex items-center text-2xl font-bold text-green-800 font-['Playfair_Display'] tracking-tight cursor-pointer"
          >
            <img 
              src="/lovable-uploads/f9ed85a4-8776-4a28-89f2-ab48c5139e9b.png" 
              alt="GreenWild Logo" 
              className="h-12 mr-2"
            />
            <span className="transition-all duration-500 hover:text-green-600">GreenWild</span>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-1">
            <NavLink href="/#about">Qui sommes-nous</NavLink>
            <NavLink href="/#activities">Nos actions</NavLink>
            <NavLink href="/team">Notre équipe</NavLink>
            <NavLink href="/calendar">Calendrier</NavLink>
            <NavLink href="/#donation">Faire un don</NavLink>
            <NavLink href="/#contact">Contact</NavLink>
            <a 
              href="https://greenwild.assoconnect.com/collect/description/451088-n-adhesions-annuelles"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-4 px-4 py-2 rounded-md bg-green-700 text-white hover:bg-green-600 transition-all duration-300 transform hover:scale-105"
            >
              Nous rejoindre
            </a>
            
            {/* Lien Admin - visible seulement si utilisateur connecté */}
            {user && <NavLink href="/admin">Admin</NavLink>}
          </div>

          {/* Mobile menu button */}
          <button
            onClick={toggleMobileMenu}
            className="md:hidden text-green-800 focus:outline-none z-50"
            aria-label="Menu"
          >
            {isMobileMenuOpen ? (
              <X size={24} className="animate-fade-in" />
            ) : (
              <Menu size={24} className="animate-fade-in" />
            )}
          </button>
        </nav>

        {/* Mobile Navigation - Fixed positioning and solid background */}
        <div
          className={`md:hidden fixed inset-0 z-40 bg-white pt-20 px-6 transition-all duration-300 ease-in-out ${
            isMobileMenuOpen
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full pointer-events-none"
          }`}
        >
          <div className="flex flex-col space-y-6 text-center">
            <MobileNavLink href="/#about" onClick={closeMobileMenu}>
              Qui sommes-nous
            </MobileNavLink>
            <MobileNavLink href="/#activities" onClick={closeMobileMenu}>
              Nos actions
            </MobileNavLink>
            <MobileNavLink href="/team" onClick={closeMobileMenu}>
              Notre équipe
            </MobileNavLink>
            <MobileNavLink href="/calendar" onClick={closeMobileMenu}>
              Calendrier
            </MobileNavLink>
            <MobileNavLink href="/#donation" onClick={closeMobileMenu}>
              Faire un don
            </MobileNavLink>
            <MobileNavLink href="/#contact" onClick={closeMobileMenu}>
              Contact
            </MobileNavLink>
            <a 
              href="https://greenwild.assoconnect.com/collect/description/451088-n-adhesions-annuelles"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 w-full px-4 py-3 rounded-md bg-green-700 text-white hover:bg-green-600 transition-all duration-300"
              onClick={closeMobileMenu}
            >
              Nous rejoindre
            </a>
            
            {/* Lien Admin pour mobile - visible seulement si utilisateur connecté */}
            {user && (
              <MobileNavLink href="/admin" onClick={closeMobileMenu}>
                Admin
              </MobileNavLink>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

const NavLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  return (
    <a
      href={href}
      className="px-3 py-2 text-green-800 hover:text-green-600 font-medium relative group"
    >
      {children}
      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-green-600 transition-all duration-300 group-hover:w-full"></span>
    </a>
  );
};

const MobileNavLink = ({ 
  href, 
  onClick, 
  children 
}: { 
  href: string; 
  onClick: () => void;
  children: React.ReactNode 
}) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className="text-xl text-green-800 hover:text-green-600 py-2 block font-medium transition-colors duration-300"
    >
      {children}
    </a>
  );
};

export default Navbar;
