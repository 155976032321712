
import React, { useEffect, useState } from 'react';

interface AnimatedTextProps {
  text: string;
  once?: boolean;
  className?: string;
}

const AnimatedText = ({ text, once = true, className = '' }: AnimatedTextProps) => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <span className={className}>
      {text.split(' ').map((word, wordIndex) => (
        <span 
          key={wordIndex} 
          className="inline-block overflow-hidden mr-[0.25em] relative"
        >
          <span 
            className={`inline-block transition-transform duration-[800ms] ease-out ${
              isVisible ? 'translate-y-0' : 'translate-y-full'
            }`}
            style={{ 
              transitionDelay: `${wordIndex * 60 + 300}ms` 
            }}
          >
            {word}
          </span>
        </span>
      ))}
    </span>
  );
};

export default AnimatedText;
