
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface SummaryCardsProps {
  totalRevenue: number;
  totalExpenses: number;
  formatCurrency: (amount: number) => string;
}

const SummaryCards = ({ totalRevenue, totalExpenses, formatCurrency }: SummaryCardsProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-xl">Total des recettes</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-3xl font-bold text-green-600">{formatCurrency(totalRevenue)}</p>
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-xl">Total des dépenses</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-3xl font-bold text-red-600">{formatCurrency(totalExpenses)}</p>
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-xl">Bilan</CardTitle>
        </CardHeader>
        <CardContent>
          <p className={`text-3xl font-bold ${(totalRevenue - totalExpenses) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {formatCurrency(totalRevenue - totalExpenses)}
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default SummaryCards;
