
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CalendarHeader from "../components/calendar/CalendarHeader";
import ActionsFilter from "../components/calendar/ActionsFilter";
import ActionsStats from "../components/calendar/ActionsStats";
import ActionsList from "../components/calendar/ActionsList";
import { useCalendarActions } from "@/hooks/useCalendarActions";

const CalendarPage = () => {
  const {
    activeTab,
    setActiveTab,
    filterOpen,
    setFilterOpen,
    filters,
    setFilters,
    filteredActions,
    pastActions,
    isLoading,
    resetFilters
  } = useCalendarActions();

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      <main className="pt-32 pb-24">
        <div className="container-custom">
          <CalendarHeader 
            activeTab={activeTab} 
            setActiveTab={setActiveTab} 
          />
          
          <ActionsFilter 
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            filters={filters}
            setFilters={setFilters}
          />
          
          {activeTab === 'past' && (
            <ActionsStats pastActions={pastActions} />
          )}
          
          <ActionsList 
            actions={filteredActions}
            isLoading={isLoading}
            onResetFilters={resetFilters}
          />
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default CalendarPage;
