
/**
 * Initializes masonry layout by adjusting grid-row-end based on image heights
 */
export function initMasonry() {
  console.log('Initializing masonry layout...');
  // Wait for DOM to be fully loaded
  setTimeout(() => {
    const masonryItems = document.querySelectorAll('.masonry-item');
    const rowHeight = 10; // This matches the grid-auto-rows value in CSS
    
    masonryItems.forEach((item) => {
      const itemElement = item as HTMLElement;
      const imgElement = itemElement.querySelector('img');
      
      if (imgElement) {
        // Once image is loaded, calculate and set the appropriate height
        if (imgElement.complete) {
          resizeGridItem(itemElement, imgElement, rowHeight);
        } else {
          imgElement.addEventListener('load', () => {
            resizeGridItem(itemElement, imgElement, rowHeight);
          });
        }
      }
    });
  }, 100);
}

/**
 * Resize a grid item based on its content height
 */
function resizeGridItem(item: HTMLElement, img: HTMLElement, rowHeight: number) {
  // Get the actual height of the content
  const contentHeight = img.offsetHeight;
  
  // Calculate how many rows this item should span and add a small buffer to avoid gaps
  const rowSpan = Math.ceil((contentHeight + 2) / rowHeight); // Add 2px buffer
  
  // Set the grid-row-end property to make this item span the appropriate number of rows
  item.style.gridRowEnd = `span ${rowSpan}`;
}

/**
 * Adds event listener to resize masonry items on window resize
 */
export function addMasonryResizeListener() {
  let resizeTimer: number;
  
  window.addEventListener('resize', () => {
    // Debounce the resize event
    clearTimeout(resizeTimer);
    resizeTimer = window.setTimeout(() => {
      // Reinitialize masonry on resize to fix layout
      initMasonry();
    }, 200);
  });
}
