
import React from 'react';
import { Route } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import ActionsListPage from '@/pages/admin/actions/ActionsListPage';
import NewActionPage from '@/pages/admin/actions/NewActionPage';
import EditActionPage from '@/pages/admin/actions/EditActionPage';

export const actionRoutes = [
  <Route 
    key="admin-actions"
    path="/admin/actions"
    element={
      <PermissionGuard permission="manage_actions">
        <ActionsListPage />
      </PermissionGuard>
    }
  />,
  <Route 
    key="admin-actions-new"
    path="/admin/actions/new"
    element={
      <PermissionGuard permission="manage_actions">
        <NewActionPage />
      </PermissionGuard>
    }
  />,
  <Route 
    key="admin-actions-edit"
    path="/admin/actions/edit/:id"
    element={
      <PermissionGuard permission="manage_actions">
        <EditActionPage />
      </PermissionGuard>
    }
  />,
];
