
import React from "react";
import { CleanupAction } from "@/types/database";

interface ActionsStatsProps {
  pastActions: CleanupAction[];
}

const ActionsStats = ({ pastActions }: ActionsStatsProps) => {
  const totalStats = pastActions.reduce((acc, curr) => {
    const wasteWeight = curr.wasteData?.totalWeight || 0;
    return {
      participants: acc.participants + curr.currentParticipants,
      collected: acc.collected + wasteWeight
    };
  }, { participants: 0, collected: 0 });

  return (
    <div className="mb-12 p-6 bg-green-50 rounded-xl">
      <h2 className="text-xl font-semibold text-green-800 mb-4 text-center">Statistiques des actions passées</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-sm text-center">
          <p className="text-gray-600 mb-1">Déchets collectés</p>
          <p className="text-3xl font-bold text-green-700">{totalStats.collected} kg</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm text-center">
          <p className="text-gray-600 mb-1">Participants</p>
          <p className="text-3xl font-bold text-green-700">{totalStats.participants}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionsStats;
