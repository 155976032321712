
import React from 'react';
import Map from './Map';

const MapSection = () => {
  return (
    <section id="map" className="py-24 bg-green-50">
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-16">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Nos lieux d'intervention
          </span>
          <h2 className="section-title mb-4">
            Carte de nos actions de nettoyage
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl text-balance">
            Découvrez les différents sites où GreenWild intervient régulièrement. Rejoignez-nous lors 
            de notre prochaine action près de chez vous !
          </p>
        </div>
        
        <Map />
      </div>
    </section>
  );
};

export default MapSection;
