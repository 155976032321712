
import React from 'react';
import { Route } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import GalleryListPage from '@/pages/admin/gallery/GalleryListPage';
import NewGalleryItemPage from '@/pages/admin/gallery/NewGalleryItemPage';
import EditGalleryItemPage from '@/pages/admin/gallery/EditGalleryItemPage';

export const galleryRoutes = [
  // Gallery list page
  <Route
    key="gallery-list"
    path="/admin/gallery"
    element={
      <PermissionGuard permission="manage_gallery">
        <GalleryListPage />
      </PermissionGuard>
    }
  />,
  
  // New gallery item page
  <Route
    key="gallery-new"
    path="/admin/gallery/new"
    element={
      <PermissionGuard permission="manage_gallery">
        <NewGalleryItemPage />
      </PermissionGuard>
    }
  />,
  
  // Edit gallery item page
  <Route
    key="gallery-edit"
    path="/admin/gallery/edit/:id"
    element={
      <PermissionGuard permission="manage_gallery">
        <EditGalleryItemPage />
      </PermissionGuard>
    }
  />
];
