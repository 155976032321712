
import { supabase } from '@/integrations/supabase/client';
import { CleanupAction } from '@/types/database';
import { transformActionFromDb } from './transformers';

/**
 * Fetch all actions from the database
 * No authentication required - public access
 */
export const getAll = async (): Promise<CleanupAction[]> => {
  try {
    console.log('Fetching all actions from database...');
    
    const { data, error } = await supabase
      .from('actions')
      .select('*')
      .order('date', { ascending: true });
    
    if (error) {
      console.error('Error fetching actions:', error);
      throw error;
    }
    
    console.log(`Successfully fetched ${data.length} actions`);
    if (data.length > 0) {
      console.log('Sample action:', data[0]);
    }
    
    const transformedActions = data.map(action => transformActionFromDb(action));
    return transformedActions;
  } catch (error) {
    console.error('Error in getAll actions:', error);
    throw error;
  }
};

/**
 * Fetch a specific action by ID
 * No authentication required - public access
 */
export const getById = async (id: string): Promise<CleanupAction | undefined> => {
  try {
    console.log(`Fetching action with ID: ${id}`);
    
    const { data, error } = await supabase
      .from('actions')
      .select('*')
      .eq('id', id)
      .maybeSingle();
    
    if (error) {
      console.error('Error fetching action by ID:', error);
      throw error;
    }
    
    if (!data) {
      console.log(`No action found with ID: ${id}`);
      return undefined;
    }
    
    return transformActionFromDb(data);
  } catch (error) {
    console.error(`Error in getById action (ID: ${id}):`, error);
    throw error;
  }
};
