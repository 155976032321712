
import React from "react";
import { Control } from "react-hook-form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface QuantitySectionProps {
  control: Control<any>;
}

const QuantitySection: React.FC<QuantitySectionProps> = ({ control }) => {
  return (
    <div className="space-y-6">
      {/* Quantity and Unit Fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormField
          control={control}
          name="quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Quantité</FormLabel>
              <FormControl>
                <Input 
                  type="number" 
                  min="0"
                  {...field}
                  onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <FormField
          control={control}
          name="unit"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Unité</FormLabel>
              <FormControl>
                <Input placeholder="pièce(s), kg, litre(s)" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      
      {/* Min Quantity Field */}
      <FormField
        control={control}
        name="minQuantity"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Quantité minimale (alerte stock bas)</FormLabel>
            <FormControl>
              <Input 
                type="number" 
                min="0"
                {...field}
                onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                value={field.value === undefined ? 0 : field.value}
              />
            </FormControl>
            <FormDescription>
              Une alerte sera affichée lorsque le stock sera inférieur ou égal à cette valeur
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      
      {/* Image URL Field */}
      <FormField
        control={control}
        name="imageUrl"
        render={({ field }) => (
          <FormItem>
            <FormLabel>URL de l'image (optionnel)</FormLabel>
            <FormControl>
              <Input placeholder="https://exemple.com/image.jpg" {...field} value={field.value || ""} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default QuantitySection;
