
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import { dashboardRoutes } from './admin/dashboardRoutes';
import { actionRoutes } from './admin/actionRoutes';
import { inventoryRoutes } from './admin/inventoryRoutes';
import { invoiceRoutes } from './admin/invoiceRoutes';
import { galleryRoutes } from './admin/galleryRoutes';

/**
 * Admin dashboard routes with permission guards
 */
export const adminRoutes = [
  // Redirect /admin to /admin/dashboard
  <Route
    key="admin-redirect"
    path="/admin"
    element={<Navigate to="/admin/dashboard" replace />}
  />,
  
  // Wrap each route group with PermissionGuard
  ...dashboardRoutes,
  ...actionRoutes,
  ...inventoryRoutes,
  ...invoiceRoutes,
  ...galleryRoutes,
  // Users routes will be added here later
  // For now, we're just adding the menu items in the AdminLayout
];
