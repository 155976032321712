
import React from "react";
import { Button } from "@/components/ui/button";
import { Plus, BarChart } from "lucide-react";

interface PageActionsProps {
  onAddNew: () => void;
  onViewStats: () => void;
}

const PageActions = ({ onAddNew, onViewStats }: PageActionsProps) => {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold">Gestion des factures</h1>
      <div className="flex space-x-2">
        <Button variant="outline" onClick={onViewStats}>
          <BarChart className="mr-2 h-4 w-4" />
          Statistiques
        </Button>
        <Button onClick={onAddNew}>
          <Plus className="mr-2 h-4 w-4" />
          Ajouter une facture
        </Button>
      </div>
    </div>
  );
};

export default PageActions;
