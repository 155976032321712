
import React from "react";
import { Loader2 } from "lucide-react";

const LoadingState = () => {
  return (
    <div className="flex flex-col justify-center items-center p-12">
      <Loader2 className="h-8 w-8 animate-spin text-green-600 mb-3" />
      <p className="text-gray-500 text-sm">Chargement des actions...</p>
    </div>
  );
};

export default LoadingState;
