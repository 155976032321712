
import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { InventoryItem } from "@/types/database";

interface StockInfoCardProps {
  item: InventoryItem;
}

const StockInfoCard: React.FC<StockInfoCardProps> = ({ item }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Informations</CardTitle>
        <CardDescription>Détails de l'article</CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <div>
          <span className="font-semibold">Nom:</span> {item.name}
        </div>
        <div>
          <span className="font-semibold">Catégorie:</span> {item.category}
        </div>
        <div>
          <span className="font-semibold">Unité:</span> {item.unit}
        </div>
        {item.description && (
          <div>
            <span className="font-semibold">Description:</span>
            <p className="mt-1 text-sm text-muted-foreground">{item.description}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default StockInfoCard;
