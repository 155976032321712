
import { initializeAdmin } from './initAdmin';
import { supabase } from '@/integrations/supabase/client';

/**
 * Initialise l'application au démarrage
 * Cette fonction est appelée une fois dans App.tsx
 */
export const initializeApp = async () => {
  console.log("Application initialization started");
  
  try {
    // Vérifier que la connexion Supabase fonctionne
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      console.error("Supabase connection error:", error);
    } else {
      console.log("Supabase connection established", data?.session ? "with session" : "without session");
    }
    
    // Initialiser l'utilisateur admin si nécessaire
    await initializeAdmin();
    
    // Configuration de callback d'authentification pour déboguer
    supabase.auth.onAuthStateChange((event, session) => {
      console.log(`Auth state changed: ${event}`, session ? "User authenticated" : "No session");
    });
    
    console.log("Application initialization completed");
  } catch (error) {
    console.error("Error during app initialization:", error);
  }
};
