
import React from "react";
import InvoiceForm from "./InvoiceForm";
import AdminLayout from "../AdminLayout";

const NewInvoicePage = () => {
  return (
    <AdminLayout>
      <div className="container py-6">
        <InvoiceForm />
      </div>
    </AdminLayout>
  );
};

export default NewInvoicePage;
