
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import AppRouter from '@/components/AppRouter';
import { AuthProvider } from '@/contexts/AuthContext';
import { initializeApp } from '@/utils/initApp';
import { Toaster } from 'sonner';
import './index.css';

// Configure the React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

function App() {
  // Initialize app on mount
  useEffect(() => {
    initializeApp().catch(console.error);
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppRouter />
          <Toaster position="top-right" richColors />
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
