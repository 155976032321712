
import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { actionsApi } from "@/services/actions";
import { toast } from "sonner";
import AdminLayout from "../AdminLayout";

// Components
import ActionFilters from "./components/ActionFilters";
import ActionsList from "./components/ActionsList";

const ActionsListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  // Filter states - explicitly initialize all to empty/default values
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  
  // Fetch actions with consistent settings
  const { data: actions, isLoading, error, refetch } = useQuery({
    queryKey: ['actions'],
    queryFn: actionsApi.getAll,
    staleTime: 1000, // Very short stale time
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    retry: 3
  });
  
  // Force a refetch on mount
  useEffect(() => {
    refetch();
    console.log("ActionsListPage mounted - forcing refetch");
  }, [refetch]);
  
  useEffect(() => {
    // Log actions to debug
    if (actions) {
      console.log(`ActionsListPage - Total actions received: ${actions.length}`);
      if (actions.length > 0) {
        console.log("First few actions:", actions.slice(0, 3).map(a => ({
          id: a.id,
          title: a.title,
          type: a.type
        })));
      } else {
        console.log("No actions received");
      }
    } else {
      console.log("Actions data is undefined");
    }
  }, [actions]);
  
  // Delete action mutation
  const deleteMutation = useMutation({
    mutationFn: (id: string) => actionsApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['actions'] });
      toast.success("Action supprimée avec succès");
    }
  });
  
  // Navigation handlers
  const handleAddAction = () => navigate("/admin/actions/new");
  const handleEditAction = (id: string) => navigate(`/admin/actions/edit/${id}`);
  const handleViewAction = (id: string) => navigate(`/admin/actions/edit/${id}`);
  const handleDeleteAction = async (id: string) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette action ?")) {
      deleteMutation.mutate(id);
    }
  };
  
  // Reset all filters
  const handleResetFilters = () => {
    setSelectedTypes([]);
    setSelectedStatus([]);
    setDateFilter("all");
    setSearchQuery("");
    console.log("Filters reset to default values");
  };
  
  // Derive type filter from selected types - only use first selected type
  const typeFilter = selectedTypes.length > 0 ? selectedTypes[0] : "";
  
  return (
    <AdminLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Gestion des actions</h1>
          <button
            onClick={handleAddAction}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center space-x-2"
          >
            <span>Nouvelle action</span>
          </button>
        </div>
        
        <ActionFilters
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onResetFilters={handleResetFilters}
        />
        
        <ActionsList
          actions={actions || []}
          isLoading={isLoading}
          error={error}
          onDeleteAction={handleDeleteAction}
          searchQuery={searchQuery}
          typeFilter={typeFilter}
          dateFilter={dateFilter}
          onAddAction={handleAddAction}
        />
      </div>
    </AdminLayout>
  );
};

export default ActionsListPage;
