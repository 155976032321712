import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ArrowLeft, Image as ImageIcon, Plus, X, Upload } from 'lucide-react';
import { galleryApi } from '@/services/api';
import { GalleryItem } from '@/types/database';
import { supabase } from '@/integrations/supabase/client';

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const galleryFormSchema = z.object({
  title: z.string().min(1, 'Le titre est requis'),
  description: z.string().optional(),
  imageUrl: z.string().min(1, 'Une image est requise'),
  date: z.string().min(1, 'La date est requise'),
  tags: z.array(z.string()).min(1, 'Au moins un tag est requis'),
});

type GalleryFormValues = z.infer<typeof galleryFormSchema>;

interface GalleryFormProps {
  galleryItem?: GalleryItem;
}

const GalleryForm: React.FC<GalleryFormProps> = ({ galleryItem }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [newTag, setNewTag] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadTab, setUploadTab] = useState<'url' | 'file'>('url');
  
  const isEditMode = !!galleryItem;
  
  const formatDateForInput = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  
  const form = useForm<GalleryFormValues>({
    resolver: zodResolver(galleryFormSchema),
    defaultValues: {
      title: galleryItem?.title || '',
      description: galleryItem?.description || '',
      imageUrl: galleryItem?.imageUrl || '',
      date: galleryItem?.date ? formatDateForInput(galleryItem.date) : new Date().toISOString().split('T')[0],
      tags: galleryItem?.tags || [],
    },
  });
  
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setUploadProgress(10);

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${fileExt}`;
      const filePath = `gallery/${fileName}`;

      setUploadProgress(30);
      
      const { data, error } = await supabase.storage
        .from('images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false,
        });

      if (error) {
        throw error;
      }

      setUploadProgress(90);

      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      setUploadProgress(100);

      form.setValue('imageUrl', publicUrl);
      
      toast.success('Image téléchargée avec succès');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Erreur lors du téléchargement de l\'image');
    } finally {
      setIsUploading(false);
    }
  };
  
  const createItemMutation = useMutation({
    mutationFn: (data: GalleryFormValues) => {
      const newItem: Omit<GalleryItem, 'id' | 'createdAt' | 'updatedAt'> = {
        title: data.title,
        description: data.description,
        imageUrl: data.imageUrl,
        date: new Date(data.date).toISOString(),
        tags: data.tags,
      };
      return galleryApi.create(newItem);
    },
    onSuccess: () => {
      toast.success('Image ajoutée avec succès');
      queryClient.invalidateQueries({ queryKey: ['galleryItems'] });
      navigate('/admin/gallery');
    },
    onError: (error) => {
      console.error('Error creating gallery item:', error);
      toast.error('Erreur lors de l\'ajout de l\'image');
    },
  });
  
  const updateItemMutation = useMutation({
    mutationFn: (data: GalleryFormValues) => {
      if (!galleryItem) throw new Error('Missing gallery item');
      
      return galleryApi.update(galleryItem.id, {
        ...data,
        date: new Date(data.date).toISOString(),
      });
    },
    onSuccess: () => {
      toast.success('Image mise à jour avec succès');
      queryClient.invalidateQueries({ queryKey: ['galleryItems'] });
      navigate('/admin/gallery');
    },
    onError: (error) => {
      console.error('Error updating gallery item:', error);
      toast.error('Erreur lors de la mise à jour de l\'image');
    },
  });
  
  const onSubmit = (data: GalleryFormValues) => {
    if (isEditMode) {
      updateItemMutation.mutate(data);
    } else {
      createItemMutation.mutate(data);
    }
  };
  
  const handleAddTag = () => {
    const trimmedTag = newTag.trim();
    if (!trimmedTag) return;
    
    const currentTags = form.getValues().tags || [];
    
    if (currentTags.includes(trimmedTag)) {
      toast.error('Ce tag existe déjà');
      return;
    }
    
    form.setValue('tags', [...currentTags, trimmedTag]);
    setNewTag('');
  };
  
  const handleRemoveTag = (tagToRemove: string) => {
    const currentTags = form.getValues().tags;
    form.setValue(
      'tags', 
      currentTags.filter(tag => tag !== tagToRemove)
    );
  };
  
  const handleTagKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      handleAddTag();
    }
  };
  
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Informations générales</h2>
              
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Titre</FormLabel>
                    <FormControl>
                      <Input placeholder="Titre de l'image" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea 
                        placeholder="Description de l'image" 
                        className="resize-none" 
                        {...field} 
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="imageUrl"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Image</FormLabel>
                    <Tabs defaultValue={uploadTab} onValueChange={(value) => setUploadTab(value as 'url' | 'file')}>
                      <TabsList className="mb-2">
                        <TabsTrigger value="url">URL</TabsTrigger>
                        <TabsTrigger value="file">Fichier</TabsTrigger>
                      </TabsList>
                      
                      <TabsContent value="url" className="space-y-2">
                        <FormControl>
                          <Input placeholder="https://example.com/image.jpg" {...field} />
                        </FormControl>
                        <FormDescription>
                          Entrez l'URL complète de l'image
                        </FormDescription>
                      </TabsContent>
                      
                      <TabsContent value="file" className="space-y-2">
                        <div className="border border-dashed border-gray-300 rounded-md p-6 text-center">
                          <Input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            onChange={handleFileUpload}
                            className="hidden"
                            disabled={isUploading}
                          />
                          <label 
                            htmlFor="image-upload" 
                            className="flex flex-col items-center justify-center cursor-pointer"
                          >
                            <Upload className="h-10 w-10 text-gray-400 mb-2" />
                            {isUploading ? (
                              <div className="space-y-2 w-full">
                                <p className="text-sm text-gray-500">Téléchargement en cours... {uploadProgress}%</p>
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                  <div 
                                    className="bg-green-600 h-2 rounded-full" 
                                    style={{ width: `${uploadProgress}%` }}
                                  ></div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <p className="font-medium">Cliquez pour télécharger une image</p>
                                <p className="text-sm text-gray-500 mt-1">PNG, JPG, GIF jusqu'à 10MB</p>
                              </>
                            )}
                          </label>
                        </div>
                        {field.value && (
                          <div className="text-sm text-gray-500 mt-2 flex items-center">
                            <span className="mr-2">Image actuelle:</span>
                            <span className="text-xs bg-gray-100 p-1 rounded truncate max-w-xs">
                              {field.value}
                            </span>
                          </div>
                        )}
                      </TabsContent>
                    </Tabs>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Tags</h2>
              <FormField
                control={form.control}
                name="tags"
                render={() => (
                  <FormItem>
                    <FormLabel>Tags</FormLabel>
                    <div className="flex gap-2">
                      <Input
                        placeholder="Ajouter un tag"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyDown={handleTagKeyPress}
                        className="flex-1"
                      />
                      <Button 
                        type="button" 
                        onClick={handleAddTag}
                        variant="outline"
                      >
                        <Plus className="h-4 w-4 mr-2" />
                        Ajouter
                      </Button>
                    </div>
                    
                    <div className="flex flex-wrap gap-2 mt-2">
                      {form.watch('tags')?.map((tag) => (
                        <div 
                          key={tag} 
                          className="flex items-center gap-1 px-3 py-1 rounded-full bg-green-100 text-green-800"
                        >
                          <span>{tag}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveTag(tag)}
                            className="text-green-800 hover:text-red-500"
                          >
                            <X className="h-3 w-3" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            
            <div className="flex justify-end gap-2">
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => navigate('/admin/gallery')}
              >
                Annuler
              </Button>
              <Button 
                type="submit" 
                disabled={createItemMutation.isPending || updateItemMutation.isPending || isUploading}
              >
                {(createItemMutation.isPending || updateItemMutation.isPending) 
                  ? 'Enregistrement...' 
                  : isEditMode ? 'Mettre à jour' : 'Ajouter'}
              </Button>
            </div>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold mb-4">Aperçu</h2>
            <Card>
              <CardContent className="p-0 overflow-hidden">
                {form.watch('imageUrl') ? (
                  <div className="relative">
                    <img 
                      src={form.watch('imageUrl')} 
                      alt={form.watch('title')} 
                      className="w-full aspect-video object-cover"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = 'https://placehold.co/600x400?text=Image+invalide';
                      }}
                    />
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black/50 transition-opacity">
                      <Button 
                        variant="secondary" 
                        size="sm" 
                        onClick={() => window.open(form.watch('imageUrl'), '_blank')}
                      >
                        Voir en taille réelle
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-[200px] bg-muted">
                    <ImageIcon className="h-12 w-12 text-muted-foreground opacity-50" />
                    <p className="text-sm text-muted-foreground mt-2">Aperçu non disponible</p>
                  </div>
                )}
              </CardContent>
            </Card>
            
            <div className="mt-4">
              <h3 className="font-medium">{form.watch('title') || 'Titre non défini'}</h3>
              {form.watch('description') && (
                <p className="text-sm text-gray-600 mt-1">{form.watch('description')}</p>
              )}
              <div className="flex flex-wrap gap-1 mt-2">
                {form.watch('tags')?.map((tag) => (
                  <span 
                    key={tag} 
                    className="inline-block px-2 py-0.5 text-xs font-medium rounded-full bg-green-100 text-green-800"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default GalleryForm;
