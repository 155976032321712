
import { InventoryItem } from '@/types/database';
import { createApi } from '../utils/apiFactory';
import { supabaseInventoryApi } from './supabaseInventoryApi';
import { supabase } from '@/integrations/supabase/client';

// Clé de stockage local pour l'inventaire (fallback)
const STORAGE_KEY = 'greenwild_inventory';

// Créer une API de fallback basée sur le stockage local
const localInventoryApi = createApi<InventoryItem>(STORAGE_KEY);

// Exporter l'API d'inventaire
export const inventoryApi = {
  getAll: async (): Promise<InventoryItem[]> => {
    try {
      // Toujours essayer d'utiliser Supabase d'abord pour la lecture
      return await supabaseInventoryApi.getAll();
    } catch (error) {
      console.error('Erreur avec Supabase, utilisation du stockage local:', error);
      // Fallback sur le stockage local
      return localInventoryApi.getAll();
    }
  },
  
  getById: async (id: string): Promise<InventoryItem | undefined> => {
    try {
      return await supabaseInventoryApi.getById(id);
    } catch (error) {
      console.error('Erreur avec Supabase, utilisation du stockage local:', error);
      return localInventoryApi.getById(id);
    }
  },
  
  // Pour les opérations d'écriture, on vérifie l'authentification
  create: async (data: Omit<InventoryItem, 'id' | 'createdAt' | 'updatedAt'>): Promise<InventoryItem> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        return await supabaseInventoryApi.create(data);
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.error('Erreur avec Supabase, utilisation du stockage local:', error);
      return localInventoryApi.create(data as any);
    }
  },
  
  update: async (id: string, data: Partial<InventoryItem>): Promise<InventoryItem> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        return await supabaseInventoryApi.update(id, data);
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.error('Erreur avec Supabase, utilisation du stockage local:', error);
      return localInventoryApi.update(id, data);
    }
  },
  
  delete: async (id: string): Promise<void> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        return await supabaseInventoryApi.delete(id);
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.error('Erreur avec Supabase, utilisation du stockage local:', error);
      return localInventoryApi.delete(id);
    }
  },
  
  // Méthodes spécifiques pour les mouvements de stock
  getStockMovements: async (inventoryId: string) => {
    try {
      return await supabaseInventoryApi.getStockMovements(inventoryId);
    } catch (error) {
      console.error('Erreur avec Supabase:', error);
      // Retourner un tableau vide en cas d'erreur
      return [];
    }
  },
  
  addStockMovement: async (
    inventoryId: string,
    quantity: number,
    movementType: 'add' | 'remove',
    notes?: string
  ) => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        return await supabaseInventoryApi.addStockMovement(inventoryId, quantity, movementType, notes);
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.error('Erreur avec Supabase:', error);
      
      // Fallback pour le stockage local (version simplifiée)
      const item = await localInventoryApi.getById(inventoryId);
      if (!item) {
        throw new Error('Article non trouvé');
      }
      
      const newQuantity = movementType === 'add' 
        ? item.quantity + quantity 
        : item.quantity - quantity;
      
      if (newQuantity < 0) {
        throw new Error('La quantité ne peut pas être négative');
      }
      
      await localInventoryApi.update(inventoryId, { quantity: newQuantity });
      
      // Simple objet de mouvement pour le stockage local (pas persistant)
      const movement = {
        id: Date.now().toString(),
        inventory_id: inventoryId,
        quantity,
        movement_type: movementType,
        notes: notes || null,
        created_at: new Date().toISOString()
      };
      
      return { movement, newQuantity };
    }
  }
};
