import React from 'react';
import { ArrowRight, Heart } from 'lucide-react';

const Donation = () => {
  return (
    <section id="donation" className="py-24 bg-gradient-to-b from-green-50 to-white">
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-16">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Soutenez-nous
          </span>
          <h2 className="section-title mb-4">
            Faites un don pour soutenir nos actions
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl text-balance">
            Chaque contribution, même la plus modeste, nous permet de continuer notre mission 
            de préservation de l'environnement et d'organiser davantage d'actions de nettoyage.
          </p>
        </div>
        
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="grid md:grid-cols-2">
            <div className="p-8 md:p-12 flex flex-col justify-center">
              <h3 className="text-2xl font-bold text-green-800 mb-4">Pourquoi faire un don ?</h3>
              <ul className="space-y-4 mb-8">
                <li className="flex items-start">
                  <div className="mr-3 bg-green-100 p-1 rounded-full text-green-700">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <span className="text-gray-700">Achat de matériel de nettoyage</span>
                </li>
                <li className="flex items-start">
                  <div className="mr-3 bg-green-100 p-1 rounded-full text-green-700">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <span className="text-gray-700">Organisation d'événements de sensibilisation</span>
                </li>
                <li className="flex items-start">
                  <div className="mr-3 bg-green-100 p-1 rounded-full text-green-700">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                  <span className="text-gray-700">Développement de nouveaux projets écologiques</span>
                </li>
              </ul>
              <a 
                href="https://greenwild.assoconnect.com/collect/description/451107-n-campagne-de-dons-pour-soutenir-nos-projets" 
                target="_blank" 
                rel="noopener noreferrer"
                className="group flex items-center justify-center bg-green-700 hover:bg-green-600 text-white font-medium py-3 px-6 rounded-lg transition-colors shadow-lg hover:shadow-xl transform hover:scale-105 duration-300"
              >
                <Heart className="mr-2 h-5 w-5" />
                <span>Faire un don maintenant</span>
                <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
            
            <div className="hidden md:block bg-donation-image bg-cover bg-center"></div>
          </div>
        </div>
        
        <div className="mt-12 text-center">
          <p className="text-sm text-gray-500">
            GreenWild est une association à but non lucratif.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Donation;
