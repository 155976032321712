
import React from "react";
import { CleanupAction } from "@/types/database";
import { Form } from "@/components/ui/form";
import BasicInfoSection from "./components/BasicInfoSection";
import DateLocationSection from "./components/DateLocationSection";
import HikingDetailsSection from "./components/HikingDetailsSection";
import WasteDataSection from "./components/WasteDataSection";
import FormActions from "./components/FormActions";
import { useActionForm } from "./hooks/useActionForm";
import { useNavigate } from "react-router-dom";
import { actionsApi } from "@/services/actions";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";

type ActionFormProps = {
  action?: CleanupAction;
};

const ActionForm = ({ action }: ActionFormProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = React.useState(false);
  
  const {
    form,
    latitude,
    longitude,
    setLatitude,
    setLongitude,
    handleCoordinateChange,
    onSubmit,
    isSubmitting
  } = useActionForm(action);
  
  const status = form.watch("status");
  const isCompleted = status === "completed";

  const handleDelete = async () => {
    if (!action || !action.id) return;
    
    setIsDeleting(true);
    try {
      await actionsApi.delete(action.id);
      toast({
        title: "Action supprimée",
        description: "L'action a été supprimée avec succès",
      });
      queryClient.invalidateQueries({ queryKey: ['actions'] });
      navigate('/admin/actions');
    } catch (error) {
      console.error("Error deleting action:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <BasicInfoSection form={form} />
            <DateLocationSection 
              form={form}
              latitude={latitude}
              longitude={longitude}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
              handleCoordinateChange={handleCoordinateChange}
            />
          </div>
          <div className="space-y-6">
            <HikingDetailsSection form={form} />
            <WasteDataSection form={form} isCompleted={isCompleted} />
          </div>
        </div>

        <FormActions 
          isSubmitting={isSubmitting || isDeleting}
          isEditing={!!action}
          submitText={action ? "Mettre à jour" : "Créer"}
          cancelHref="/admin/actions"
          showDelete={!!action}
          onDelete={handleDelete}
        />
      </form>
    </Form>
  );
};

export default ActionForm;
