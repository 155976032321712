
import React from "react";
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { CleanupAction } from "@/types/database";
import ActionRow from "./ActionRow";

type ActionsTableProps = {
  actions: CleanupAction[];
  onDeleteAction: (id: string) => void;
  onEdit?: (id: string) => void;
  onView?: (id: string) => void;
  onDelete?: (id: string) => Promise<void> | void;
};

const ActionsTable = ({ actions, onEdit, onView, onDelete, onDeleteAction }: ActionsTableProps) => {
  // Use onDelete if provided, otherwise fall back to onDeleteAction
  const handleDelete = onDelete || onDeleteAction;
  
  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Titre</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Date et heure</TableHead>
            <TableHead>Lieu</TableHead>
            <TableHead>Participants</TableHead>
            <TableHead>Statut</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {actions.map((action) => (
            <ActionRow 
              key={action.id} 
              action={action} 
              onDeleteAction={handleDelete}
              onEdit={onEdit}
              onView={onView}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ActionsTable;
