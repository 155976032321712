
import React from "react";
import { Link } from "react-router-dom";
import ActionCard from "./ActionCard";
import FallbackActionCards from "./FallbackActionCards";
import { useUpcomingActions } from "@/hooks/useUpcomingActions";

const Activities = () => {
  const { upcomingActions } = useUpcomingActions(3);
  
  return (
    <section id="activities" className="py-24 bg-white">
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-16">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Nos Actions
          </span>
          <h2 className="section-title mb-4">
            Participez à nos actions de nettoyage
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl text-balance">
            Rejoignez-nous lors de nos randonnées de nettoyage et autres événements 
            pour contribuer à la préservation de notre environnement.
          </p>
        </div>
        
        {/* Activities Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {upcomingActions.length > 0 ? (
            upcomingActions.map((action) => (
              <ActionCard key={action.id} action={action} />
            ))
          ) : (
            <FallbackActionCards />
          )}
        </div>
        
        {/* CTA Button */}
        <div className="text-center mt-12">
          <Link 
            to="/calendar" 
            className="inline-block px-8 py-3 bg-green-700 text-white font-medium rounded-md shadow-sm hover:bg-green-600 transition-colors transform hover:scale-105 duration-300"
          >
            Voir toutes nos actions
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Activities;
