
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

/**
 * Delete an action from the database
 * No authentication required - public access
 */
export const deleteAction = async (id: string): Promise<void> => {
  try {
    console.log(`Deleting action with ID: ${id}`);
    
    const { error } = await supabase
      .from('actions')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error('Error deleting action:', error);
      toast.error(`Erreur lors de la suppression: ${error.message}`);
      throw error;
    }
    
    console.log(`Action with ID ${id} deleted successfully`);
    toast.success("Action supprimée avec succès");
  } catch (error) {
    console.error(`Error in deleteAction (ID: ${id}):`, error);
    throw error;
  }
};
