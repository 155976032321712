
import { Invoice } from '@/types/database';
import { InvoiceFormValues } from '@/types/invoice';
import { supabase } from '@/integrations/supabase/client';

export const supabaseInvoicesApi = {
  getAll: async (): Promise<Invoice[]> => {
    const { data, error } = await supabase
      .from('invoices')
      .select('*')
      .order('created_at', { ascending: false });
      
    if (error) {
      console.error('Error fetching invoices from Supabase:', error);
      throw error;
    }
    
    // Transformer les données pour correspondre au type Invoice
    return data.map(item => ({
      id: item.id,
      number: item.number,
      title: item.title,
      description: item.description || undefined,
      amount: Number(item.amount),
      date: item.date,
      dueDate: item.due_date || undefined,
      vendor: item.vendor,
      category: item.category as 'equipment' | 'consumables' | 'services' | 'rent' | 'other',
      status: item.status as 'paid' | 'pending' | 'overdue',
      fileUrl: item.file_url || undefined,
      notes: item.notes || undefined,
      recurring: item.recurring || false,
      createdAt: item.created_at,
      updatedAt: item.updated_at
    }));
  },
  
  getById: async (id: string): Promise<Invoice | undefined> => {
    const { data, error } = await supabase
      .from('invoices')
      .select('*')
      .eq('id', id)
      .single();
      
    if (error) {
      if (error.code === 'PGRST116') {
        // Code "PGRST116" signifie "No rows returned" dans Supabase
        return undefined;
      }
      console.error('Error fetching invoice from Supabase:', error);
      throw error;
    }
    
    if (!data) return undefined;
    
    return {
      id: data.id,
      number: data.number,
      title: data.title,
      description: data.description || undefined,
      amount: Number(data.amount),
      date: data.date,
      dueDate: data.due_date || undefined,
      vendor: data.vendor,
      category: data.category as 'equipment' | 'consumables' | 'services' | 'rent' | 'other',
      status: data.status as 'paid' | 'pending' | 'overdue',
      fileUrl: data.file_url || undefined,
      notes: data.notes || undefined,
      recurring: data.recurring || false,
      createdAt: data.created_at,
      updatedAt: data.updated_at
    };
  },
  
  create: async (data: InvoiceFormValues): Promise<Invoice> => {
    // Obtenir l'ID utilisateur courant
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');
    
    const { data: createdInvoice, error } = await supabase
      .from('invoices')
      .insert({
        number: data.number,
        title: data.title,
        description: data.description,
        amount: data.amount,
        date: data.date,
        due_date: data.dueDate,
        vendor: data.vendor,
        category: data.category,
        status: data.status,
        file_url: data.fileUrl,
        notes: data.notes,
        recurring: data.recurring || false,
        user_id: user.id
      })
      .select()
      .single();
      
    if (error) {
      console.error('Error creating invoice in Supabase:', error);
      throw error;
    }
    
    if (!createdInvoice) throw new Error('Failed to create invoice');
    
    return {
      id: createdInvoice.id,
      number: createdInvoice.number,
      title: createdInvoice.title,
      description: createdInvoice.description || undefined,
      amount: Number(createdInvoice.amount),
      date: createdInvoice.date,
      dueDate: createdInvoice.due_date || undefined,
      vendor: createdInvoice.vendor,
      category: createdInvoice.category as 'equipment' | 'consumables' | 'services' | 'rent' | 'other',
      status: createdInvoice.status as 'paid' | 'pending' | 'overdue',
      fileUrl: createdInvoice.file_url || undefined,
      notes: createdInvoice.notes || undefined,
      recurring: createdInvoice.recurring || false,
      createdAt: createdInvoice.created_at,
      updatedAt: createdInvoice.updated_at
    };
  },
  
  update: async (id: string, data: Partial<Invoice>): Promise<Invoice> => {
    // Préparer les données pour Supabase (conversion de camelCase à snake_case)
    const updateData: any = {};
    
    if (data.number !== undefined) updateData.number = data.number;
    if (data.title !== undefined) updateData.title = data.title;
    if (data.description !== undefined) updateData.description = data.description;
    if (data.amount !== undefined) updateData.amount = data.amount;
    if (data.date !== undefined) updateData.date = data.date;
    if (data.dueDate !== undefined) updateData.due_date = data.dueDate;
    if (data.vendor !== undefined) updateData.vendor = data.vendor;
    if (data.category !== undefined) updateData.category = data.category;
    if (data.status !== undefined) updateData.status = data.status;
    if (data.fileUrl !== undefined) updateData.file_url = data.fileUrl;
    if (data.notes !== undefined) updateData.notes = data.notes;
    if (data.recurring !== undefined) updateData.recurring = data.recurring;
    
    const { data: updatedInvoice, error } = await supabase
      .from('invoices')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();
      
    if (error) {
      console.error('Error updating invoice in Supabase:', error);
      throw error;
    }
    
    return {
      id: updatedInvoice.id,
      number: updatedInvoice.number,
      title: updatedInvoice.title,
      description: updatedInvoice.description || undefined,
      amount: Number(updatedInvoice.amount),
      date: updatedInvoice.date,
      dueDate: updatedInvoice.due_date || undefined,
      vendor: updatedInvoice.vendor,
      category: updatedInvoice.category as 'equipment' | 'consumables' | 'services' | 'rent' | 'other',
      status: updatedInvoice.status as 'paid' | 'pending' | 'overdue',
      fileUrl: updatedInvoice.file_url || undefined,
      notes: updatedInvoice.notes || undefined,
      recurring: updatedInvoice.recurring || false,
      createdAt: updatedInvoice.created_at,
      updatedAt: updatedInvoice.updated_at
    };
  },
  
  delete: async (id: string): Promise<void> => {
    const { error } = await supabase
      .from('invoices')
      .delete()
      .eq('id', id);
      
    if (error) {
      console.error('Error deleting invoice from Supabase:', error);
      throw error;
    }
  }
};
