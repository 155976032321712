
import { GalleryItem } from '@/types/database';
import { createApi } from '../utils/apiFactory';
import { supabaseGalleryApi } from './supabaseGalleryApi';
import { supabase } from '@/integrations/supabase/client';

// Storage key for gallery items (utilisé comme fallback si Supabase échoue)
const STORAGE_KEY = 'greenwild_gallery';

// Créer l'API locale comme fallback
const localGalleryApi = createApi<GalleryItem>(STORAGE_KEY);

// Exporter l'API Supabase comme API principale, avec fallback vers localStorage si nécessaire
export const galleryApi = {
  getAll: async () => {
    try {
      // Pour la galerie, on récupère toujours les données de Supabase, même sans authentification
      // car c'est une fonctionnalité publique
      return await supabaseGalleryApi.getAll();
    } catch (error) {
      console.warn('Fallback to local storage for gallery getAll:', error);
      return localGalleryApi.getAll();
    }
  },
  
  getById: async (id: string) => {
    try {
      // Même approche pour getById - on essaie toujours Supabase d'abord
      return await supabaseGalleryApi.getById(id);
    } catch (error) {
      console.warn('Fallback to local storage for gallery getById:', error);
      return localGalleryApi.getById(id);
    }
  },
  
  // Pour les opérations de modification, l'authentification reste nécessaire
  create: async (data: Omit<GalleryItem, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      // Vérifier si l'utilisateur est authentifié
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        console.log('Création via Supabase avec données:', data);
        return await supabaseGalleryApi.create(data);
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.warn('Fallback to local storage for gallery create:', error);
      return localGalleryApi.create(data);
    }
  },
  
  update: async (id: string, data: Partial<GalleryItem>) => {
    console.log('Tentative de mise à jour avec ID:', id, 'et données:', data);
    
    try {
      // Vérifier si l'utilisateur est authentifié
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        console.log('Authentifié, mise à jour via Supabase');
        const result = await supabaseGalleryApi.update(id, data);
        console.log('Résultat de la mise à jour Supabase:', result);
        return result;
      } else {
        console.log('Non authentifié, fallback vers localStorage');
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour via Supabase:', error);
      console.warn('Fallback to local storage for gallery update');
    }
    
    // Le format des paramètres est différent pour l'API locale
    try {
      const fullItem = await localGalleryApi.getById(id);
      if (!fullItem) {
        throw new Error(`Item with id ${id} not found`);
      }
      console.log('Mise à jour via localStorage avec item complet:', {...fullItem, ...data});
      return await localGalleryApi.update(id, {...fullItem, ...data});
    } catch (localError) {
      console.error('Erreur lors de la mise à jour via localStorage:', localError);
      throw localError;
    }
  },
  
  delete: async (id: string) => {
    try {
      // Vérifier si l'utilisateur est authentifié
      const { data: session } = await supabase.auth.getSession();
      if (session.session) {
        await supabaseGalleryApi.delete(id);
        return;
      } else {
        throw new Error('Authentication required');
      }
    } catch (error) {
      console.warn('Fallback to local storage for gallery delete:', error);
      await localGalleryApi.delete(id);
    }
  }
};
