
import React from "react";
import { Filter, ChevronDown, ChevronUp } from "lucide-react";

interface FiltersState {
  type: string;
  difficulty: string;
}

interface ActionsFilterProps {
  filterOpen: boolean;
  setFilterOpen: (isOpen: boolean) => void;
  filters: FiltersState;
  setFilters: (filters: FiltersState) => void;
}

const ActionsFilter = ({ 
  filterOpen, 
  setFilterOpen, 
  filters, 
  setFilters 
}: ActionsFilterProps) => {
  return (
    <div className="mb-8">
      <button 
        onClick={() => setFilterOpen(!filterOpen)}
        className="flex items-center mx-auto px-4 py-2 bg-gray-100 rounded-lg text-gray-700 font-medium transition-colors hover:bg-gray-200"
      >
        <Filter className="w-4 h-4 mr-2" />
        Filtrer
        {filterOpen ? (
          <ChevronUp className="w-4 h-4 ml-2" />
        ) : (
          <ChevronDown className="w-4 h-4 ml-2" />
        )}
      </button>
      
      {filterOpen && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Type d'action</label>
              <select 
                className="w-full p-2 border border-gray-300 rounded-md"
                value={filters.type}
                onChange={(e) => setFilters({...filters, type: e.target.value})}
              >
                <option value="all">Tous les types</option>
                <option value="cleanup">Nettoyage</option>
                <option value="workshop">Atelier</option>
                <option value="awareness">Sensibilisation</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Difficulté</label>
              <select 
                className="w-full p-2 border border-gray-300 rounded-md"
                value={filters.difficulty}
                onChange={(e) => setFilters({...filters, difficulty: e.target.value})}
              >
                <option value="all">Toutes les difficultés</option>
                <option value="easy">Facile</option>
                <option value="medium">Moyen</option>
                <option value="hard">Difficile</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionsFilter;
