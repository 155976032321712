
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';

interface PermissionGuardProps {
  permission?: string;
  children: ReactNode;
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({ permission, children }) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [accessChecked, setAccessChecked] = useState(false);
  
  // Check if we're in the admin section
  const isAdminSection = location.pathname.includes('/admin');
  const isLoginPage = location.pathname.includes('/admin/login');
  
  // Add more detailed logs to track authentication flow
  console.log("PermissionGuard: user status:", user ? "Authenticated" : "Not authenticated");
  console.log("PermissionGuard: loading status:", loading);
  console.log("PermissionGuard: requested permission:", permission);
  console.log("PermissionGuard: current path:", location.pathname);
  
  useEffect(() => {
    // Only perform access checks when loading is complete
    if (!loading) {
      // Case 1: Not logged in but in admin section (not login page) -> redirect to login
      if (!user && isAdminSection && !isLoginPage) {
        console.log("PermissionGuard: redirecting to login");
        navigate('/admin/login', { replace: true });
      }
      
      // Case 2: Logged in but missing required permission -> redirect to dashboard
      else if (user && permission && user.permissions) {
        if (!user.permissions.includes(permission)) {
          console.log(`PermissionGuard: user lacks permission: ${permission}`);
          navigate('/admin/dashboard', { replace: true });
        }
      }
      
      // Mark access check as complete
      setAccessChecked(true);
    }
  }, [user, loading, navigate, permission, isAdminSection, isLoginPage, location.pathname]);

  // Show loading indicator while checking authentication
  if (loading) {
    return (
      <div className="flex justify-center items-center p-6 min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-700"></div>
        <span className="ml-3">Vérification de l'authentification...</span>
      </div>
    );
  }

  // Don't render until access check is complete
  if (!accessChecked) {
    return null;
  }

  // Don't render children if in admin area and not authenticated (except login page)
  if (!user && isAdminSection && !isLoginPage) {
    return null;
  }

  // If all checks pass, render children
  console.log("PermissionGuard: allowing access");
  return <>{children}</>;
};

export default PermissionGuard;
