
import { supabase } from '@/integrations/supabase/client';

/**
 * Fonction d'initialisation de l'administrateur
 * Cette fonction vérifie l'existence d'un utilisateur administrateur et tente de le créer s'il n'existe pas
 */
export const initializeAdmin = async (): Promise<void> => {
  try {
    console.log("Checking for admin user existence");
    
    // Vérifier si l'utilisateur admin existe déjà dans la table profiles
    const { data: profiles, error: profileError } = await supabase
      .from('profiles')
      .select('id, email, role, permissions')
      .eq('email', 'admin@greenwild.org')
      .limit(1);
    
    if (profileError) {
      console.error("Error checking admin profile:", profileError);
      return;
    }
    
    // Si aucun admin n'existe, essayer de le créer
    if (!profiles || profiles.length === 0) {
      console.log("No admin user found, attempting to create one");
      
      try {
        // Essayons d'abord de voir si l'utilisateur existe déjà dans auth mais pas dans profiles
        const { data: existingUsers, error: existingError } = await supabase.auth.admin.listUsers({
          page: 1,
          perPage: 1,
        });
        
        if (existingError) {
          console.log("Unable to check existing users (expected in preview):", existingError.message);
        } else if (existingUsers?.users && existingUsers.users.length > 0) {
          // Fix: Check if users array exists and has items before trying to find
          // Use explicit typecasting to handle the user object properly
          const adminUser = existingUsers.users.find(user => {
            // Safely check if this is a user object with an email property
            if (user && typeof user === 'object' && 'email' in user) {
              // Use type assertion after we've verified 'email' property exists
              return (user as { email: string }).email === 'admin@greenwild.org';
            }
            return false;
          });
          
          if (adminUser) {
            console.log("Admin user exists in auth but not in profiles, creating profile");
            
            // Créer le profil pour l'utilisateur admin existant
            const { data: profileData, error: insertError } = await supabase
              .from('profiles')
              .insert({
                id: adminUser.id,
                email: 'admin@greenwild.org',
                first_name: 'Admin',
                last_name: 'User',
                role: 'admin',
                permissions: ['manage_actions', 'manage_gallery', 'manage_waste', 'manage_inventory', 'manage_users', 'manage_invoices']
              })
              .select();
              
            if (insertError) {
              console.error("Error creating admin profile:", insertError);
            } else {
              console.log("Admin profile created for existing user:", profileData);
            }
            
            return;
          }
        }
        
        // Première tentative: via l'API admin (nécessite des droits admin)
        console.log("Attempting to create admin user via admin API");
        const { data, error } = await supabase.auth.admin.createUser({
          email: 'admin@greenwild.org',
          password: 'Gr33nWild@2025!',
          email_confirm: true,
          user_metadata: {
            first_name: 'Admin',
            last_name: 'User'
          }
        });
        
        if (error) {
          console.log("Admin API not available (expected in preview):", error.message);
          
          // Seconde tentative: inscription standard 
          console.log("Attempting to create admin user via signUp");
          const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
            email: 'admin@greenwild.org',
            password: 'Gr33nWild@2025!',
            options: {
              data: {
                first_name: 'Admin',
                last_name: 'User'
              },
              // Essayons d'auto-confirmer dans les options
              emailRedirectTo: window.location.origin
            }
          });
          
          if (signUpError) {
            console.error("Error creating admin via signUp:", signUpError);
          } else {
            console.log("Admin user created via signUp:", signUpData);
            
            // Pour le mode preview, nous allons tenter une connexion immédiate
            // qui pourrait fonctionner si l'auto-confirmation est active dans Supabase
            try {
              console.log("Attempting to sign in with new admin account");
              await supabase.auth.signInWithPassword({
                email: 'admin@greenwild.org',
                password: 'Gr33nWild@2025!'
              });
              
              // Se déconnecter pour que l'utilisateur puisse se connecter normalement
              await supabase.auth.signOut();
            } catch (loginError) {
              console.log("Auto-login attempt failed (expected if email confirmation required):", loginError);
            }
            
            console.log("User created but email confirmation may be required");
            console.log("In preview environments, you may need to disable email confirmation in Supabase dashboard");
          }
        } else {
          console.log("Admin user created successfully via admin API:", data);
        }
      } catch (creationError) {
        console.error("Error in admin user creation process:", creationError);
      }
    } else {
      console.log("Admin user already exists:", profiles[0]);
    }
  } catch (error) {
    console.error("Error in initializeAdmin:", error);
  }
};
