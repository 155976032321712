
import React from "react";
import { 
  PieChart, 
  Pie, 
  Cell, 
  Tooltip, 
  ResponsiveContainer 
} from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CategoryTotals } from "@/types/invoice";

interface CategoryPieChartProps {
  categoryTotals: CategoryTotals[];
  formatCurrency: (amount: number) => string;
  categoryLabels: Record<string, string>;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const CategoryPieChart = ({ categoryTotals, formatCurrency, categoryLabels }: CategoryPieChartProps) => {
  // Format category data for the pie chart
  const pieData = categoryTotals.map(item => ({
    name: categoryLabels[item.category] || item.category,
    value: item.amount
  }));
  
  return (
    <Card>
      <CardHeader>
        <CardTitle>Répartition des dépenses par catégorie</CardTitle>
        <CardDescription>
          Pourcentage des dépenses dans chaque catégorie
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col md:flex-row items-center">
        <div className="h-[300px] w-full md:w-1/2">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [formatCurrency(value as number), ""]} />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0">
          <ul className="space-y-2">
            {categoryTotals.map((item, index) => (
              <li key={item.category} className="flex items-center justify-between">
                <div className="flex items-center">
                  <span 
                    className="w-3 h-3 mr-2 rounded-full" 
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  ></span>
                  <span>{categoryLabels[item.category] || item.category}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium">{formatCurrency(item.amount)}</span>
                  <span className="text-gray-500 text-sm">({item.percentage.toFixed(1)}%)</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

export default CategoryPieChart;
