
import { supabase } from '@/integrations/supabase/client';
import { CleanupAction } from '@/types/database';
import { UpdateActionData } from './types';
import { transformActionFromDb, transformActionToDb } from './transformers';
import { toast } from 'sonner';

/**
 * Update an existing action in the database
 * No authentication required - public access
 */
export const update = async (id: string, actionData: UpdateActionData): Promise<CleanupAction> => {
  try {
    console.log('Updating action with ID:', id);
    console.log('Update data:', actionData);
    
    // Convert the data to database format
    const dbData = transformActionToDb(actionData);
    console.log('Transformed data for DB:', dbData);
    
    const { data, error } = await supabase
      .from('actions')
      .update({
        ...dbData,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select('*')  // Utiliser select('*') au lieu de select() pour s'assurer de récupérer toutes les colonnes
      .maybeSingle(); // Utiliser maybeSingle() au lieu de single() pour éviter les erreurs
    
    if (error) {
      console.error('Error updating action:', error);
      toast.error(`Erreur lors de la mise à jour: ${error.message}`);
      throw error;
    }
    
    if (!data) {
      console.error('No data returned after update');
      toast.error("Aucune donnée retournée après la mise à jour");
      // Solution critique: au lieu de lancer une erreur, récupérer les données actualisées
      const { data: refreshedData, error: refreshError } = await supabase
        .from('actions')
        .select('*')
        .eq('id', id)
        .maybeSingle();
        
      if (refreshError || !refreshedData) {
        console.error('Error fetching updated action:', refreshError);
        throw new Error('Failed to retrieve updated action');
      }
      
      console.log('Retrieved action after update:', refreshedData);
      toast.success("Action mise à jour avec succès");
      return transformActionFromDb(refreshedData);
    }
    
    console.log('Update successful, received data:', data);
    toast.success("Action mise à jour avec succès");
    return transformActionFromDb(data);
  } catch (error) {
    console.error('Error in update action:', error);
    throw error;
  }
};
