
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { InventoryItem } from '@/types/database';
import { inventoryApi } from '@/services/api';

// Define the inventory category type
type InventoryCategory = 'equipment' | 'clothing' | 'consumable' | 'other';

// Define the inventory form schema
const inventoryFormSchema = z.object({
  name: z.string().min(2, { message: 'Le nom doit contenir au moins 2 caractères' }),
  category: z.enum(['equipment', 'clothing', 'consumable', 'other'], { 
    message: 'Veuillez sélectionner une catégorie' 
  }),
  quantity: z.number().min(0, { message: 'La quantité ne peut pas être négative' }),
  unit: z.string().min(1, { message: 'Veuillez spécifier une unité' }),
  description: z.string().optional(),
  minQuantity: z.number().min(0, { message: 'La quantité minimale ne peut pas être négative' }).optional(),
  imageUrl: z.string().url({ message: 'L\'URL de l\'image n\'est pas valide' }).optional().or(z.literal('')),
  sizes: z.string().optional()
});

export type InventoryFormValues = z.infer<typeof inventoryFormSchema>;

export const useInventoryForm = (inventoryItem?: InventoryItem) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm<InventoryFormValues>({
    resolver: zodResolver(inventoryFormSchema),
    defaultValues: {
      name: inventoryItem?.name || '',
      category: (inventoryItem?.category as InventoryCategory) || 'equipment',
      quantity: inventoryItem?.quantity || 0,
      unit: inventoryItem?.unit || '',
      description: inventoryItem?.description || '',
      minQuantity: inventoryItem?.minQuantity || 0,
      imageUrl: inventoryItem?.imageUrl || '',
      sizes: ''
    },
  });
  
  const watchCategory = form.watch('category');
  
  // Create mutation
  const createMutation = useMutation({
    mutationFn: (data: InventoryFormValues) => {
      console.log("Creating inventory item with data:", data);
      return inventoryApi.create({
        name: data.name,
        category: data.category,
        quantity: data.quantity,
        unit: data.unit,
        description: data.description || undefined,
        minQuantity: data.minQuantity,
        imageUrl: data.imageUrl || undefined
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryItems'] });
      toast.success('Article ajouté avec succès');
      navigate('/admin/inventory');
    },
    onError: (error: Error) => {
      console.error("Error creating inventory item:", error);
      toast.error(`Erreur lors de l'ajout: ${error.message}`);
    }
  });
  
  // Update mutation
  const updateMutation = useMutation({
    mutationFn: (data: InventoryFormValues) => {
      if (!inventoryItem) throw new Error('No inventory item to update');
      console.log("Updating inventory item with data:", data);
      return inventoryApi.update(inventoryItem.id, {
        name: data.name,
        category: data.category,
        quantity: data.quantity,
        unit: data.unit,
        description: data.description || undefined,
        minQuantity: data.minQuantity,
        imageUrl: data.imageUrl || undefined
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryItems'] });
      if (inventoryItem?.id) {
        queryClient.invalidateQueries({ queryKey: ['inventoryItem', inventoryItem.id] });
      }
      toast.success('Article mis à jour avec succès');
      navigate('/admin/inventory');
    },
    onError: (error: Error) => {
      console.error("Error updating inventory item:", error);
      toast.error(`Erreur lors de la mise à jour: ${error.message}`);
    }
  });
  
  const onSubmit = async (data: InventoryFormValues) => {
    console.log("Form submitted with data:", data);
    setIsSubmitting(true);
    try {
      if (inventoryItem) {
        await updateMutation.mutateAsync(data);
      } else {
        await createMutation.mutateAsync(data);
      }
    } catch (error) {
      console.error("Error in onSubmit:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return {
    form,
    onSubmit,
    watchCategory,
    isSubmitting
  };
};
