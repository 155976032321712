
import { supabase } from '@/integrations/supabase/client';
import { GalleryItem } from '@/types/database';

// Service pour accéder à la table gallery via Supabase
export const supabaseGalleryApi = {
  // Récupérer tous les éléments de la galerie
  getAll: async (): Promise<GalleryItem[]> => {
    try {
      const { data, error } = await supabase
        .from('gallery')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Erreur lors de la récupération des images:', error);
        throw error;
      }

      // Transformer les données Supabase en format GalleryItem
      return (data || []).map(item => ({
        id: item.id,
        title: item.title,
        description: item.description || undefined,
        imageUrl: item.image_url,
        actionId: item.action_id || undefined,
        date: item.date,
        tags: item.tags || [],
        createdAt: item.created_at,
        updatedAt: item.updated_at
      }));
    } catch (error) {
      console.error('Erreur dans getAll gallery:', error);
      throw error;
    }
  },

  // Récupérer un élément spécifique par son ID
  getById: async (id: string): Promise<GalleryItem | undefined> => {
    try {
      const { data, error } = await supabase
        .from('gallery')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        if (error.code === 'PGRST116') { // Code pour "No rows returned"
          return undefined;
        }
        console.error('Erreur lors de la récupération de l\'image:', error);
        throw error;
      }

      if (!data) return undefined;

      // Transformer les données Supabase en format GalleryItem
      return {
        id: data.id,
        title: data.title,
        description: data.description || undefined,
        imageUrl: data.image_url,
        actionId: data.action_id || undefined,
        date: data.date,
        tags: data.tags || [],
        createdAt: data.created_at,
        updatedAt: data.updated_at
      };
    } catch (error) {
      console.error('Erreur dans getById gallery:', error);
      throw error;
    }
  },

  // Créer un nouvel élément dans la galerie
  create: async (data: Omit<GalleryItem, 'id' | 'createdAt' | 'updatedAt'>): Promise<GalleryItem> => {
    try {
      console.log('Tentative de création d\'élément gallery avec les données:', data);

      // Préparation des données pour l'insertion
      const insertData = {
        title: data.title,
        description: data.description,
        image_url: data.imageUrl,
        action_id: data.actionId,
        date: data.date,
        tags: data.tags || []
      };

      const { data: newItem, error } = await supabase
        .from('gallery')
        .insert(insertData)
        .select()
        .single();

      if (error) {
        console.error('Erreur lors de la création de l\'image:', error);
        throw error;
      }

      console.log('Élément gallery créé avec succès:', newItem);

      // Transformer les données Supabase en format GalleryItem
      return {
        id: newItem.id,
        title: newItem.title,
        description: newItem.description || undefined,
        imageUrl: newItem.image_url,
        actionId: newItem.action_id || undefined,
        date: newItem.date,
        tags: newItem.tags || [],
        createdAt: newItem.created_at,
        updatedAt: newItem.updated_at
      };
    } catch (error) {
      console.error('Erreur dans create gallery:', error);
      throw error;
    }
  },

  // Mettre à jour un élément existant
  update: async (id: string, data: Partial<GalleryItem>): Promise<GalleryItem> => {
    try {
      console.log('Mise à jour de l\'élément gallery avec ID:', id, 'et données:', data);

      // Préparation des données pour la mise à jour
      const updateData: any = {};
      if (data.title !== undefined) updateData.title = data.title;
      if (data.description !== undefined) updateData.description = data.description;
      if (data.imageUrl !== undefined) updateData.image_url = data.imageUrl;
      if (data.actionId !== undefined) updateData.action_id = data.actionId;
      if (data.date !== undefined) updateData.date = data.date;
      if (data.tags !== undefined) updateData.tags = data.tags;
      
      updateData.updated_at = new Date().toISOString();

      console.log('Données formatées pour la mise à jour:', updateData);

      const { data: updatedItem, error } = await supabase
        .from('gallery')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Erreur lors de la mise à jour de l\'image:', error);
        throw error;
      }

      console.log('Élément gallery mis à jour avec succès:', updatedItem);

      // Transformer les données Supabase en format GalleryItem
      return {
        id: updatedItem.id,
        title: updatedItem.title,
        description: updatedItem.description || undefined,
        imageUrl: updatedItem.image_url,
        actionId: updatedItem.action_id || undefined,
        date: updatedItem.date,
        tags: updatedItem.tags || [],
        createdAt: updatedItem.created_at,
        updatedAt: updatedItem.updated_at
      };
    } catch (error) {
      console.error('Erreur dans update gallery:', error);
      throw error;
    }
  },

  // Supprimer un élément
  delete: async (id: string): Promise<void> => {
    try {
      console.log('Tentative de suppression de l\'élément gallery avec ID:', id);

      const { error } = await supabase
        .from('gallery')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Erreur lors de la suppression de l\'image:', error);
        throw error;
      }

      console.log('Élément gallery supprimé avec succès');
    } catch (error) {
      console.error('Erreur dans delete gallery:', error);
      throw error;
    }
  }
};
