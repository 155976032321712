
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { actionsApi } from "@/services/api";
import { CleanupAction } from "@/types/database";

export const useUpcomingActions = (limit: number = 3) => {
  const [upcomingActions, setUpcomingActions] = useState<CleanupAction[]>([]);

  // Fetch actions from API
  const { data: actions, isLoading, error } = useQuery({
    queryKey: ['actions'],
    queryFn: actionsApi.getAll,
  });
  
  useEffect(() => {
    if (actions && actions.length > 0) {
      // Filter actions to get only upcoming ones and sort by date
      const upcoming = actions
        .filter(action => action.status === 'upcoming')
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .slice(0, limit); // Get only the first n upcoming actions
      
      setUpcomingActions(upcoming);
    }
  }, [actions, limit]);

  return { upcomingActions, isLoading, error };
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  // Format to French date: day month year
  return date.toLocaleDateString('fr-FR', { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric' 
  });
};

export const getTypeDisplay = (type: string) => {
  switch (type) {
    case 'cleanup': return 'Randonnée';
    case 'workshop': return 'Atelier';
    case 'awareness': return 'Sensibilisation';
    default: return type;
  }
};

export const getDifficultyLabel = (difficulty: string) => {
  switch (difficulty) {
    case 'easy': return 'Facile';
    case 'medium': return 'Moyen';
    case 'hard': return 'Difficile';
    default: return difficulty;
  }
};
