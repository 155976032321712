
import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ArrowLeft, Loader2, AlertTriangle } from "lucide-react";
import { Button } from "@/components/ui/button";
import ActionForm from "./ActionForm";
import { useQuery } from "@tanstack/react-query";
import { actionsApi } from "@/services/actions";
import AdminLayout from "../AdminLayout";

const EditActionPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  const { data: action, isLoading, error } = useQuery({
    queryKey: ['action', id],
    queryFn: () => id ? actionsApi.getById(id) : Promise.resolve(undefined),
    enabled: !!id,
  });

  console.log("EditActionPage - Params:", id);
  console.log("EditActionPage - Action data:", action);
  console.log("EditActionPage - Error:", error);

  if (isLoading) {
    return (
      <AdminLayout>
        <div className="flex flex-col items-center justify-center h-64">
          <Loader2 className="h-8 w-8 animate-spin text-green-600 mb-4" />
          <p className="text-gray-500">Chargement de l'action...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error || !action) {
    return (
      <AdminLayout>
        <div className="flex flex-col items-center justify-center h-64">
          <AlertTriangle className="h-12 w-12 text-red-500 mb-4" />
          <h3 className="text-lg font-semibold">Action introuvable</h3>
          <p className="text-gray-500 mt-1 mb-4">L'action que vous cherchez n'existe pas ou a été supprimée</p>
          <Button onClick={() => navigate('/admin/actions')}>
            Retour à la liste
          </Button>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div>
        <div className="mb-6">
          <Link to="/admin/actions">
            <Button variant="ghost" className="p-0 h-auto mb-4">
              <ArrowLeft className="h-4 w-4 mr-2" />
              Retour à la liste
            </Button>
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">Modifier une action</h1>
          <p className="text-gray-500 mt-1">
            Modifiez les détails de l'action
          </p>
        </div>
        
        <div className="bg-white rounded-lg shadow-sm border p-6">
          <ActionForm action={action} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default EditActionPage;
