
import React from 'react';

interface StatsCardProps {
  icon: React.ReactNode;
  value: number | string;
  title: string;
  description: string;
  delay?: number;
  isVisible: boolean;
}

const StatsCard: React.FC<StatsCardProps> = ({
  icon,
  value,
  title,
  description,
  delay = 0,
  isVisible
}) => {
  return (
    <div 
      className={`bg-white rounded-xl shadow-md overflow-hidden transform transition-all duration-700 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
      }`}
      style={{ transitionDelay: `${delay}ms` }} // Use inline style for more reliable delay
    >
      <div className="p-6 text-center">
        <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
          {icon}
        </div>
        <div className="text-4xl font-bold text-green-700 mb-2">{value}</div>
        <div className="text-xl font-semibold mb-2">{title}</div>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default StatsCard;
