
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Session, User } from '@supabase/supabase-js';

// Define a user type with profile data
interface UserWithProfile {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  permissions?: string[];
}

interface AuthContextType {
  user: UserWithProfile | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (data: any) => Promise<void>;
  updateUserPassword: (currentPassword: string, newPassword: string) => Promise<boolean>;
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserWithProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<Session | null>(null);

  // Login function - uses Supabase authentication
  const login = async (email: string, password: string) => {
    console.log('Login attempt with:', { email });
    setLoading(true);
    
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (error) {
        if (error.message.includes("Email not confirmed")) {
          console.log("Email not confirmed, will try to proceed anyway in preview environments");
          
          // Dans un environnement de preview, on pourrait vouloir tenter de récupérer les informations utilisateur quand même
          const { data: userData, error: userError } = await supabase
            .from('profiles')
            .select('*')
            .eq('email', email)
            .single();
            
          if (!userError && userData) {
            console.log("Found user profile despite email not being confirmed:", userData);
            
            // En mode preview, on pourrait simuler une connexion réussie
            if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('preview')) {
              console.log("In preview environment - proceeding with mock session");
              // Mais dans ce cas, on va quand même remonter l'erreur pour que le LoginPage puisse gérer
            }
          }
        }
        
        // Remonter l'erreur pour une gestion appropriée au niveau du LoginPage
        throw error;
      }
      
      console.log('Authentication successful:', data);
      
      // Fetch user profile from the profile table
      if (data.user) {
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', data.user.id)
          .single();
          
        if (profileError) {
          console.error("Error fetching profile:", profileError);
        } else if (profileData) {
          console.log('Profile data retrieved:', profileData);
          
          // Set user with profile data
          setUser({
            id: data.user.id,
            email: data.user.email,
            firstName: profileData.first_name,
            lastName: profileData.last_name,
            role: profileData.role,
            permissions: profileData.permissions,
          });
        }
      }
      
      toast.success("Connexion réussie");
    } catch (error: any) {
      console.error("Login error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    console.log('Logout attempt');
    setLoading(true);
    
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear user state
      setUser(null);
      setSession(null);
      
      console.log('User logged out successfully');
      toast.success("Déconnexion réussie");
    } catch (error: any) {
      console.error("Error during logout:", error);
      toast.error(error.message || "Erreur lors de la déconnexion");
    } finally {
      setLoading(false);
    }
  };

  // Update profile function
  const updateProfile = async (data: any) => {
    if (!user) return;
    
    try {
      const { error } = await supabase
        .from('profiles')
        .update(data)
        .eq('id', user.id);
        
      if (error) throw error;
      
      // Update local user state
      setUser(prev => prev ? { ...prev, ...data } : null);
      toast.success("Profil mis à jour avec succès");
    } catch (error: any) {
      console.error("Error updating profile:", error);
      toast.error(error.message || "Erreur lors de la mise à jour du profil");
    }
  };

  // Update password function
  const updateUserPassword = async (currentPassword: string, newPassword: string) => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });
      
      if (error) throw error;
      
      toast.success("Mot de passe mis à jour avec succès");
      return true;
    } catch (error: any) {
      console.error("Error updating password:", error);
      toast.error(error.message || "Erreur lors de la mise à jour du mot de passe");
      return false;
    }
  };

  // Initialize on component mount - check for existing session
  useEffect(() => {
    console.log('AuthContext initializing');
    
    // Set up auth state listener FIRST
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log(`Auth state changed: ${event}`, session);
        setSession(session);
        
        // Fetch user profile when session changes
        if (session?.user) {
          fetchUserProfile(session.user);
        } else {
          setUser(null);
        }
        
        setLoading(false);
      }
    );
    
    // Check for existing session
    const initializeAuth = async () => {
      setLoading(true);
      
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Existing session:', session);
      
      if (session?.user) {
        fetchUserProfile(session.user);
      } else {
        setUser(null);
        setLoading(false);
      }
    };
    
    // Helper function to fetch user profile
    const fetchUserProfile = async (authUser: User) => {
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', authUser.id)
          .single();
          
        if (error) {
          console.error("Error fetching profile:", error);
          setUser(null);
        } else if (data) {
          console.log('Profile data retrieved:', data);
          setUser({
            id: authUser.id,
            email: authUser.email,
            firstName: data.first_name,
            lastName: data.last_name,
            role: data.role,
            permissions: data.permissions,
          });
        }
      } catch (error) {
        console.error("Error in profile fetch:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    
    initializeAuth();
    
    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Provide auth context
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout,
        updateProfile,
        updateUserPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
