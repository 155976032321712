
import React from "react";
import { Link } from "react-router-dom";
import InventoryForm from "./InventoryForm";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import AdminLayout from "../AdminLayout";

const NewInventoryItemPage = () => {
  return (
    <AdminLayout>
      <div className="container py-6">
        <div className="mb-6">
          <Link to="/admin/inventory">
            <Button variant="ghost" className="p-0 h-auto mb-4">
              <ArrowLeft className="h-4 w-4 mr-2" />
              Retour à la liste
            </Button>
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">Ajouter un article</h1>
          <p className="text-gray-500 mt-1">
            Remplissez le formulaire ci-dessous pour ajouter un nouvel article à l'inventaire
          </p>
        </div>
        <InventoryForm />
      </div>
    </AdminLayout>
  );
};

export default NewInventoryItemPage;
