
import React from "react";
import { FileText, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";

interface EmptyStateProps {
  onAddNew: () => void;
}

const EmptyState = ({ onAddNew }: EmptyStateProps) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-md border border-dashed p-8 text-center">
      <FileText className="mb-4 h-12 w-12 text-gray-400" />
      <h3 className="mb-2 text-lg font-medium">Aucune facture</h3>
      <p className="mb-4 text-sm text-gray-500">
        Commencez par ajouter des factures à votre système.
      </p>
      <Button onClick={onAddNew}>
        <Plus className="mr-2 h-4 w-4" />
        Ajouter une facture
      </Button>
    </div>
  );
};

export default EmptyState;
