
import React from "react";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import ActionForm from "./ActionForm";
import { Link } from "react-router-dom";
import AdminLayout from "../AdminLayout";

const NewActionPage = () => {
  return (
    <AdminLayout>
      <div>
        <div className="mb-6">
          <Link to="/admin/actions">
            <Button variant="ghost" className="p-0 h-auto mb-4">
              <ArrowLeft className="h-4 w-4 mr-2" />
              Retour à la liste
            </Button>
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">Créer une nouvelle action</h1>
          <p className="text-gray-500 mt-1">
            Remplissez le formulaire ci-dessous pour créer une nouvelle action
          </p>
        </div>
        
        <div className="bg-white rounded-lg shadow-sm border p-6">
          <ActionForm />
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewActionPage;
