
import { z } from "zod";

// Schema for form validation
export const actionSchema = z.object({
  title: z.string().min(3, { message: "Le titre doit faire au moins 3 caractères" }),
  description: z.string().min(10, { message: "La description doit faire au moins 10 caractères" }),
  type: z.enum(["cleanup", "workshop", "awareness"], { 
    required_error: "Veuillez sélectionner un type d'action" 
  }),
  date: z.string().refine(val => {
    try {
      return !isNaN(new Date(val).getTime());
    } catch (e) {
      return false;
    }
  }, {
    message: "Veuillez entrer une date valide",
  }),
  startTime: z.string().regex(/^\d{2}:\d{2}$/, { 
    message: "Format d'heure invalide. Utilisez le format HH:MM" 
  }),
  endTime: z.string().regex(/^\d{2}:\d{2}$/, { 
    message: "Format d'heure invalide. Utilisez le format HH:MM" 
  }),
  location: z.string().min(3, { message: "Le lieu doit faire au moins 3 caractères" }),
  coordinates: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  maxParticipants: z.number().min(1, { message: "Nombre minimum de participants: 1" }),
  status: z.enum(["upcoming", "completed", "cancelled"], { 
    required_error: "Veuillez sélectionner un statut" 
  }),
  imageUrl: z.string().optional(),
  // Nouvelles propriétés
  distance: z.string().optional(),
  duration: z.string().optional(),
  difficulty: z.enum(["easy", "medium", "hard"], { 
    required_error: "Veuillez sélectionner un niveau de difficulté" 
  }),
  elevation: z.string().optional(),
  // Données des déchets (uniquement pour les actions complétées)
  wasteData: z.object({
    totalWeight: z.number().min(0, { message: "Le poids ne peut pas être négatif" }).optional(),
    categories: z.object({
      plastic: z.number().min(0).max(100).optional(),
      paper: z.number().min(0).max(100).optional(),
      metal: z.number().min(0).max(100).optional(),
      glass: z.number().min(0).max(100).optional(),
      organic: z.number().min(0).max(100).optional(),
      cigarettes: z.number().min(0).max(100).optional(),
      other: z.number().min(0).max(100).optional(),
    }).optional(),
  }).optional(),
});

export type ActionFormValues = z.infer<typeof actionSchema>;
