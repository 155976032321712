
import React from 'react';
import { Route } from 'react-router-dom';
import Index from '@/pages/Index';
import ActionsPage from '@/pages/ActionsPage';
import TeamPage from '@/pages/TeamPage';
import CalendarPage from '@/pages/CalendarPage';
import NotFound from '@/pages/NotFound';

/**
 * Public routes accessible to all users
 */
export const publicRoutes = [
  <Route key="home" path="/" element={<Index />} />,
  <Route key="actions" path="/actions" element={<ActionsPage />} />,
  <Route key="team" path="/team" element={<TeamPage />} />,
  <Route key="calendar" path="/calendar" element={<CalendarPage />} />,
  <Route key="not-found" path="*" element={<NotFound />} />,
];
