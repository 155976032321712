
import React from "react";
import { Calendar, Clock } from "lucide-react";

type DateProps = {
  date: string;
  startTime: string;
  endTime: string;
};

const ActionDate = ({ date, startTime, endTime }: DateProps) => {
  // Format date for display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  return (
    <div className="flex items-center text-sm">
      <Calendar className="h-3.5 w-3.5 mr-1 text-gray-500" />
      {formatDate(date)}
      <span className="mx-1">•</span>
      <Clock className="h-3.5 w-3.5 mr-1 text-gray-500" />
      {startTime} - {endTime}
    </div>
  );
};

export default ActionDate;
