
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { inventoryApi } from "@/services/api";
import { Card } from "@/components/ui/card";
import { Loader2, AlertTriangle } from "lucide-react";
import { Button } from "@/components/ui/button";
import AdminLayout from "../AdminLayout";

// Components
import PageHeader from "./components/stock-movement/PageHeader";
import CurrentStockCard from "./components/stock-movement/CurrentStockCard";
import StockInfoCard from "./components/stock-movement/StockInfoCard";
import StockMovementCard from "./components/stock-movement/StockMovementCard";

// Hooks
import { useStockMovement } from "./hooks/useStockMovement";

const StockMovementPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  
  // Utiliser le hook useStockMovement pour gérer l'état et les mutations
  const { 
    item,
    isLoading: itemLoading, 
    quantity,
    setQuantity,
    movementType,
    setMovementType,
    handleStockMovement,
    isSubmitting 
  } = useStockMovement(id!);
  
  const { data: movements, isLoading: movementsLoading } = useQuery({
    queryKey: ['stockMovements', id],
    queryFn: () => inventoryApi.getStockMovements(id!),
    enabled: !!id,
  });
  
  const error = !item && !itemLoading;
  
  if (itemLoading || movementsLoading) {
    return (
      <AdminLayout>
        <div className="container h-64 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <Loader2 className="h-8 w-8 animate-spin text-green-600 mb-4" />
            <p className="text-gray-500">Chargement des données...</p>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  if (error || !item) {
    return (
      <AdminLayout>
        <div className="container h-64 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <AlertTriangle className="h-12 w-12 text-red-500 mb-4" />
            <h3 className="text-lg font-semibold">Article introuvable</h3>
            <p className="text-gray-500 mt-1 mb-4">L'article que vous cherchez n'existe pas ou a été supprimé</p>
            <Button onClick={() => navigate('/admin/inventory')}>
              Retour à la liste
            </Button>
          </div>
        </div>
      </AdminLayout>
    );
  }
  
  return (
    <AdminLayout>
      <div className="container py-6">
        <PageHeader item={item} />
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <CurrentStockCard 
            quantity={item.quantity} 
            unit={item.unit} 
            minQuantity={item.minQuantity} 
          />
          
          <div className="md:col-span-2">
            <StockInfoCard item={item} />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <StockMovementCard 
            quantity={quantity}
            setQuantity={setQuantity}
            movementType={movementType}
            setMovementType={setMovementType}
            handleStockMovement={handleStockMovement}
            isSubmitting={isSubmitting}
          />
          
          <div className="md:col-span-2">
            <Card>
              <div className="p-4 border-b">
                <h3 className="font-medium">Historique des mouvements</h3>
              </div>
              <div className="p-0">
                {!movements || movements.length === 0 ? (
                  <div className="p-6 text-center text-gray-500">
                    Aucun mouvement de stock enregistré
                  </div>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50 text-left">
                          <th className="p-3 font-medium">Date</th>
                          <th className="p-3 font-medium">Type</th>
                          <th className="p-3 font-medium">Quantité</th>
                          <th className="p-3 font-medium">Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {movements.map((movement) => (
                          <tr key={movement.id} className="border-t">
                            <td className="p-3">
                              {new Date(movement.created_at).toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </td>
                            <td className="p-3">
                              <span className={`px-2 py-1 rounded-full text-xs ${
                                movement.movement_type === 'add' 
                                  ? 'bg-green-100 text-green-800' 
                                  : 'bg-red-100 text-red-800'
                              }`}>
                                {movement.movement_type === 'add' ? 'Ajout' : 'Retrait'}
                              </span>
                            </td>
                            <td className="p-3 font-medium">
                              {movement.movement_type === 'add' ? '+' : '-'}{movement.quantity} {item.unit}
                            </td>
                            <td className="p-3 text-gray-600 max-w-xs truncate">
                              {movement.notes || '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default StockMovementPage;
