import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { galleryApi } from "@/services/api";
import { toast } from "sonner";
import { Skeleton } from "@/components/ui/skeleton";
import { initMasonry, addMasonryResizeListener } from "./MasonryInit";

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [imageLoadErrors, setImageLoadErrors] = useState<Record<string, boolean>>({});
  const sectionRef = useRef<HTMLDivElement>(null);
  
  const { data: galleryItems = [], isLoading } = useQuery({
    queryKey: ['galleryItems'],
    queryFn: async () => {
      try {
        console.log("Fetching gallery items...");
        const data = await galleryApi.getAll();
        console.log("Gallery items fetched:", data);
        return data;
      } catch (error) {
        console.error("Error fetching gallery items:", error);
        toast.error("Impossible de charger la galerie. Affichage des exemples par défaut.");
        return [];
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 2
  });

  const placeholderItems = [
    {
      id: "1",
      title: "Randonnée en forêt",
      tags: ["Randonnée"],
      imageUrl: "https://images.unsplash.com/photo-1513836279014-a89f7a76ae86",
      description: "Découverte des sentiers cachés et de la faune locale",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "2",
      title: "Le Rocher des deux trous",
      tags: ["Randonnée"],
      imageUrl: "/lovable-uploads/deux_trous.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "3",
      title: "L'équipe",
      tags: ["Equipe"],
      imageUrl: "/lovable-uploads/deux_trous_team.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "4",
      title: "Randonnée en montagne",
      tags: ["Randonnée"],
      imageUrl: "https://images.unsplash.com/photo-1469474968028-56623f02e42e",
      description: "Nettoyage de sentier montagnard",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "5",
      title: "Pêche à l'aimant dans le Vidourle",
      tags: ["Pêche à l'aimant"],
      imageUrl: "/lovable-uploads/mobylette_peche.jpg",
      description: "Dépollution d'une partie du vidourle, mobylette pêché!.",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "6",
      title: "Balade et découverte du site d'Ambrussum",
      tags: ["Balade"],
      imageUrl: "/lovable-uploads/ambrussum_balade.jpg",
      description: "Ramassage des déchets sur site archéologique d'ambrussum",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "7",
      title: "L'équipe à Ambrussum",
      tags: ["Equipe"],
      imageUrl: "/lovable-uploads/ambrussum_team.png",
      description: "Ramassage des déchets sur site archéologique d'ambrussum",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "8", 
      title: "Le Rocher des deux trous bis",
      tags: ["Randonnée"],
      imageUrl: "/lovable-uploads/deux_trous.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
  ];

  const ensureUniqueIds = (items: any[]) => {
    const seenIds = new Set<string>();
    return items.map((item, index) => {
      if (!item.id || seenIds.has(item.id)) {
        return {
          ...item,
          id: `generated_id_${index}_${Date.now()}`
        };
      }
      seenIds.add(item.id);
      return item;
    });
  };

  const rawItems = galleryItems.length > 0 ? galleryItems : placeholderItems;
  const items = ensureUniqueIds(rawItems);

  console.log("Gallery rendered with items:", items);

  const categories = ["Tous", ...new Set(items.flatMap(item => item.tags || []).filter(Boolean))];

  const filteredItems = selectedCategory && selectedCategory !== "Tous"
    ? items.filter(item => item.tags && item.tags.includes(selectedCategory))
    : items;

  useEffect(() => {
    items.forEach(item => {
      console.log(`Vérification de l'URL d'image pour ${item.id}: ${item.imageUrl}`);
      
      if (item.imageUrl && !item.imageUrl.startsWith('http') && !item.imageUrl.startsWith('/')) {
        console.log(`Correction de l'URL relative: ${item.imageUrl} -> /${item.imageUrl}`);
        item.imageUrl = `/${item.imageUrl}`;
      }
    });
  }, [items]);

  useEffect(() => {
    if (!isLoading && filteredItems.length > 0) {
      console.log("Initial masonry setup with", filteredItems.length, "items");
      // Allow a longer timeout for initial render to ensure all DOM elements are ready
      setTimeout(() => {
        initMasonry();
      }, 500);
    }
    
    addMasonryResizeListener();
    
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [filteredItems, isLoading, selectedCategory]);

  const handleImageError = (id: string) => {
    console.warn(`⚠️ Erreur de chargement d'image pour l'ID: ${id}`);
    setImageLoadErrors(prev => ({
      ...prev,
      [id]: true
    }));
  };

  const preloadImages = () => {
    console.log("Préchargement des images...");
    filteredItems.forEach(item => {
      const img = new Image();
      img.onload = () => {
        console.log(`✅ Image chargée avec succès: ${item.imageUrl}`);
        // Don't call initMasonry here as it can cause race conditions
      };
      img.onerror = () => console.warn(`⚠️ Impossible de charger l'image: ${item.imageUrl}`);
      img.src = item.imageUrl;
    });
  };

  useEffect(() => {
    preloadImages();
    setImageLoadErrors({});
  }, [filteredItems, selectedCategory]);

  const handleCategoryChange = (category: string | null) => {
    if (category === selectedCategory) return;
    setSelectedCategory(category);
  };

  const openModal = (index: number) => {
    setActiveIndex(index);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setActiveIndex(null);
    document.body.style.overflow = "unset";
  };

  const handleNext = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex + 1) % filteredItems.length);
    }
  };

  const handlePrev = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex - 1 + filteredItems.length) % filteredItems.length);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (activeIndex === null) return;
      
      if (e.key === 'Escape') {
        closeModal();
      } else if (e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrev();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [activeIndex, filteredItems]);

  const getFallbackImage = () => {
    return "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 600'%3e%3crect fill='%23f8f8f8' width='800' height='600'/%3e%3cg fill='%23ccc'%3e%3cpath d='M400 140c-61.8 0-112 50.2-112 112s50.2 112 112 112 112-50.2 112-112-50.2-112-112-112zm0 192c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80zm208 64H192c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z'/%3e%3c/g%3e%3ctext x='50%25' y='450' dominant-baseline='middle' text-anchor='middle' font-family='Arial, sans-serif' font-size='24' fill='%23666'%3eImage non disponible%3c/text%3e%3c/svg%3e";
  };

  return (
    <section id="gallery" ref={sectionRef} className="py-24 bg-gray-50">
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-12">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Nos Aventures
          </span>
          <h2 className="section-title mb-4">
            Explorez notre galerie d'expériences
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mb-8 text-balance">
            Découvrez en images les moments inoubliables vécus lors de nos
            aventures en pleine nature.
          </p>

          <div className="flex flex-wrap justify-center gap-2 mb-8">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleCategoryChange(category === "Tous" ? null : category)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                  (category === "Tous" && selectedCategory === null) || 
                  category === selectedCategory
                    ? "bg-green-700 text-white"
                    : "bg-white border border-green-200 text-green-800 hover:bg-green-50"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Array.from({ length: 6 }).map((_, index) => (
              <div 
                key={`skeleton-${index}`} 
                className="aspect-w-4 aspect-h-3 rounded-xl overflow-hidden"
              >
                <Skeleton className="w-full h-full" />
              </div>
            ))}
          </div>
        ) : filteredItems.length === 0 ? (
          <div className="text-center py-12">
            <h3 className="text-xl font-medium text-gray-700 mb-2">
              Aucune image disponible dans cette catégorie
            </h3>
            <p className="text-gray-500">
              Essayez une autre catégorie ou revenez plus tard
            </p>
          </div>
        ) : (
          <div className="masonry-gallery">
            {filteredItems.map((item, index) => {
              const imageUrl = imageLoadErrors[item.id] ? getFallbackImage() : item.imageUrl;
              
              return (
                <div
                  key={item.id}
                  className="masonry-item cursor-pointer transition-all duration-300 hover:shadow-lg"
                  onClick={() => openModal(index)}
                >
                  <div className="relative overflow-hidden rounded-lg shadow-sm">
                    {/* Loading placeholder */}
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500 text-sm">
                      Chargement de l'image...
                    </div>
                    
                    <img
                      src={imageUrl}
                      alt={item.title}
                      className="w-full h-full object-cover relative z-10"
                      loading="lazy"
                      onError={() => handleImageError(item.id)}
                      onLoad={(e) => {
                        console.log(`Image loaded: ${item.title}`);
                        // Call initMasonry after each image loads to recalculate layout
                        initMasonry();
                      }}
                    />
                    
                    {/* Overlay and caption */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-transparent to-transparent opacity-80 z-20"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-4 text-white z-30">
                      {item.tags && item.tags.length > 0 && (
                        <span className="text-xs font-medium bg-green-700/80 px-3 py-1 rounded-full mb-2 inline-block">
                          {item.tags[0]}
                        </span>
                      )}
                      <h3 className="text-lg font-bold line-clamp-2">{item.title}</h3>
                      {item.description && (
                        <p className="text-white/80 text-sm mt-1 line-clamp-2">{item.description}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {activeIndex !== null && filteredItems[activeIndex] && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/95 p-4 animate-fade-in">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white z-50 p-2 bg-black/20 rounded-full hover:bg-black/40 transition-colors"
            aria-label="Fermer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>

          <button
            onClick={handlePrev}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white z-50 p-2 rounded-full bg-black/30 hover:bg-black/50 transition-colors"
            aria-label="Précédent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>

          <button
            onClick={handleNext}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white z-50 p-2 rounded-full bg-black/30 hover:bg-black/50 transition-colors"
            aria-label="Suivant"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>

          <div className="max-w-5xl w-full animate-scale-in">
            <div className="w-full h-[60vh] flex items-center justify-center bg-gray-800 mb-4">
              <p className="text-white">Chargement de l'image...</p>
            </div>
            
            <img
              src={imageLoadErrors[filteredItems[activeIndex].id] ? getFallbackImage() : filteredItems[activeIndex].imageUrl}
              alt={filteredItems[activeIndex].title}
              className="w-full object-contain max-h-[80vh]"
              onError={() => handleImageError(filteredItems[activeIndex].id)}
              style={{ display: 'none' }}
              onLoad={(e) => {
                const img = e.target as HTMLImageElement;
                const parent = img.parentElement;
                if (parent) {
                  const loadingDiv = parent.querySelector('div');
                  if (loadingDiv) loadingDiv.style.display = 'none';
                }
                img.style.display = 'block';
              }}
            />
            
            <div className="bg-white p-4 mt-4 rounded-lg">
              <h3 className="text-xl font-bold">{filteredItems[activeIndex].title}</h3>
              <p className="text-gray-600 mt-1">{filteredItems[activeIndex].description}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Gallery;