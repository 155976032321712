
import React from 'react';
import { Route } from 'react-router-dom';
import PermissionGuard from '@/components/PermissionGuard';
import DashboardHome from '@/pages/admin/dashboard/DashboardHome';

export const dashboardRoutes = [
  <Route 
    key="admin-home"
    path="/admin"
    element={
      <PermissionGuard>
        <DashboardHome />
      </PermissionGuard>
    }
  />,
  <Route 
    key="admin-dashboard"
    path="/admin/dashboard"
    element={
      <PermissionGuard>
        <DashboardHome />
      </PermissionGuard>
    }
  />,
];
