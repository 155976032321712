
import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Loader2, Plus, Minus } from "lucide-react";

interface StockMovementCardProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
  movementType: 'add' | 'remove';
  setMovementType: (type: 'add' | 'remove') => void;
  handleStockMovement: () => void;
  isSubmitting: boolean;
}

const StockMovementCard: React.FC<StockMovementCardProps> = ({
  quantity,
  setQuantity,
  movementType,
  setMovementType,
  handleStockMovement,
  isSubmitting,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Mouvements de Stock</CardTitle>
        <CardDescription>Ajouter ou retirer du stock</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium" htmlFor="movement-type">
            Type de mouvement
          </label>
          <Select 
            value={movementType} 
            onValueChange={(value: 'add' | 'remove') => setMovementType(value)}
          >
            <SelectTrigger id="movement-type">
              <SelectValue placeholder="Sélectionner un type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="add">
                <div className="flex items-center">
                  <Plus className="mr-2 h-4 w-4 text-green-500" />
                  <span>Ajouter au stock</span>
                </div>
              </SelectItem>
              <SelectItem value="remove">
                <div className="flex items-center">
                  <Minus className="mr-2 h-4 w-4 text-red-500" />
                  <span>Retirer du stock</span>
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        
        <div className="space-y-2">
          <label className="text-sm font-medium" htmlFor="quantity">
            Quantité
          </label>
          <Input
            id="quantity"
            type="number"
            min="1"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
          />
        </div>
      </CardContent>
      <CardFooter>
        <Button 
          className="w-full" 
          onClick={handleStockMovement}
          disabled={isSubmitting}
        >
          {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          {movementType === 'add' ? 'Ajouter au stock' : 'Retirer du stock'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default StockMovementCard;
