
import React from "react";
import { Control } from "react-hook-form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface BasicInfoSectionProps {
  control: Control<any>;
  watchCategory: string;
}

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({
  control,
  watchCategory,
}) => {
  return (
    <div className="space-y-6">
      {/* Name Field */}
      <FormField
        control={control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Nom de l'article</FormLabel>
            <FormControl>
              <Input placeholder="Nom de l'article" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      
      {/* Category Field */}
      <FormField
        control={control}
        name="category"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Catégorie</FormLabel>
            <Select
              value={field.value}
              onValueChange={field.onChange}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner une catégorie" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="equipment">Équipement</SelectItem>
                <SelectItem value="clothing">Vêtement</SelectItem>
                <SelectItem value="consumable">Consommable</SelectItem>
                <SelectItem value="other">Autre</SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      
      {/* Sizes Field (only for clothing) */}
      {watchCategory === "clothing" && (
        <FormField
          control={control}
          name="sizes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tailles disponibles</FormLabel>
              <FormControl>
                <Input placeholder="XS, S, M, L, XL, XXL" {...field} />
              </FormControl>
              <FormDescription>
                Séparez les tailles par des virgules
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {/* Description Field */}
      <FormField
        control={control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Description de l'article (optionnel)"
                {...field}
                value={field.value || ""}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default BasicInfoSection;
