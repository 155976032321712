
import React from "react";

type TypeProps = {
  type: string;
};

const ActionType = ({ type }: TypeProps) => {
  // Get type display name
  const getTypeDisplay = (type: string) => {
    switch (type) {
      case 'cleanup': return 'Nettoyage';
      case 'workshop': return 'Atelier';
      case 'awareness': return 'Sensibilisation';
      default: return type;
    }
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs ${
      type === 'cleanup' ? 'bg-blue-100 text-blue-800' :
      type === 'workshop' ? 'bg-amber-100 text-amber-800' :
      'bg-green-100 text-green-800'
    }`}>
      {getTypeDisplay(type)}
    </span>
  );
};

export default ActionType;
