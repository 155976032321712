
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { actionsApi } from "@/services/actions";
import { CleanupAction } from "@/types/database";

interface UseCalendarActionsProps {
  initialTab?: 'upcoming' | 'past';
}

export const useCalendarActions = ({ initialTab = 'upcoming' }: UseCalendarActionsProps = {}) => {
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>(initialTab);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    type: 'all',
    difficulty: 'all'
  });
  
  const { data: actions = [], isLoading } = useQuery({
    queryKey: ['actions'],
    queryFn: actionsApi.getAll,
  });

  const convertStatus = (status: string): 'upcoming' | 'past' => {
    if (status === 'completed' || status === 'cancelled') return 'past';
    return 'upcoming';
  };

  const filteredActions = useMemo(() => {
    return actions.filter(action => {
      if (convertStatus(action.status) !== activeTab) return false;
      
      if (filters.type !== 'all' && action.type !== filters.type) return false;
      if (filters.difficulty !== 'all' && action.difficulty !== filters.difficulty) return false;
      
      return true;
    });
  }, [actions, activeTab, filters]);

  const pastActions = useMemo(() => {
    return filteredActions.filter(action => convertStatus(action.status) === 'past');
  }, [filteredActions]);

  const resetFilters = () => {
    setFilters({
      type: 'all',
      difficulty: 'all'
    });
  };

  return {
    activeTab,
    setActiveTab,
    filterOpen,
    setFilterOpen,
    filters,
    setFilters,
    actions,
    filteredActions,
    pastActions,
    isLoading,
    resetFilters
  };
};
