
import React from "react";
import { MapPin } from "lucide-react";

type LocationProps = {
  location: string;
};

const ActionLocation = ({ location }: LocationProps) => {
  return (
    <div className="flex items-center text-sm">
      <MapPin className="h-3.5 w-3.5 mr-1 text-gray-500" />
      {location}
    </div>
  );
};

export default ActionLocation;
