
import React from "react";
import { Calendar, Trash2, Users, ShoppingBag } from "lucide-react";
import StatCard from "./StatCard";
import { CleanupAction } from "@/types/database";

interface DashboardStatsProps {
  actions: CleanupAction[];
}

const DashboardStats: React.FC<DashboardStatsProps> = ({ actions = [] }) => {
  // Calculate statistics
  const calculateStats = () => {
    // Default values for empty data
    const defaultStats = {
      upcomingCount: 0,
      wasteCollected: 0,
      participantsCount: 0,
      inventoryStatus: "0%"
    };

    if (!actions || actions.length === 0) {
      return defaultStats;
    }

    const now = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(now.getDate() + 30);

    // Filter for upcoming actions in next 30 days
    const upcomingActions = actions.filter(action => {
      const actionDate = new Date(action.date);
      return action.status === "upcoming" && actionDate >= now && actionDate <= thirtyDaysFromNow;
    });

    // Calculate total waste collected from completed actions
    const wasteCollected = actions
      .filter(action => action.status === "completed")
      .reduce((total, action) => {
        return total + (action.wasteData?.totalWeight || 0);
      }, 0);

    // Calculate total participants across all actions
    const participantsCount = actions.reduce((total, action) => {
      return total + (action.currentParticipants || 0);
    }, 0);

    // Calculate the percentage of completed actions
    const completedActionsCount = actions.filter(action => action.status === "completed").length;
    const completionPercentage = actions.length > 0 
      ? Math.round((completedActionsCount / actions.length) * 100) 
      : 0;

    return {
      upcomingCount: upcomingActions.length,
      wasteCollected: wasteCollected.toFixed(1),
      participantsCount,
      inventoryStatus: `${completionPercentage}%` // Now represents completion rate instead of inventory
    };
  };

  const stats = calculateStats();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
      <StatCard 
        title="Actions à venir" 
        value={`${stats.upcomingCount}`} 
        description="Prochains 30 jours" 
        icon={<Calendar className="h-7 w-7 text-blue-600" />}
        color="bg-blue-50"
      />
      <StatCard 
        title="Déchets collectés" 
        value={`${stats.wasteCollected} kg`} 
        description="Total des déchets collectés" 
        icon={<Trash2 className="h-7 w-7 text-amber-600" />}
        color="bg-amber-50"
      />
      <StatCard 
        title="Participants" 
        value={`${stats.participantsCount}`} 
        description="Total des bénévoles" 
        icon={<Users className="h-7 w-7 text-green-600" />}
        color="bg-green-50"
      />
      <StatCard 
        title="Taux de complétion" 
        value={stats.inventoryStatus} 
        description="Actions terminées" 
        icon={<ShoppingBag className="h-7 w-7 text-purple-600" />}
        color="bg-purple-50"
      />
    </div>
  );
};

export default DashboardStats;
