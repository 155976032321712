
import React from "react";
import { CleanupAction } from "@/types/database";
import ActionCard from "./ActionCard";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

interface ActionsListProps {
  actions: CleanupAction[];
  isLoading: boolean;
  onResetFilters: () => void;
}

const ActionsList = ({ actions, isLoading, onResetFilters }: ActionsListProps) => {
  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin h-8 w-8 border-4 border-green-700 border-t-transparent rounded-full mx-auto mb-4"></div>
        <p className="text-gray-600">Chargement des actions...</p>
      </div>
    );
  }

  if (actions.length === 0) {
    return (
      <div className="text-center py-12 bg-gray-50 rounded-lg">
        <p className="text-gray-600">Aucune action ne correspond aux critères de recherche.</p>
        <Button 
          onClick={onResetFilters}
          className="mt-4 px-4 py-2 bg-green-700 text-white rounded-md hover:bg-green-600 transition-colors"
        >
          Réinitialiser les filtres
        </Button>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {actions.map((action) => (
        <ActionCard key={action.id} action={action} />
      ))}
    </div>
  );
};

export default ActionsList;
