
import { supabase } from "@/integrations/supabase/client";
import { InventoryItem } from "@/types/database";

// Convertir les formats de date entre Supabase et notre application
const formatInventoryItemFromDb = (item: any): InventoryItem => {
  return {
    id: item.id,
    name: item.name,
    category: item.category,
    quantity: item.quantity,
    unit: item.unit,
    description: item.description || undefined,
    minQuantity: item.min_quantity,
    imageUrl: item.image_url || undefined,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
  };
};

// Convertir notre modèle en format compatible avec Supabase
const formatInventoryItemForDb = (item: Partial<InventoryItem>) => {
  const dbItem: any = { ...item };
  
  if (item.minQuantity !== undefined) {
    dbItem.min_quantity = item.minQuantity;
    delete dbItem.minQuantity;
  }
  
  if (item.imageUrl !== undefined) {
    dbItem.image_url = item.imageUrl;
    delete dbItem.imageUrl;
  }
  
  // Supprimer les champs de date que Supabase gère
  delete dbItem.createdAt;
  delete dbItem.updatedAt;
  
  return dbItem;
};

export const supabaseInventoryApi = {
  getAll: async (): Promise<InventoryItem[]> => {
    const { data, error } = await supabase
      .from('inventory')
      .select('*')
      .order('created_at', { ascending: false });
    
    if (error) {
      console.error('Erreur lors de la récupération des articles d\'inventaire:', error);
      throw new Error(error.message);
    }
    
    return (data || []).map(formatInventoryItemFromDb);
  },
  
  getById: async (id: string): Promise<InventoryItem | undefined> => {
    const { data, error } = await supabase
      .from('inventory')
      .select('*')
      .eq('id', id)
      .maybeSingle();
    
    if (error) {
      console.error(`Erreur lors de la récupération de l'article d'inventaire ${id}:`, error);
      throw new Error(error.message);
    }
    
    return data ? formatInventoryItemFromDb(data) : undefined;
  },
  
  create: async (data: Omit<InventoryItem, 'id' | 'createdAt' | 'updatedAt'>): Promise<InventoryItem> => {
    const dbItem = formatInventoryItemForDb(data);
    
    const { data: insertedData, error } = await supabase
      .from('inventory')
      .insert(dbItem)
      .select()
      .single();
    
    if (error) {
      console.error('Erreur lors de la création de l\'article d\'inventaire:', error);
      throw new Error(error.message);
    }
    
    return formatInventoryItemFromDb(insertedData);
  },
  
  update: async (id: string, data: Partial<InventoryItem>): Promise<InventoryItem> => {
    const dbItem = formatInventoryItemForDb(data);
    
    const { data: updatedData, error } = await supabase
      .from('inventory')
      .update(dbItem)
      .eq('id', id)
      .select()
      .single();
    
    if (error) {
      console.error(`Erreur lors de la mise à jour de l'article d'inventaire ${id}:`, error);
      throw new Error(error.message);
    }
    
    return formatInventoryItemFromDb(updatedData);
  },
  
  delete: async (id: string): Promise<void> => {
    const { error } = await supabase
      .from('inventory')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error(`Erreur lors de la suppression de l'article d'inventaire ${id}:`, error);
      throw new Error(error.message);
    }
  },
  
  // Méthode pour obtenir l'historique des mouvements de stock pour un article
  getStockMovements: async (inventoryId: string) => {
    const { data, error } = await supabase
      .from('inventory_movements')
      .select('*')
      .eq('inventory_id', inventoryId)
      .order('created_at', { ascending: false });
    
    if (error) {
      console.error(`Erreur lors de la récupération des mouvements de stock pour l'article ${inventoryId}:`, error);
      throw new Error(error.message);
    }
    
    return data || [];
  },
  
  // Méthode pour enregistrer un mouvement de stock
  addStockMovement: async (
    inventoryId: string,
    quantity: number,
    movementType: 'add' | 'remove',
    notes?: string
  ) => {
    // D'abord mettre à jour la quantité dans l'inventaire
    const { data: inventoryItem, error: fetchError } = await supabase
      .from('inventory')
      .select('quantity')
      .eq('id', inventoryId)
      .single();
    
    if (fetchError) {
      console.error(`Erreur lors de la récupération de l'article ${inventoryId}:`, fetchError);
      throw new Error(fetchError.message);
    }
    
    const newQuantity = movementType === 'add' 
      ? inventoryItem.quantity + quantity 
      : inventoryItem.quantity - quantity;
    
    if (newQuantity < 0) {
      throw new Error('La quantité ne peut pas être négative');
    }
    
    // Mettre à jour la quantité dans l'inventaire
    const { error: updateError } = await supabase
      .from('inventory')
      .update({ quantity: newQuantity })
      .eq('id', inventoryId);
    
    if (updateError) {
      console.error(`Erreur lors de la mise à jour de la quantité pour l'article ${inventoryId}:`, updateError);
      throw new Error(updateError.message);
    }
    
    // Enregistrer le mouvement dans l'historique
    const { data: movement, error: movementError } = await supabase
      .from('inventory_movements')
      .insert({
        inventory_id: inventoryId,
        quantity: quantity,
        movement_type: movementType,
        notes: notes || null,
        created_by: (await supabase.auth.getUser()).data.user?.id
      })
      .select()
      .single();
    
    if (movementError) {
      console.error(`Erreur lors de l'enregistrement du mouvement de stock:`, movementError);
      throw new Error(movementError.message);
    }
    
    return { movement, newQuantity };
  }
};
