
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

interface FormActionsProps {
  isSubmitting: boolean;
  isEditing: boolean;
  submitText: string;
  cancelHref: string;
}

const FormActions: React.FC<FormActionsProps> = ({
  isSubmitting,
  isEditing,
  submitText,
  cancelHref,
}) => {
  return (
    <div className="flex items-center justify-end space-x-2 pt-4">
      <div className="flex-grow"></div>
      <Button variant="outline" type="button" asChild>
        <Link to={cancelHref}>Annuler</Link>
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {submitText}
      </Button>
    </div>
  );
};

export default FormActions;
