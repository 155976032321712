
import React from "react";

interface CalendarHeaderProps {
  activeTab: 'upcoming' | 'past';
  setActiveTab: (tab: 'upcoming' | 'past') => void;
}

const CalendarHeader = ({ activeTab, setActiveTab }: CalendarHeaderProps) => {
  return (
    <div className="text-center mb-12">
      <h1 className="text-4xl md:text-5xl font-bold mb-4 text-green-800">Calendrier des Actions</h1>
      <p className="text-lg text-gray-600 max-w-3xl mx-auto">
        Retrouvez toutes nos actions passées et à venir. Rejoignez-nous pour participer 
        à nos prochaines randonnées de nettoyage et événements.
      </p>
      
      <div className="flex justify-center mt-8">
        <div className="inline-flex bg-gray-100 rounded-lg p-1">
          <button
            className={`px-6 py-2 rounded-md text-sm font-medium transition-all ${
              activeTab === 'upcoming' 
              ? 'bg-white shadow text-green-800' 
              : 'text-gray-600 hover:text-green-700'
            }`}
            onClick={() => setActiveTab('upcoming')}
          >
            Actions à venir
          </button>
          <button
            className={`px-6 py-2 rounded-md text-sm font-medium transition-all ${
              activeTab === 'past' 
              ? 'bg-white shadow text-green-800' 
              : 'text-gray-600 hover:text-green-700'
            }`}
            onClick={() => setActiveTab('past')}
          >
            Actions passées
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
