
import { Link } from "react-router-dom";
import { Facebook, Instagram, Twitter, Youtube, MapPin, Mail } from "lucide-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-green-900 text-white pt-16 pb-8">
      <div className="container-custom">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Column 1: About */}
          <div>
            <h3 className="text-xl font-bold mb-4 font-['Playfair_Display']">GreenWild</h3>
            <p className="text-white/80 mb-6">
              Une association dédiée à la préservation de notre environnement naturel 
              à travers des actions de nettoyage et de sensibilisation.
            </p>
            <div className="flex space-x-4">
              <SocialLink href="#" aria-label="Facebook">
                <Facebook className="w-5 h-5" />
              </SocialLink>
              <SocialLink href="#" aria-label="Instagram">
                <Instagram className="w-5 h-5" />
              </SocialLink>
              <SocialLink href="#" aria-label="Twitter">
                <Twitter className="w-5 h-5" />
              </SocialLink>
              <SocialLink href="#" aria-label="YouTube">
                <Youtube className="w-5 h-5" />
              </SocialLink>
            </div>
          </div>

          {/* Column 2: Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Liens Rapides</h3>
            <ul className="space-y-3">
              <FooterLink href="#about">Qui sommes-nous</FooterLink>
              <FooterLink href="#activities">Nos actions</FooterLink>
              <FooterLink href="#gallery">Galerie</FooterLink>
              <FooterLink href="#contact">Contact</FooterLink>
              <FooterLink href="#">Blog</FooterLink>
            </ul>
          </div>

          {/* Column 3: Activities */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Nos Actions</h3>
            <ul className="space-y-3">
              <FooterLink href="#">Randonnées de nettoyage</FooterLink>
              <FooterLink href="#">Ateliers de sensibilisation</FooterLink>
              <FooterLink href="#">Événements communautaires</FooterLink>
              <FooterLink href="#">Interventions scolaires</FooterLink>
              <FooterLink href="#">Calendrier des actions</FooterLink>
            </ul>
          </div>

          {/* Column 4: Contact */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <MapPin className="w-5 h-5 text-green-400 mr-3 mt-0.5" />
                <span className="text-white/80">
                  123 Rue de la Nature,<br /> 34000 Montpellier, France
                </span>
              </li>
              <li className="flex items-center">
                <Mail className="w-5 h-5 text-green-400 mr-3" />
                <span className="text-white/80">contact@greenwild.fr</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <div className="h-px bg-white/20 my-8"></div>

        {/* Bottom footer */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-white/60 text-sm mb-4 md:mb-0">
            &copy; {currentYear} GreenWild. Tous droits réservés.
          </p>
          <div className="flex space-x-6 text-sm text-white/60">
            <Link to="#" className="hover:text-white transition-colors">
              Mentions légales
            </Link>
            <Link to="#" className="hover:text-white transition-colors">
              Confidentialité
            </Link>
            <Link to="#" className="hover:text-white transition-colors">
              Conditions d'utilisation
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

const SocialLink = ({ 
  href, 
  children,
  ...props
}: { 
  href: string; 
  children: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <a
      href={href}
      className="w-9 h-9 rounded-full bg-white/10 flex items-center justify-center hover:bg-white/20 transition-colors"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

const FooterLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  return (
    <li>
      <a 
        href={href} 
        className="text-white/80 hover:text-white transition-colors"
      >
        {children}
      </a>
    </li>
  );
};

export default Footer;
