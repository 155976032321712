
import React, { useEffect, useState } from "react";
import { ArrowRight } from "lucide-react";
import AnimatedText from "./AnimatedText";
import { useIsMobile } from "@/hooks/use-mobile";

const Hero = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 300);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculer les effets de parallaxe en fonction de la position de défilement
  const calculateParallax = (factor = 0.2) => {
    return scrollPosition * factor;
  };

  return (
    <section 
      id="home" 
      className="relative min-h-screen flex items-center justify-center overflow-hidden"
    >
      {/* Hero background video/image with parallax effect */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-black/40 z-10"></div>
        <div 
          className={`absolute inset-0 bg-forest-texture bg-cover bg-center bg-no-repeat transition-transform duration-[8000ms] ease-out ${isLoaded ? 'scale-105' : 'scale-100'}`}
          style={{ transform: `translateY(${calculateParallax(0.15)}px) scale(${isLoaded ? '1.05' : '1'})` }}
        ></div>
      </div>

      {/* Animated leaves */}
      <div className="absolute inset-0 z-5 overflow-hidden pointer-events-none">
        {Array.from({ length: 10 }).map((_, index) => (
          <div 
            key={index} 
            className={`absolute w-6 h-6 bg-green-500/20 rounded-full blur-sm animate-float-${index % 3 + 1}`}
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${index * 0.3}s`,
              transform: `rotate(${index * 36}deg)`,
            }}
          />
        ))}
      </div>

      {/* Content container with parallax effect */}
      <div 
        className="container-custom relative z-20 pt-20 pb-16"
        style={{ transform: `translateY(${calculateParallax(-0.1)}px)` }}
      >
        <div className="max-w-3xl mx-auto text-center">
          <div className={`opacity-0 ${isLoaded ? 'animate-fade-in' : ''}`}>
            <span className="inline-block px-3 py-1 mb-6 text-sm font-medium bg-white/20 backdrop-blur-sm text-white rounded-full border border-white/30">
              Ensemble pour un environnement plus propre
            </span>
          </div>

          <h1 className={`hero-text text-white mb-6 opacity-0 ${isLoaded ? 'animate-fade-in delay-100' : ''}`}>
            <AnimatedText text="Prenons soin de notre planète ensemble" />
          </h1>

          <p className={`text-lg md:text-xl text-white/90 mb-8 max-w-2xl mx-auto opacity-0 ${isLoaded ? 'animate-fade-in delay-200' : ''}`}>
            Rejoignez GreenWild dans sa mission de préservation de l'environnement à travers des actions 
            de nettoyage et de sensibilisation. Chaque geste compte.
          </p>

          <div className={`flex flex-col sm:flex-row gap-4 justify-center opacity-0 ${isLoaded ? 'animate-fade-in delay-300' : ''}`}>
            <a 
              href="#activities" 
              className="btn-primary bg-green-700 hover:bg-green-600 group relative overflow-hidden"
            >
              <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-green-600 to-green-500 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></span>
              <span className="relative z-10 flex items-center">
                Découvrir nos actions
                <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
              </span>
            </a>
            <a 
              href="#about" 
              className="btn-outline bg-transparent text-white border-white/50 hover:bg-white/20 relative overflow-hidden group"
            >
              <span className="absolute inset-0 w-full h-full bg-white/10 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></span>
              <span className="relative z-10">Qui sommes-nous</span>
            </a>
          </div>
        </div>
      </div>

      {/* Scroll indicator with fade out on scroll - only shown on desktop */}
      {!isMobile && (
        <div 
          className={`absolute top-[85vh] left-1/2 transform -translate-x-1/2 z-20 opacity-0 transition-opacity duration-300 ${
            isLoaded ? 'animate-fade-in delay-500' : ''
          } ${scrollPosition > 100 ? 'opacity-0' : 'opacity-100'}`}
        >
          <div className="flex flex-col items-center animate-bounce">
            <span className="text-white/80 text-sm mb-2">Défiler</span>
            <div className="w-0.5 h-8 bg-white/50"></div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;
