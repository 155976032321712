
import { Invoice } from '@/types/database';
import { createApi } from '../utils/apiFactory';
import { InvoiceFormValues } from '@/types/invoice';
import { supabase } from '@/integrations/supabase/client';
import { supabaseInvoicesApi } from './supabaseInvoicesApi';

// Storage key for invoices (fallback)
const STORAGE_KEY = 'greenwild_invoices';

// Create local API as fallback
const localInvoicesApi = createApi<Invoice>(STORAGE_KEY);

// Create and export the invoices API
export const invoicesApi = {
  getAll: async (): Promise<Invoice[]> => {
    try {
      // Vérifier si l'utilisateur est authentifié - pour les factures on exige l'authentification
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error('Authentication required');
      }
      
      // Utiliser l'API Supabase
      return await supabaseInvoicesApi.getAll();
    } catch (error) {
      console.warn('Using local storage for invoices getAll:', error);
      return localInvoicesApi.getAll();
    }
  },
  
  getById: async (id: string): Promise<Invoice | undefined> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error('Authentication required');
      }
      
      // Utiliser l'API Supabase
      return await supabaseInvoicesApi.getById(id);
    } catch (error) {
      console.warn('Using local storage for invoices getById:', error);
      return localInvoicesApi.getById(id);
    }
  },
  
  create: async (data: InvoiceFormValues): Promise<Invoice> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error('Authentication required');
      }
      
      // Utiliser l'API Supabase
      return await supabaseInvoicesApi.create(data);
    } catch (error) {
      console.warn('Using local storage for invoices create:', error);
      return localInvoicesApi.create(data as any);
    }
  },
  
  update: async (id: string, data: Partial<Invoice>): Promise<Invoice> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error('Authentication required');
      }
      
      // Utiliser l'API Supabase
      return await supabaseInvoicesApi.update(id, data);
    } catch (error) {
      console.warn('Using local storage for invoices update:', error);
      return localInvoicesApi.update(id, data);
    }
  },
  
  delete: async (id: string): Promise<void> => {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error('Authentication required');
      }
      
      // Utiliser l'API Supabase
      return await supabaseInvoicesApi.delete(id);
    } catch (error) {
      console.warn('Using local storage for invoices delete:', error);
      return localInvoicesApi.delete(id);
    }
  }
};
