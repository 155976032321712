
import React from "react";
import { AlertTriangle } from "lucide-react";

const ErrorState = () => {
  return (
    <div className="flex flex-col items-center justify-center p-12 text-center">
      <AlertTriangle className="h-12 w-12 text-red-500 mb-4" />
      <h3 className="text-lg font-semibold">Erreur de chargement</h3>
      <p className="text-gray-500 mt-1">Impossible de charger les actions</p>
    </div>
  );
};

export default ErrorState;
