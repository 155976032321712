
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  LayoutDashboard, 
  Calendar, 
  Image,
  FileText,
  Package,
  Users,
  LogOut
} from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar
} from "@/components/ui/sidebar";
import { useIsMobile } from "@/hooks/use-mobile";

interface AdminSidebarProps {
  openMobile: boolean;
  setOpenMobile: (open: boolean) => void;
}

const AdminSidebar = ({ openMobile, setOpenMobile }: AdminSidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const isMobile = useIsMobile();
  const { setOpenMobile: setSidebarOpenMobile } = useSidebar();
  
  // Synchroniser l'état du menu mobile avec le sidebar
  React.useEffect(() => {
    if (isMobile) {
      setSidebarOpenMobile(openMobile);
    }
  }, [openMobile, isMobile, setSidebarOpenMobile]);
  
  const navigation = [
    { name: "Tableau de bord", href: "/admin/dashboard", icon: LayoutDashboard },
    { name: "Actions", href: "/admin/actions", icon: Calendar },
    { name: "Galerie", href: "/admin/gallery", icon: Image },
    { name: "Factures", href: "/admin/invoices", icon: FileText },
    { name: "Inventaire", href: "/admin/inventory", icon: Package },
    { name: "Utilisateurs", href: "/admin/users", icon: Users },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Déconnexion réussie");
      navigate("/admin/login");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      toast.error("Une erreur est survenue lors de la déconnexion");
    }
  };

  const handleNavigation = (href: string) => {
    navigate(href);
    if (isMobile) {
      setOpenMobile(false);
    }
  };

  // Generate user initials for the avatar
  const userInitials = user?.firstName && user?.lastName 
    ? `${user.firstName[0]}${user.lastName[0]}` 
    : user?.email?.[0] || 'A';

  return (
    <Sidebar>
      <SidebarHeader className="flex items-center flex-shrink-0 px-4 py-4">
        <h1 className="text-xl font-bold text-green-600">
          GreenWild Admin
        </h1>
      </SidebarHeader>
      
      <SidebarContent>
        <SidebarMenu>
          {navigation.map((item) => {
            const isActive = location.pathname === item.href || 
                            (item.href !== "/admin/dashboard" && location.pathname.startsWith(item.href));
            return (
              <SidebarMenuItem key={item.name}>
                <SidebarMenuButton 
                  isActive={isActive}
                  tooltip={item.name}
                  onClick={() => handleNavigation(item.href)}
                >
                  <item.icon className="mr-2 h-5 w-5" />
                  <span>{item.name}</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            );
          })}
        </SidebarMenu>
      </SidebarContent>
      
      <SidebarFooter className="flex-shrink-0 flex border-t border-sidebar-border p-4">
        <div className="flex items-center w-full">
          <div className="flex-shrink-0">
            <div className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center text-white">
              {userInitials}
            </div>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-sidebar-foreground">
              {user?.firstName && user?.lastName 
                ? `${user.firstName} ${user.lastName}` 
                : user?.email || 'Admin User'}
            </p>
            <button onClick={handleLogout} className="text-xs font-medium text-sidebar-foreground flex items-center opacity-70 hover:opacity-100">
              <LogOut className="h-3 w-3 mr-1" />
              Se déconnecter
            </button>
          </div>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AdminSidebar;
