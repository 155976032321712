
import React from "react";
import { Form } from "@/components/ui/form";
import { InventoryItem } from "@/types/database";
import { useInventoryForm } from "./hooks/useInventoryForm";
import BasicInfoSection from "./components/BasicInfoSection";
import QuantitySection from "./components/QuantitySection";
import FormActions from "./components/FormActions";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface InventoryFormProps {
  inventoryItem?: InventoryItem;
}

const InventoryForm: React.FC<InventoryFormProps> = ({ inventoryItem }) => {
  const { form, onSubmit, watchCategory, isSubmitting } = useInventoryForm(inventoryItem);
  
  return (
    <div className="space-y-6 max-w-4xl mx-auto">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          {/* Basic Information Section */}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Informations de base</CardTitle>
            </CardHeader>
            <CardContent>
              <BasicInfoSection 
                control={form.control} 
                watchCategory={watchCategory} 
              />
            </CardContent>
          </Card>
          
          {/* Quantity Section */}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Quantité et détails</CardTitle>
            </CardHeader>
            <CardContent>
              <QuantitySection 
                control={form.control} 
              />
            </CardContent>
          </Card>
          
          {/* Form Actions */}
          <div className="flex justify-end pt-4">
            <FormActions 
              isSubmitting={isSubmitting}
              isEditing={!!inventoryItem}
              submitText={inventoryItem ? "Mettre à jour" : "Ajouter"}
              cancelHref="/admin/inventory"
            />
          </div>
        </form>
      </Form>
    </div>
  );
};

export default InventoryForm;
