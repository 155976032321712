
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { inventoryApi } from '@/services/api';

export const useStockMovement = (itemId: string) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [movementType, setMovementType] = useState<'add' | 'remove'>('add');
  const queryClient = useQueryClient();
  
  const { data: item, isLoading } = useQuery({
    queryKey: ['inventoryItem', itemId],
    queryFn: () => inventoryApi.getById(itemId),
    enabled: !!itemId,
  });
  
  const { mutate: handleStockMovement, isPending: isSubmitting } = useMutation({
    mutationFn: async () => {
      return await inventoryApi.addStockMovement(
        itemId,
        quantity,
        movementType
      );
    },
    onSuccess: () => {
      toast.success(
        movementType === 'add'
          ? `${quantity} ${item?.unit || 'unité(s)'} ajouté(s) au stock`
          : `${quantity} ${item?.unit || 'unité(s)'} retiré(s) du stock`
      );
      // Invalidate the item query to refresh the data
      queryClient.invalidateQueries({ queryKey: ['inventoryItem', itemId] });
      // Reset the form
      setQuantity(1);
    },
    onError: (error: Error) => {
      toast.error(`Erreur: ${error.message}`);
    },
  });
  
  return {
    item,
    isLoading,
    quantity,
    setQuantity,
    movementType,
    setMovementType,
    handleStockMovement,
    isSubmitting
  };
};
