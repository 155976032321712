
import React from "react";
import Navbar from "../components/Navbar";
import Team from "../components/Team";
import Footer from "../components/Footer";

const TeamPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      <div className="pt-24 pb-8 bg-green-50">
        <div className="container-custom">
          <h1 className="text-4xl md:text-5xl font-bold text-center text-green-800 mb-4">Notre Équipe</h1>
          <p className="text-xl text-gray-600 text-center max-w-3xl mx-auto">
            Découvrez les membres passionnés de GreenWild qui œuvrent chaque jour 
            pour la préservation de notre environnement.
          </p>
        </div>
      </div>
      
      <Team />
      
      <section className="py-16 bg-white">
        <div className="container-custom">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-green-800 mb-8">Rejoignez notre aventure</h2>
            <p className="text-lg text-gray-600 mb-8">
              Nous sommes toujours à la recherche de passionnés pour renforcer notre équipe. 
              Si vous êtes motivé par la protection de l'environnement et souhaitez faire partie 
              de notre mission, n'hésitez pas à nous contacter !
            </p>
            <a 
              href="#contact" 
              className="inline-block px-8 py-3 bg-green-700 text-white font-medium rounded-md shadow-sm hover:bg-green-600 transition-colors"
            >
              Nous rejoindre
            </a>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default TeamPage;
